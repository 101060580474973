import React, { Component } from "react";
import Form from "react-bootstrap/Form";

export class SelectRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      active: false,
      hover: false,
      id: props.id,
      click: props.click,
      disabled: props.disabled
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.selected !== this.props.selected) {
      if (this.props.selected === this.state.id) {
        this.setState({
          active: true
        });
      } else {
        this.setState({
          active: false
        });
      }
    }
    if (prevProps.disabled !== this.props.disabled) {
      if (prevProps.disabled) {
        this.setState({
          disabled: true
        });
      } else {
        this.setState({
          disabled: false
        });
      }
    }
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  selectButton = () => {
    this.setState({ active: true });
  };

  unSelectButton = () => {
    this.setState({ active: false });
  };

  render() {
    return (
      <div
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={() => {
          this.state.click(this.state.id);
        }}
        style={{
          ...style.selectButtonContainer,
          ...(this.state.active
            ? style.selectButtonContainerActive
            : style.selectButtonContainerInactive)
        }}
      >
        <Form.Check type="radio">
          <Form.Check.Input type="radio" checked={this.state.active} />
          <Form.Check.Label>
            <p
              style={{
                ...style.selectButtonText,
                ...(this.state.hover && !this.state.active
                  ? style.selectButtonTextHover
                  : {}),
                ...(this.state.active ? style.selectButtonTextActive : {}),
                ...(this.state.disabled && style.selectButtonTextDisabled)
              }}
            >
              {this.state.label}
            </p>
          </Form.Check.Label>
        </Form.Check>
      </div>
    );
  }
}

const style = {
  selectButtonContainer: {
    cursor: "pointer",
    borderRadius: "0.25rem",
    color: "black",
    marginBottom: "15px"
  },
  selectButtonContainerInactive: {
    color: "black"
  },
  selectButtonContainerActive: {},
  selectButtonContainerHoverColor: {
    backgroundColor: "#ececec"
  },
  selectButtonText: {
    margin: "auto",
    cursor: "pointer",
    color: "#484848",
    marginTop: "2px",
    marginLeft: "10px"
  },
  selectButtonTextHover: {
    color: "#cecece"
  },
  selectButtonTextActive: {
    color: "#7fad23",
    fontWeight: "bold"
  },
  selectButtonTextDisabled: {
    color: "#cecece"
  }
};

export default SelectRadio;
