import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import ReactModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";

import RewardCard from "../rewards/RewardCard";

import { isEmpty } from "../../actions/utils";

import { getConfig } from "../../actions/CoreActions";

export class StickerModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      stickerCount: 0,
      stickerLimit: 12,
      buttonAnimation: "",
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      stickerCount: user.stickers,
    });
    getConfig((config) => {
      this.setState({
        stickerLimit: config.max_sticker_count,
      });
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user !== this.props.user) {
      this.setState({
        stickerCount: this.props.user.stickers,
        buttonAnimation: "bounce animated",
      });
      setTimeout(() => {
        this.setState({
          buttonAnimation: "",
        });
      }, 1000);
    }
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  confirm = () => {
    this.handleClose();
  };

  render() {
    const currentCardStickerCount =
      this.state.stickerCount >= this.state.stickerLimit
        ? this.state.stickerLimit
        : this.state.stickerCount;
    const nextCardStickerCount =
      this.state.stickerCount >= this.state.stickerLimit
        ? this.state.stickerCount - this.state.stickerLimit
        : 0;
    return (
      <Fragment>
        {!isEmpty(this.props.user) && (
          <Fragment>
            <Button
              variant="primary"
              onClick={this.handleShow}
              style={styles.openModalButton}
              className={this.state.buttonAnimation}
            >
              <Image
                src={`${window.location.origin}/images/sticker.png`}
                style={styles.openModalButtonImage}
              />
              <Badge
                pill
                variant="danger text-white"
                style={styles.openModalButtonCount}
              >
                {currentCardStickerCount}
              </Badge>
            </Button>
            <ReactModal show={this.state.show} onHide={this.handleClose}>
              <ReactModal.Header closeButton>
                <ReactModal.Title>
                  You currently have {currentCardStickerCount} sticker/s
                </ReactModal.Title>
              </ReactModal.Header>

              <ReactModal.Body>
                <RewardCard stickerCount={currentCardStickerCount} />
                <br />
                {nextCardStickerCount > 0 && (
                  <Fragment>
                    <b>
                      You have {nextCardStickerCount} extra sticker/s for your
                      next card.
                    </b>
                  </Fragment>
                )}
                <small class="form-text">
                  Click{" "}
                  <a
                    className="text-dark-green"
                    href="/rewards"
                    target="_blank"
                  >
                    here
                  </a>{" "}
                  to view more details about this promo.
                </small>
              </ReactModal.Body>
            </ReactModal>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const styles = {
  openModalButton: {
    position: "fixed",
    left: 15,
    bottom: 15,
    borderRadius: 40,
    height: 66,
    borderColor: "#acd656",
    boxShadow: "0 8px 6px -6px black",
  },
  openModalButtonCount: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  openModalButtonImage: {
    width: 40,
  },
};

const mapPropsToState = (state) => ({
  user: state.auth.user,
});

export default connect(mapPropsToState, {})(StickerModal);
