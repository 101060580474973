import React, { Component } from "react";
import { connect } from "react-redux";

import { registerUser } from "../../actions/AuthActions";
import {
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal
} from "../../actions/CoreActions";

export class RegisterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      mobile: "",
      password1: "",
      password2: "",
      email_error: "",
      first_name_error: "",
      last_name_error: "",
      mobile_error: "",
      password1_error: "",
      password2_error: "",
      non_field_errors: "",
      isLoading: false
    };
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      email_error: "",
      first_name_error: "",
      last_name_error: "",
      mobile_error: "",
      password1_error: "",
      password2_error: "",
      non_field_errors: ""
    });
    const user = {
      username: this.state.email, //username is the field required in backend (Using email as well)
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      mobile: this.state.mobile,
      password1: this.state.password1,
      password2: this.state.password2,
      user_type: "marketplace"
    };
    registerUser(user, cb => {
      if (cb.status === 400) {
        Object.keys(cb.data).forEach((key, index) => {
          const errors = cb.data[key].join(" ");
          if (key === "non_field_errors") {
            this.setState({
              [key]: errors
            });
          } else {
            this.setState({
              [key + "_error"]: errors
            });
          }
        });
        this.setState({
          isLoading: false,
          password1: "",
          password2: ""
        });
      } else {
        this.setState({
          email: "",
          first_name: "",
          last_name: "",
          password1: "",
          password2: "",
          isLoading: false
        });
        this.refs.registerModalCloseButton.click();
        setTimeout(() => {
          this.props.setModalType("basic");
          this.props.setModalTitle("Registration Successful");
          this.props.setModalBody(
            "Successfully registered. Please check your email to confirm your account."
          );
          this.props.setModalConfirmButton("OK");
          this.props.showModal();
        }, 500);
      }
    });
  };

  render() {
    return (
      <div
        className="modal fade"
        id="registerModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registerModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="registerModalLabel">
                Register
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="registerModalCloseButton"
              ></button>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter Email"
                    onChange={this.onChange}
                    value={this.state.email}
                  />
                  {this.state.email_error && (
                    <small class="form-text text-danger">
                      {this.state.email_error}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    placeholder="Enter First Name"
                    onChange={this.onChange}
                    value={this.state.first_name}
                  />
                  {this.state.first_name_error && (
                    <small class="form-text text-danger">
                      {this.state.first_name_error}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    placeholder="Enter Last Name"
                    onChange={this.onChange}
                    value={this.state.last_name}
                  />
                  {this.state.last_name_error && (
                    <small class="form-text text-danger">
                      {this.state.last_name_error}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    onChange={this.onChange}
                    value={this.state.mobile}
                  />
                  {this.state.mobile_error && (
                    <small class="form-text text-danger">
                      {this.state.mobile_error}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password1"
                    className="form-control"
                    placeholder="Password"
                    onChange={this.onChange}
                    value={this.state.password1}
                  />
                  {this.state.password1_error && (
                    <small class="form-text text-danger">
                      {this.state.password1_error}
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="password2"
                    className="form-control"
                    placeholder="Reenter Password"
                    onChange={this.onChange}
                    value={this.state.password2}
                  />
                  {this.state.password2_error && (
                    <small class="form-text text-danger">
                      {this.state.password2_error}
                    </small>
                  )}
                </div>
                {this.state.non_field_errors && (
                  <small class="form-text text-danger">
                    {this.state.non_field_errors}
                  </small>
                )}
                <p style={{ fontSize: 12, margin: 0 }}>
                  By creating an account, you hereby agree to BukidFresh's{" "}
                  <a
                    href="https://bukidfresh.com/terms-conditions"
                    className="text-primary-dark"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://bukidfresh.com/privacy"
                    className="text-primary-dark"
                    target="_blank"
                  >
                    Privacy
                  </a>
                  .
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                  ref="registerModalDismissBtn"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {this.state.isLoading ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />{" "}
                      Loading...
                    </div>
                  ) : (
                    <span>Register</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    setModalType,
    setModalTitle,
    setModalBody,
    setModalConfirmButton,
    showModal
  }
)(RegisterModal);
