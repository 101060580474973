export const ORDER_STATUS = [
  {
    code: 10,
    name: "Open"
  },
  {
    code: 20,
    name: "Shipped"
  },
  {
    code: 30,
    name: "Delivered"
  },
  {
    code: 40,
    name: "Paid"
  },
  {
    code: 50,
    name: "Cancelled"
  }
];
