import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";
import CategoryFilter from "../components/shop/CategoryFilter";
import ProductCard from "../components/shop/ProductCard";

import { getProducts } from "../actions/ProductsActions";
import { getProductCategories } from "../actions/ShopActions";
import { setActiveMenu } from "../actions/CoreActions";
import { isEmpty } from "../actions/utils";

export class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      page: 1,
      next: "",
      noMoreProducts: false,
      isLoadingProduct: false,
      productCategories: {},
      category: false,
      isLoaded: false,
      query: "",
    };
  }

  componentDidMount() {
    getProducts({}).then((data) => {
      this.setState({
        products: data.results,
        next: data.next,
        isLoaded: true,
      });

      if (data.results.length === 0) {
        this.setState({
          noMoreProducts: true,
        });
      }
    });
    document.addEventListener("scroll", this.trackScrolling);
    getProductCategories((cb) => {
      if (cb && cb !== 500) {
        this.setState({
          productCategories: cb,
        });
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("shopContainer");
    if (this.state.isLoaded && this.isBottom(wrappedElement)) {
      document.removeEventListener("scroll", this.trackScrolling);
      this.setState({
        isLoadingProduct: true,
      });
      if (this.state.next) {
        const newPage = this.state.page + 1;
        const reqParams = {
          page: newPage,
        };
        if (this.state.category !== "all") {
          reqParams["category"] = this.state.category;
        }
        if (this.state.query !== "") {
          reqParams["q"] = this.state.query;
        }
        getProducts(reqParams).then((data) => {
          this.setState({
            products: [...this.state.products, ...data.results],
            page: newPage,
            next: data.next,
          });
          document.addEventListener("scroll", this.trackScrolling);
          this.setState({
            isLoadingProduct: false,
          });
        });
      } else {
        this.setState({
          noMoreProducts: true,
          isLoadingProduct: false,
        });
      }
    }
  };

  filterShop = (code) => {
    this.setState({
      isLoadingProduct: true,
      isLoaded: false,
      noMoreProducts: false,
      products: [],
    });
    const reqParams = {
      page: 1,
    };
    if (code === "all") {
      this.setState({
        category: false,
      });
    } else {
      reqParams["category"] = code;
      this.setState({
        category: code,
      });
    }
    if (this.state.query !== "") {
      reqParams["q"] = this.state.query;
    }
    document.addEventListener("scroll", this.trackScrolling);
    getProducts(reqParams).then((data) => {
      this.setState({
        products: data.results,
        page: 1,
        next: data.next,
        isLoadingProduct: false,
        isLoaded: true,
      });
      if (data.results.length === 0) {
        this.setState({
          noMoreProducts: true,
        });
      }
    });
  };

  goToHowToOrder = (e) => {
    e.preventDefault();
    this.props.setActiveMenu("how-to-order");
    this.props.history.push(`/how-to-order`);
  };

  filter = (e) => {
    this.filterShop(e.target.value);
  };

  search = () => {
    this.setState({
      isLoadingProduct: true,
      isLoaded: false,
      noMoreProducts: false,
      products: [],
    });
    const reqParams = {
      page: 1,
      q: this.state.query,
    };
    if (this.state.category) {
      reqParams["category"] = this.state.category;
    }
    document.addEventListener("scroll", this.trackScrolling);
    getProducts(reqParams).then((data) => {
      this.setState({
        products: data.results,
        page: 1,
        next: data.next,
        isLoadingProduct: false,
        isLoaded: true,
      });
      if (data.results.length === 0) {
        this.setState({
          noMoreProducts: true,
        });
      }
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.search();
    }
  };

  render() {
    return (
      <MainLayout history={this.props.history}>
        <Container
          className="d-md-none py-2"
          style={{
            backgroundColor: "#ececec",
            position: "fixed",
            zIndex: 3,
            maxWidth: "none",
          }}
        >
          <Form.Group
            as={Row}
            className="m-0 d-flex"
            style={{ alignItems: "center" }}
          >
            <InputGroup className="mb-2" size="sm">
              <FormControl
                placeholder="Search"
                onChange={(event) => {
                  this.setState({ query: event.target.value });
                }}
                onKeyDown={this.handleKeyDown}
                value={this.state.query}
              />
              <InputGroup.Append>
                <Button variant="gray" onClick={() => this.search()}>
                  <FontAwesomeIcon icon="search" size="sm" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Row}
            className="m-0 d-flex"
            style={{ alignItems: "center" }}
          >
            <Form.Label column xs="3" className="p-0 text-brown">
              Categories
            </Form.Label>
            <Col xs="9" className="p-0">
              <Form.Control as="select" name="filter" onChange={this.filter}>
                <option key="all" value="all">
                  All
                </option>
                {!isEmpty(this.state.productCategories) &&
                  this.state.productCategories.map((category) => (
                    <option key={category.code} value={category.code}>
                      {category.name}
                    </option>
                  ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Container>
        <div class="mt-5 mt-md-0 pt-5 pt-md-0">
          <PageHeader>
            <h2>Shop</h2>
          </PageHeader>
        </div>
        <Container id="shopContainer">
          <Row>
            <Col xs={12} md={3}>
              <InputGroup className="mb-3 d-none d-md-flex">
                <FormControl
                  placeholder="Search"
                  onChange={(event) => {
                    this.setState({ query: event.target.value });
                  }}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.query}
                />
                <InputGroup.Append>
                  <Button variant="gray" onClick={() => this.search()}>
                    <FontAwesomeIcon icon="search" size="sm" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <CategoryFilter
                productCategories={this.state.productCategories}
                filterShop={this.filterShop}
                category={this.state.category}
              />
            </Col>
            <Col xs={12} md={9}>
              <p style={{ fontSize: "12px" }}>
                Kindly read{" "}
                <a
                  href="#"
                  onClick={(e) => this.goToHowToOrder(e)}
                  className="text-primary-dark"
                >
                  "How to Order"
                </a>{" "}
                first before shopping. A submitted order assumes that you have
                read and agreed to our guidelines.
              </p>

              <Row>
                {this.state.products.map((product) => {
                  return (
                    <Col xs={6} md={3} className="p-0">
                      <ProductCard
                        key={`product${product.id}`}
                        product={product}
                        history={this.props.history}
                      />
                    </Col>
                  );
                })}
              </Row>
              {this.state.noMoreProducts && (
                <Alert
                  className="mt-3"
                  key="noMoreProductsAlert"
                  variant="secondary"
                >
                  No more products.
                </Alert>
              )}
              {this.state.isLoadingProduct && (
                <Alert
                  className="mt-3 text-center"
                  key="noMoreProductsAlert"
                  variant="secondary"
                >
                  <Spinner animation="grow" variant="secondary" />
                  <Spinner animation="grow" variant="secondary" />
                  <Spinner animation="grow" variant="secondary" />
                </Alert>
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    );
  }
}

export default connect(null, { setActiveMenu })(Shop);
