import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { connect } from "react-redux";

import commonStyles from "../constants/styles/CommonStyles";
import "./styles/Cart.css";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";
import QuantitySelector from "../components/shop/QuantitySelector";

import {
  updateCart,
  updateProductToCart,
  removeProductToCart
} from "../actions/ShopActions";
import { removeBaseUrl } from "../actions/utils";

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: []
    };
  }
  componentDidMount() {
    const { cart } = this.props;
    this.setState({
      cart
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      cart: props.cart
    });
  }

  removeCart = product => {
    const { cart } = this.props;
    let productToDelete = cart.find(prop => prop.id === product.id);
    removeProductToCart(productToDelete);
    const updatedCart = cart.filter(prop => prop.id !== product.id);
    this.props.updateCart(updatedCart);
  };

  goToProduct = product => {
    this.props.history.push(`/shop/${product.id}`, { product });
  };

  goToShop = () => {
    this.props.history.push(`/shop`);
  };

  proceedCheckout = () => {
    this.props.history.push(`/checkout`);
  };

  subtractQty = productId => {
    const { cart } = this.props;
    let currentQty = cart.find(prop => prop.id === productId).quantity;
    if (currentQty > 1) {
      let productToUpdate = cart.find(prop => prop.id === productId);
      productToUpdate.quantity = currentQty - 1;
      this.props.updateProductToCart(cart, productToUpdate);
    }
  };

  addQty = productId => {
    const { cart } = this.props;
    let currentQty = cart.find(prop => prop.id === productId).quantity;
    let productToUpdate = cart.find(prop => prop.id === productId);
    productToUpdate.quantity = currentQty + 1;
    this.props.updateProductToCart(cart, productToUpdate);
  };

  render() {
    const totalPrice = this.props.cart.reduce(function(prev, product) {
      return prev + +(product.price * product.quantity);
    }, 0);
    const totalWeight = this.props.cart.reduce(function(prev, product) {
      return prev + +product.quantity;
    }, 0);

    return (
      <MainLayout history={this.props.history}>
        <PageHeader>
          <h2>Your Cart</h2>
        </PageHeader>
        <Container>
          {this.state.cart.length == 0 && (
            <Row>
              <Col xs={12} className="d-flex justify-content-center">
                <h6 className="text-center">Your Cart is Empty.</h6>
              </Col>
              <Col xs={12} className="d-flex justify-content-center">
                <Button variant="primary" onClick={this.goToShop}>
                  Go to Shop
                </Button>
              </Col>
            </Row>
          )}
          {this.state.cart.map(product => {
            return (
              <Row className="mb-3">
                <Col xs={12}>
                  <Card className="p-2">
                    <Row>
                      <Col xs={12} md={2}>
                        <div
                          style={style.cartImageContainer}
                          className="cartImageContainer"
                          onClick={() => this.goToProduct(product)}
                        >
                          <Image
                            src={removeBaseUrl(product.image)}
                            style={style.cartImage}
                            className="cartImage"
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={10} style={commonStyles.flexAlignCenter}>
                        <Card.Body style={style.cartBody}>
                          <Row>
                            <Col
                              xs={6}
                              md={4}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter
                              }}
                            >
                              <div
                                className="mb-3"
                                style={style.hoverCursor}
                                onClick={() => this.goToProduct(product)}
                              >
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Text>
                                  Price: ₱{product.price} / {product.base_weight_text}
                                </Card.Text>
                              </div>
                            </Col>
                            <Col
                              xs={{ span: 6, order: 6 }}
                              md={{ span: 3, order: 1 }}
                              style={commonStyles.flexAlignCenter}
                            >
                              <QuantitySelector
                                subtractQty={() => this.subtractQty(product.id)}
                                addQty={() => this.addQty(product.id)}
                                quantity={product.quantity}
                                quantityType={product.qty_type}
                                disabled={product.is_available !== "true"}
                              />
                            </Col>
                            <Col
                              xs={{ span: 6, order: 1 }}
                              md={{ span: 2, order: 6 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyContentFlexEnd
                              }}
                            >
                              <h5>₱{product.price * product.quantity}</h5>
                            </Col>
                            <Col
                              xs={{ span: 6, order: 12 }}
                              md={{ span: 2, order: 12 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyContentFlexEnd
                              }}
                            >
                              <Button
                                variant="outline-danger"
                                onClick={() => this.removeCart(product)}
                                style={commonStyles.defaultMarginBotttom}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          })}
          {this.state.cart.length > 0 && (
            <Row className="mb-3">
              <Col xs={12}>
                <Card className="p-2">
                  <Row>
                    <Col xs={12} md={2} />
                    <Col xs={12} md={10} style={commonStyles.flexAlignCenter}>
                      <Card.Body>
                        <Row>
                          <Col
                            xs={12}
                            md={7}
                            style={commonStyles.flexAlignCenter}
                          />
                          <Col
                            xs={12}
                            md={2}
                            style={{
                              ...commonStyles.flex,
                              ...commonStyles.textAlignRight,
                              ...commonStyles.defaultMarginBotttom
                            }}
                          >
                            <h3>₱{totalPrice}</h3>
                            <strong>{`( ${totalWeight} ${
                              totalWeight > 1 ? "items" : "item"
                            } )`}</strong>
                          </Col>
                          <Col
                            xs={12}
                            md={2}
                            style={{
                              ...commonStyles.flex,
                              ...commonStyles.flexAlignCenter,
                              ...commonStyles.flexJustifyContentFlexEnd
                            }}
                          >
                            <Button
                              variant="primary"
                              onClick={this.proceedCheckout}
                            >
                              Checkout
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </MainLayout>
    );
  }
}

const style = {
  hoverCursor: {
    cursor: "pointer"
  },
  cartImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    marginBottom: 20,
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
    cursor: "pointer"
  },
  cartImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

const mapPropsToState = state => ({
  cart: state.shopReducer.cart
});

export default connect(
  mapPropsToState,
  { updateCart, updateProductToCart }
)(Cart);
