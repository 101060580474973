import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const initialState = {};

let middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware = compose([...middleware, logger]);
}

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'shopReducer', 'coreReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let appMiddleware = applyMiddleware(...middleware);

export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(
    appMiddleware
  )
);
export const persistor = persistStore(store);