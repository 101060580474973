import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AnimatedSlideUp from "../animation/AnimatedSlideUp";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const HowItWorksRow = () => {
  const [isInitialVisible, setIsInitialVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsInitialVisible(true);
    }, 500);
  });
  return (
    <Container className="my-5">
      <Row>
        <Col xs={12}>
          <h2 className="text-center wide-text">HOW BUKIDFRESH WORKS</h2>
        </Col>
      </Row>
      {isInitialVisible && (
        <Row className="text-center">
          <Col xs={12} md={4} className="my-3 my-md-5">
            <AnimatedSlideUp delay={0}>
              <div style={styles.iconBg} className="bg-primary mb-4">
                <FontAwesomeIcon
                  icon="shopping-basket"
                  size="2x"
                  className="text-primary-text"
                />
              </div>
              <h5 className="text-primary-dark mt-4">CONVENIENT SHOPPING</h5>
              <p>
                Buy local and seasonal produce direct from BukidFresh’s
                network of smallholder farmers.
              </p>
            </AnimatedSlideUp>
          </Col>
          <Col xs={12} md={4} className="my-3 my-5">
            <AnimatedSlideUp delay={100}>
              <div style={styles.iconBg} className="bg-primary mb-4">
                <FontAwesomeIcon
                  icon="carrot"
                  size="2x"
                  className="text-primary-text"
                />
              </div>
              <h5 className="text-primary-dark mt-4">FRESH HARVEST</h5>
              <p>
                Our produce comes from farmers who've put in a lot of hard work 
                and passion to their craft, to provide us with safe and naturally 
                grown food.
              </p>
            </AnimatedSlideUp>
          </Col>
          <Col xs={12} md={4} className="my-3 my-5">
            <AnimatedSlideUp delay={200}>
              <div style={styles.iconBg} className="bg-primary mb-4">
                <FontAwesomeIcon
                  icon="box-open"
                  size="2x"
                  className="text-primary-text"
                />
              </div>
              <h5 className="text-primary-dark mt-4">SKIP THE QUEUE</h5>
              <p>
                Get your order delivered straight to your doorstep, 
                skipping the long lines and the heavy traffic.	
              </p>
            </AnimatedSlideUp>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const styles = {
  iconBg: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
    margin: "auto",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default HowItWorksRow;
