import { AUTH_USER, AUTH_GET_PROFILE, LOGOUT } from "../constants/Types";

const initialState = {
  token: "",
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        token: action.payload.data.key,
      };

    case AUTH_GET_PROFILE:
      return {
        ...state,
        user: action.payload.user_profile
      };

    case LOGOUT:
      return {
        ...state,
        token: "",
        user: {}
      };

    default:
      return state;
  }
}
