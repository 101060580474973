import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

import { getConfig } from "../actions/CoreActions";

export class HowToOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minPrice: "",
      delPrice: "",
      picPrice: "",
    };
  }
  componentDidMount() {
    getConfig(cb => {
      if (cb.min_price !== "") {
        this.setState({
          minPrice: cb.min_price
        });
      }
      if (cb.delivery_cost !== "") {
        this.setState({
          delPrice: cb.delivery_cost
        });
      }
      if (cb.pickup_cost !== "") {
        this.setState({
          picPrice: cb.pickup_cost
        });
      }
      if (cb.bayong_cost !== "") {
        this.setState({
          bayPrice: cb.bayong_cost
        });
      }
    });
  }
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>How To Order</h2>
          </PageHeader>
        </div>
        <Container>
          <div style={styles.section}>
            <h4 style={styles.header}>Order Guidelines</h4>
            <p>
              The items in our website are packaged into either packs or in pieces.
              For items which are relatively small, such as Snap Beans, Kalamansi, etc.,
              add each product to your cart based on the weight of the pack size, as indicated
              on its product description. 
            </p>
            <p>
              On the other hand, for items which have significant
              weight variance, such as Papaya, Ampalaya, etc., you may add these items to your
              cart based on the number of pieces which you would like to order. Please take 
              note of the item's average weight per piece as indicated in its product description.
            </p>
            <p>
              You can then further adjust your order of the said items, at increments of the
              weight of the pack size or in the number of pieces which you wish.
            </p>
          </div>

          <div style={styles.section}>
            <h4 style={styles.header}>Delivery Schedule</h4>
            Weekend Delivery:
            <ul>
              <li>Expect Updated Produce List by Monday 12NN</li>
              <li>Order from Monday 12NN to Thursday 12NN (cutoff)</li>
              <li>Saturday Delivery for Fresh Fruits and Vegetables</li>
            </ul>
            {/* Weekday Delivery:
            <ul>
              <li>Expect Updated Product List by Thursday 12NN</li>
              <li>Order from Thursday 12NN to Monday 12NN (cutoff)</li>
              <li>Tuesday Delivery of Rice, Coffee and Other Processed Food Products</li>
            </ul> */}
          </div>

          <div style={styles.section}>
          <h4 style={styles.header}>Minimum Order</h4>
            <p>
              To properly compensate for our partner farmers’ hard work
              in providing fresh produce to our growing community, we will
              be implementing a minimum order of PHP {this.state.minPrice} per
              customer delivery (estimated value), exclusive of the bayong and
              shipping fees.
            </p>
          </div>

          <div style={styles.section}>
            <h4 style={styles.header}>Bayong Delivery Guidelines</h4>
            <p>
              We currently use bayongs for our deliveries and we have a PHP {this.state.bayPrice} fee
              per bayong, depending on the volume of your orders. This would be an
              additional charge on your first transaction and it would only apply
              again when the volume of your new orders exceed the previously paid
              for bayong fee. 
            </p>
            <p>
              This being said, we request our customers to exchange their used bayongs
              with our new deliveries, on a per transaction basis. We’d like to advise
              though that our bayongs would normally remain in good condition for 3 months
              only and after which, we would be needing to replace it with new ones.
            </p>
            <p>
              Likewise, delivery for customers with new addresses would also be treated
              as a new customer delivery and hence, will be subjected to the PHP 50 fee
              per bayong basis.
            </p>
          </div>

          <div style={styles.section}>
            <h4 style={styles.header}>Payment</h4>
            <p>
              Please remember that your orders for our fresh produce only reflect
              weight approximations. As the items are still for harvest by our
              partner farmers, we do not have their actual weight until the morning
              of our delivery to you. 
            </p>
            <p>
              Please expect updates from us via SMS regarding adjustments to your
              final bill while our final sales invoice will also be sent to you
              via your provided e-mail address. Kindly ensure you provide a functioning
              e-mail address and correct contact number so that we can relay our
              updates immediately in the morning of the delivery. That being said,
              kindly provide payment only once we've sent our final sales invoice.
            </p>
          </div>

          <div style={styles.section}>
            <h4 style={styles.header}>Shipping</h4>
            <p>
              During check-out of your orders, you will be asked to choose whether
              you would want your order to be delivered to your preferred location or
              if you would just pick it up from our partner hubs. Details are provided
              below.
            </p>
            <p>
              <strong>Delivery: </strong>Order will be delivered to your preferred
              location for a fixed shipping fee of PHP {this.state.delPrice} per 
              transaction, for our service areas mentioned below.
            </p>
            <p>
              Standard Service Area: Muntinlupa, Paranaque, Las Pinas,
              Pasay, Manila, Marikina, Taguig, Makati, Pateros, San Juan, Pasig, Mandaluyong,
              Southern Part of Quezon City (District 1,3 & 4)
            </p>
            <p>
              <strong>Pick-up at our Partner Hubs: </strong>
              Order will be delivered to our partner hubs below and you may just
              pick it up from them. There will be a fixed shipping fee of PHP {this.state.picPrice} per
              transaction.
            </p>
            {/* <p>
              <strong>Locations:</strong>
            <ul>
              <li>Derive Cafe (<a
                href="https://g.page/derivecafeph?share"
                target="_blank"
                className="text-primary-dark">
                https://g.page/derivecafeph?share)</a><br/>
                  Pick-up time: 10AM - 2PM
              </li>
              <li>The Common Space (<a 
                href="https://goo.gl/maps/w25GtpW8S55B3PYh7"
                target="_blank"
                className="text-primary-dark">
                https://goo.gl/maps/w25GtpW8S55B3PYh7</a>)<br/>
                Pick-up time: 9AM - 1PM
              </li>
              <li>SkyLabs (<a 
                href="https://goo.gl/maps/NHXV316NcDq3tPDL9" 
                target="_blank"
                className="text-primary-dark">
                https://goo.gl/maps/NHXV316NcDq3tPDL9</a>)<br/>
                Pick-up time: 11AM - 3PM
              </li>
            </ul>
            </p> */}
            <p>
              Interested to become a pick-up partner for BukidFresh’s delivery of
              fruits and vegetables? Fill up this form!<br/>BukidFresh Pick-up
              Partner Hub Form: <a
              href="https://forms.gle/UCKeyHUjTJTxy47b7"
              target="_blank"
              className="text-primary-dark">https://forms.gle/UCKeyHUjTJTxy47b7</a>
            </p>
          </div>

          <div style={styles.section}>
            <h4 style={styles.header}>Estimated Time of Arrival</h4>
            <p>
              We will be sending an SMS a day prior to delivery which will
              contain the estimated time of arrival of your order based on our
              designated delivery route and schedule. This ETA is determined by
              our final schedule of deliveries and route for that day. Rest
              assured, we have a window period of delivering between 8AM - 2PM
              for all our standard service areas.
            </p>
          </div>
        </Container>
      </MainLayout>
    );
  }
}
const styles = {
  section: {
    marginBottom: "50px"
  },
  header: {
    marginBottom: "20px"
  }
};

export default HowToOrder;
