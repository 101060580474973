import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class Privacy extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Privacy</h2>
            Last modified: October 1, 2019
          </PageHeader>
        </div>
        <Container>
          <p>
            <strong>Bukidfresh.ph</strong> collects and uses personal data as
            needed in order for us to provide you with top-notch websites,
            products, services, and support (collectively, our "Services"). Your
            personal data includes information such as:
          </p>
          <ul class="disc heading">
            <li>Name</li>
            <li>Address</li>
            <li>Phone number</li>
            <li>Email address</li>
            <li>Other data that could directly or indirectly identify you</li>
          </ul>
          <p>
            Our Privacy Policy will explain to you what data we collect, and how
            we use your personal data. It also describes how you can access,
            update, or otherwise take control of the personal data that we have
            collected from you.
          </p>
          <ul id="termsAndConditions">
            <li>
              <h2>What data is collected?</h2>
              <p>Most of your personal data is collected from you when:</p>
              <ol type="1">
                <li>You sign up for our services;</li>
                <li>You submit an order;</li>
                <li>You request assistance from our customer support team;</li>
                <li>
                  You complete surveys, subscribe to newsletters, or request
                  other information from us;
                </li>
                <li>
                  You participate in contests, apply for a job, or participate
                  in activities where we may require information about you.
                </li>
              </ol>
              <p>
                In order to deliver the best Services to you, we may collect the
                following information:
              </p>
              <p>
                <strong>Account related information</strong> is collected when
                you sign up for our Services. This information may include your
                name, address, phone number, email address, credit card
                information, etc.
              </p>
              <p>
                <strong>Service Usage Data</strong> is automatically collected
                when you interact with our Services. This information may
                include data about your interactions with the features, content
                and links contained in our Services, time of interaction,
                browser type and configuration, operating system used, IP
                address, language preferences, and other cookie data. While none
                of this data will allow us to directly identify who you are,
                some of this data can be used to approximate your location.
              </p>
              <p>
                <strong>Supplementary Data</strong> may be received about you
                from third party sources, such as public databases. We may
                combine this data with the information we already have about you
                in order to maintain accuracy of our records, and provide
                products and services that you may be interested in.
              </p>
            </li>
            <li>
              <h2>How we use data</h2>
              <p>
                We believe in minimizing the data we collect. We will only use
                your data when we have been given permission to do so, when it
                is needed to deliver the Services that you have purchased, or
                for legal compliance or other lawful purposes. These usages
                include:
              </p>
              <p>
                <strong>
                  Delivering, updating, and improving the Services that we
                  provide to you.
                </strong>{" "}
                We collect various data relating to your purchase, use, and
                interactions with our Services. We use this data to:
              </p>
              <ul>
                <li>Improve and optimize the performance of our Services</li>
                <li>
                  Identify and investigate security risks, errors, problems, and
                  needed enhancements to our Services
                </li>
                <li>Detect and prevent fraud and abuse of our Services</li>
                <li>Collect statistics about the use of our Services</li>
                <li>
                  Analyze how you use our Services and which of our Services are
                  most relevant to you
                </li>
              </ul>
              <br />
              <p>
                Most of the data that we collect is statistical data about how
                people use our Services and is not linked to any personal data.
              </p>
              <p>
                <strong>Sharing with trusted third parties.</strong> We may
                share your personal data with affiliated companies within our
                corporate family and with trusted third party service providers
                in order for them to perform certain tasks on our behalf. This
                may include:
              </p>
              <ul>
                <li>Processing credit card payments</li>
                <li>
                  Delivering content and communications to you (such as email)
                </li>
                <li>Analyzing Service usage and customer demographics</li>
                <li>Serving advertisements</li>
                <li>Conducting surveys or contests</li>
                <li>Managing customer relationships</li>
              </ul>
              <br />
              <p>
                We only share what is needed for the third party to perform the
                tasks on our behalf. These third parties are prohibited from
                using, sharing, or keeping your personal data for any other
                purpose, and are bound to strict data processing terms and
                conditions.
              </p>
              <p>
                <strong>Communicating with you.</strong> We may contact you
                directly or through a third party service provider regarding the
                Services that you have purchased or subscribed to. We may also
                contact you with offers for additional services if you give us
                consent, or when it is allowed based on legitimate interests.
                The methods of contact may include:
              </p>
              <ul>
                <li>Email</li>
                <li>Phone calls</li>
                <li>Text messages</li>
              </ul>
              <br />
              <p>
                <strong>Website analytics.</strong> We use various third party
                web analytics tools such as Google Analytics to collect
                information on how you interact with our website. This data may
                include information on which pages you visit, how much time you
                spend on each page, which operating system and browser you use,
                and geographic location information. These tools will place
                cookies in your browser for this purpose which can only be used
                by the service provider. The data collected may be transmitted
                to and stored by these service providers in a country other than
                where you reside. This information does not include personal
                data such as names, address, email addresses, etc, and will be
                stored and used in accordance with their own privacy policies.
              </p>
              <p>
                <strong>Targeted Advertisements.</strong> Our third party
                partners may use technologies such as cookies to gather
                information regarding your activities on our web pages and other
                websites in order to provide you with advertising based on your
                interests, and to measure the effectiveness of the
                advertisements.
              </p>
              <p>
                <strong>
                  Compliance with legal, regulatory, and law enforcement
                  requests.
                </strong>{" "}
                We cooperate with law enforcement and government officials and
                private parties to comply with the law. We will, in our sole
                discretion, disclose any information about you to such parties
                as necessary to respond to claims and legal processes, to
                protect our property and rights or the property and rights of a
                third party, to protect the safety of the public or an
                individual, or to prevent or stop illegal or unethical activity.
              </p>
              <p>
                If we are legally permitted to do so, we will take reasonable
                steps to notify you in the event we are required to provide your
                information to third parties as part of a legal process.
              </p>
              <p></p>
              <p>
                We will share your information as necessary to comply with ICANN
                or ccTLD regulations and policies when you register a domain
                name with us.
              </p>
            </li>
            <li>
              <h2>How we secure and retain data </h2>
              <p>
                We follow generally accepted standards to collect, store and
                protect personal data, including the use of encryption. We
                retain personal data for as long as it is needed to provide the
                Services you have requested, and thereafter for legal and
                business purposes. These may include retention periods:
              </p>
              <ul>
                <li>Mandated by legal, contractual, or similar obligations;</li>
                <li>
                  For resolving, preserving, enforcing or defending our legal
                  and contractual rights;
                </li>
                <li>
                  Needed to maintain adequate and accurate business and
                  financial records.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <h2>How you can access, update, or delete your data</h2>
              <p>
                You can access, view and update your personal data by signing
                into your account and visiting "My Account". If you are unable
                to access "My Account" for any reason, you may contact us as
                described in the "Contact Us" section below.
              </p>
              <p>
                If you make a request to delete your personal data, that request
                will be honored only to the extent where the data is no longer
                needed for the Services you have purchased, or when it is no
                longer required for our business, legal or contractual record
                keeping requirements.
              </p>
            </li>
            <br />
            <li>
              <h2>Age restrictions</h2>
              <p>
                Our Services are only available for purchase for those who are
                over the age of 19. Our Services are not designed to target,
                entice, or consumed by individuals under the age of 19. If you
                know of or have reason to believe someone under the age of 19
                has provided us with any personal data, please contact us.
              </p>
            </li>
            <br />
            <li>
              <h2>Changes to our Privacy Policy</h2>
              <p>
                We reserve the right to modify this Privacy Policy at any time.
                Please review this document from time to time so that you are
                aware of what information we collect and how we use it.
              </p>
            </li>
            <br />
            <li>
              <h2>Data Protection Authority</h2>
              <p>
                If you are a resident of the European Economic Area (EEA) and
                believe we maintain your personal data subject to the General
                Data Protection Regulation, you may direct questions or
                complaints to your local Data Protection Authority.
              </p>
              <p>
                <a
                  href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                  target="_blank"
                  rel="noopener"
                  className="text-primary-dark"
                >
                  List of Data Protection Authorities
                </a>
              </p>
            </li>
            <li>
              <h2>Contact us</h2>
              <p>
                If you have any questions or concerns regarding our Privacy
                Policy, you may contact us by:
              </p>
              <ul>
                <li>
                  Email:{" "}
                  <a
                    href="mailto:hello.bukidfresh@gmail.com"
                    className="text-primary-dark"
                  >
                    hello.bukidfresh@gmail.com
                  </a>
                </li>
              </ul>
              <br />
              <p>We will respond to all requests within 30 days.</p>
            </li>
          </ul>
        </Container>
      </MainLayout>
    );
  }
}

export default Privacy;
