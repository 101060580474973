// Auth
export const AUTH_USER = "AUTH_USER";
export const AUTH_GET_PROFILE = "AUTH_GET_PROFILE";
export const LOGOUT = "LOGOUT";

// Products
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_FEATURED_PRODUCTS = "SET_FEATURED_PRODUCTS";

// Shop
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const EMPTY_CART = "EMPTY_CART";

//Core
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const SET_MODAL_TITLE = "SET_MODAL_TITLE";
export const SET_MODAL_BODY = "SET_MODAL_BODY";
export const SET_MODAL_CONFIRM_BUTTON = "SET_MODAL_CONFIRM_BUTTON";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const HIDE_LOGIN_MODAL = "HIDE_LOGIN_MODAL";
export const SET_DELIVERIES = "SET_DELIVERIES";
export const SET_PICKUPPOINTS = "SET_PICKUPPOINTS";
export const SHOW_ADDRESS_MODAL = "SHOW_ADDRESS_MODAL";
export const HIDE_ADDRESS_MODAL = "HIDE_ADDRESS_MODAL";
export const SET_ORDER_PERIOD = "SET_ORDER_PERIOD";
export const SET_UNDER_CONSTRUCTION_BYPASS = "SET_UNDER_CONSTRUCTION_BYPASS";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_CONFIRM_ACTION = "SET_CONFIRM_ACTION";
export const SET_PIXEL_SHOPBACK = "SET_PIXEL_SHOPBACK";
