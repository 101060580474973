import React, { Component } from "react";
import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

import {
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal,
  addContactUs
} from "../actions/CoreActions";

import commonStyles from "../constants/styles/CommonStyles";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      message: "",
      purpose: "pickup_partner",
      name_error: "",
      email_address_error: "",
      number_error: "",
      message_error: "",
      non_field_errors: "",
      isLoading: false
    };
  }
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidMount() {
    if (this.props.user) {
      let name = "";
      if (this.props.user.first_name && this.props.user.last_name) {
        name = `${this.props.user.first_name} ${this.props.user.last_name}`;
      }
      this.setState({
        name,
        email: this.props.user.email,
        number: this.props.user.mobile
      });
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user !== this.props.user) {
      let name = "";
      if (this.props.user.first_name && this.props.user.last_name) {
        name = `${this.props.user.first_name} ${this.props.user.last_name}`;
      }
      this.setState({
        name,
        email: this.props.user.email,
        number: this.props.user.mobile
      });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    let hasOtherErrors = false;
    this.setState({
      isLoading: true,
      name_error: "",
      email_address_error: "",
      number_error: "",
      message_error: "",
      non_field_errors: ""
    });
    const contactUs = {
      name: this.state.name,
      email_address: this.state.email,
      number: this.state.number,
      message: this.state.message,
      purpose: this.state.purpose,
    };
    if (this.state.email === "" && this.state.number === "") {
      hasOtherErrors = true;
      this.setState({
        email_address_error: "This field may not be blank.",
        number_error: "This field may not be blank."
      });
    }
    if (
      (this.state.email !== "" &&
        this.state.number !== "" &&
        this.state.name !== "") ||
      this.state.message !== "" ||
      this.state.name === "" ||
      this.state.message === ""
    ) {
      addContactUs(contactUs, cb => {
        if (cb.status === 400) {
          Object.keys(cb.data).forEach((key, index) => {
            const errors = cb.data[key].join(" ");
            if (key === "non_field_errors") {
              this.setState({
                [key]: errors
              });
            } else {
              this.setState({
                [key + "_error"]: errors
              });
            }
          });
          this.setState({
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
          this.setState({
            name: "",
            email: "",
            number: "",
            message: "",
            purpose: "pickup_partner",
          });
          this.props.setModalType("basic");
          this.props.setModalTitle("Contact Us");
          this.props.setModalBody("Message successfully submitted!");
          this.props.setModalConfirmButton("OK");
          this.props.showModal();
        }
      });
    }
  };
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Contact Us</h2>
          </PageHeader>
        </div>
        <Container>
          <Row>
            <Col xs={12} md={6} className="pr-md-5 mb-5">
              <Row>
                <Col xs={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Name here"
                        name="name"
                        onChange={this.handleChange}
                        value={this.state.name}
                      />
                      {this.state.name_error && (
                        <small className="form-text text-danger">
                          {this.state.name_error}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Email
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="email"
                        placeholder="Email here"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                      />
                      {this.state.email_address_error && (
                        <small className="form-text text-danger">
                          {this.state.email_address_error}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Number
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="tel"
                        placeholder="Number here"
                        name="number"
                        onChange={this.handleChange}
                        value={this.state.number}
                      />
                      {this.state.number_error && (
                        <small className="form-text text-danger">
                          {this.state.number_error}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Purpose
                    </Form.Label>
                    <Col sm="8">
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control
                          as="select"
                          name="purpose"
                          onChange={this.handleChange}
                          value={this.state.purpose}
                        >
                          <option value="pickup_partner">
                            Be a Pickup Partner
                          </option>
                          <option value="wholesale_buying">
                            Wholesale Buying
                          </option>
                          <option value="farmer">
                            Become a Partner Farmer
                          </option>
                          <option value="others">Others</option>
                        </Form.Control>
                      </Form.Group>
                      {this.state.number_error && (
                        <small className="form-text text-danger">
                          {this.state.number_error}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Message
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Message here"
                        name="message"
                        onChange={this.handleChange}
                        value={this.state.message}
                      />
                      {this.state.message_error && (
                        <small className="form-text text-danger">
                          {this.state.message_error}
                        </small>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} style={commonStyles.textAlignRight}>
                  {this.state.non_field_errors && (
                    <small className="form-text text-danger">
                      {this.state.non_field_errors}
                    </small>
                  )}
                  <Button variant="primary" onClick={this.onSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="pl-md-5">
              <Row>
                <Col xs={4}>Mailing Address:</Col>
                <Col xs={8}>
                  <span>
                    Unit 203, The Pergola Mall, Aguirre Ave., Cor. Gaches St.,
                    BF Homes, Paranaque City
                  </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={4}>Facebook:</Col>
                <Col xs={8}>
                  <a
                    href="https://facebook.com/bukidfresh/"
                    className="text-primary-dark"
                  >
                    https://facebook.com/bukidfresh/
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Instagram:</Col>
                <Col xs={8}>
                  <a
                    href="https://www.instagram.com/bukidfresh/"
                    className="text-primary-dark"
                  >
                    https://www.instagram.com/bukidfresh/
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>Email:</Col>
                <Col xs={8}>
                  <a
                    href="mailto:hello.bukidfresh@gmail.com"
                    className="text-primary-dark"
                  >
                    hello.bukidfresh@gmail.com
                  </a>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={4}>Mobile:</Col>
                <Col xs={8}>09278185794</Col>
              </Row>
              <Row>
                <Col xs={4}>Landline:</Col>
                <Col xs={8}>87764838</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    );
  }
}

const mapPropsToState = state => ({
  user: state.auth.user
});

export default connect(
  mapPropsToState,
  {
    setModalType,
    setModalTitle,
    setModalBody,
    setModalConfirmButton,
    showModal
  }
)(Contact);
