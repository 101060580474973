import React, { Component } from "react";
import { connect } from "react-redux";

import ReactModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  authUser,
  getUserProfile,
  resetPassword
} from "../../actions/AuthActions";
import { hideLoginModal } from "../../actions/CoreActions";
import { refreshCartFromToken } from "../../actions/ShopActions";

export class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      email_error: "",
      password_password: "",
      isLoading: false,
      error: "",
      success: "",
      show: false,
      forgotPassword: false
    };
  }

  componentDidMount() {
    const { show } = this.props;
    this.setState({
      show
    });
  }

  componentWillReceiveProps(props) {
    const { show } = props;
    this.setState({
      show
    });
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      error: "",
      email_error: "",
      password_error: ""
    });
    const user = {
      username: this.state.email,
      password: this.state.password
    };
    if (!this.state.email) {
      this.setState({
        email_error: "Email is required.",
      });
    }
    this.props.authUser(user, cb => {
      if (cb.status === 400) {
        Object.keys(cb.data).forEach((key, index) => {
          const errors = cb.data[key].join(" ");
          if (key === "non_field_errors") {
            this.setState({
              error: errors
            });
          } else {
            this.setState({
              [key + "_error"]: errors
            });
          }
        });
        this.setState({
          password: "",
          isLoading: false
        });
      } else {
        this.setState({
          password: "",
          isLoading: false
        });
        this.props.getUserProfile();
        this.props.refreshCartFromToken(cb => {});
        this.props.hideLoginModal();
      }
    });
  };

  handleClose = () => {
    this.props.hideLoginModal();
    this.setState({
      forgotPassword: false
    });
  };

  toggleForgotPassword = () => {
    this.setState({
      forgotPassword: !this.state.forgotPassword,
      error: "",
      email_error: "",
      password_error: "",
      success: ""
    });
  };

  proceedPasswordReset = () => {
    this.setState({
      error: "",
      email_error: "",
      password_error: "",
      success: "",
      isLoading: true
    });
    if (this.state.email === "") {
      this.setState({
        error: "Please enter email."
      });
    } else {
      resetPassword(this.state.email, cb => {
        if (cb.status === 400) {
          Object.keys(cb.data).forEach((key, index) => {
            const errors = cb.data[key].join(" ");
            this.setState({
              error: errors
            });
          });
          this.setState({
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false,
            success: "Password reset e-mail has been sent."
          });
        }
      });
    }
  };

  render() {
    return (
      <ReactModal show={this.state.show} onHide={this.handleClose}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>
            {this.state.forgotPassword ? "Forget Password" : "Login"}
          </ReactModal.Title>
        </ReactModal.Header>

        <form onSubmit={this.onSubmit}>
          <ReactModal.Body>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                aria-describedby="emailHelp"
                placeholder="Enter Email"
                onChange={this.onChange}
                value={this.state.email}
              />
              {this.state.email_error && (
                <small className="form-text text-danger ml-3">
                  {this.state.email_error}
                </small>
              )}
            </div>
            {!this.state.forgotPassword && (
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={this.onChange}
                  value={this.state.password}
                />
                {this.state.password_error && (
                  <small className="form-text text-danger ml-3">
                    {this.state.password_error}
                  </small>
                )}
              </div>
            )}
            {this.state.error && (
              <small className="form-text text-danger">
                {this.state.error}
              </small>
            )}
            {this.state.success && (
              <small className="form-text text-success">
                {this.state.success}
              </small>
            )}
            {this.state.forgotPassword ? (
              <Button
                variant="link-secondary"
                style={{ padding: 0 }}
                onClick={this.toggleForgotPassword}
              >
                Back to Login
              </Button>
            ) : (
              <Button
                variant="link-secondary"
                style={{ padding: 0 }}
                onClick={this.toggleForgotPassword}
              >
                Forgot Password?
              </Button>
            )}
            {/* <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label">Remember Me</label>
                </div> */}
          </ReactModal.Body>
          <ReactModal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            {this.state.forgotPassword ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.proceedPasswordReset}
              >
                {this.state.isLoading ? (
                  <div>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <span>Confirm</span>
                )}
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                {this.state.isLoading ? (
                  <div>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <span>Login</span>
                )}
              </button>
            )}
          </ReactModal.Footer>
        </form>
      </ReactModal>
    );
  }
}

const mapPropsToState = state => ({
  show: state.coreReducer.showLoginModal
});

export default connect(
  mapPropsToState,
  { authUser, getUserProfile, hideLoginModal, refreshCartFromToken }
)(LoginModal);
