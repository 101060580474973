import axios from "axios";

import { AUTH_USER, AUTH_GET_PROFILE, LOGOUT } from "../constants/Types";
import { API_URL } from "../constants/ApiConfig";
import { getToken } from "./TokenActions";

export const authUser = (user, cb) => dispatch => {
  axios
    .post(`${API_URL}/rest-auth/login/`, {
      username: user.username,
      password: user.password
    })
    .then(function(response) {
      dispatch({
        type: AUTH_USER,
        payload: response
      });
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const getUserProfile = () => dispatch => {
  const userToken = getToken();
  axios
    .get(`${API_URL}/rest-auth/customer-profile/`, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      dispatch({
        type: AUTH_GET_PROFILE,
        payload: response.data
      });
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export const registerUser = (user, cb) => {
  axios
    .post(`${API_URL}/rest-auth/registration/`, user)
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const resetPassword = (email, cb) => {
  axios
    .post(`${API_URL}/rest-auth/password/reset/`, { email: email })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export function logout() {
  return {
    type: LOGOUT
  };
}
