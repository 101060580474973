import React from "react";

import { connect } from "react-redux";

import { Redirect, Route } from "react-router-dom";

import PageError from "../../pages/PageError";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <PageError />
        )
      }
    />
  );
};

export default PrivateRoute