import React, { Component } from "react";

import ReactModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { connect } from "react-redux";

import { showModal, hideModal, clearModal } from "../../actions/CoreActions";

export class Modal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      modalType: "basic",
      modalTitle: "",
      modalBody: "",
      modalConfirmButton: "",
      pixelShopBack: "",
    };
  }

  componentDidMount() {
    const {
      show,
      modalType,
      modalTitle,
      modalBody,
      modalConfirmButton,
      pixelShopBack,
      confirmAction,
    } = this.props;
    this.setState({
      show,
      modalType,
      modalTitle,
      modalBody,
      modalConfirmButton,
      pixelShopBack,
      confirmAction,
    });
  }

  componentWillReceiveProps(props) {
    const {
      show,
      modalType,
      modalTitle,
      modalBody,
      modalConfirmButton,
      pixelShopBack,
      confirmAction,
    } = props;
    this.setState({
      show,
      modalType,
      modalTitle,
      modalBody,
      modalConfirmButton,
      pixelShopBack,
      confirmAction,
    });
  }

  handleClose = () => {
    if (this.state.modalType === "confirm") {
    } else if (this.state.modalType === "basic") {
      this.props.hideModal();
      this.props.clearModal();
    }
  };

  handleShow = () => {
    this.props.showModal();
  };

  confirm = () => {
    this.state.confirmAction();
    this.handleClose();
  };
  render() {
    return (
      <ReactModal show={this.state.show} onHide={this.handleClose}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>{this.state.modalTitle}</ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body>
          <p>{this.state.modalBody}</p>
          {this.state.pixelShopBack && (
            <img
              src={this.state.pixelShopBack}
              width="1"
              height="1"
            />
          )}
        </ReactModal.Body>

        <ReactModal.Footer>
          {this.state.modalType === "confirm" && (
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
          )}
          <Button variant="primary" onClick={this.confirm}>
            {this.state.modalConfirmButton}
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    );
  }
}

const mapPropsToState = (state) => ({
  show: state.coreReducer.showModal,
  modalType: state.coreReducer.modalType,
  modalTitle: state.coreReducer.modalTitle,
  modalBody: state.coreReducer.modalBody,
  modalConfirmButton: state.coreReducer.modalConfirmButton,
  pixelShopBack: state.coreReducer.pixelShopBack,
  confirmAction: state.coreReducer.confirmAction,
});

export default connect(mapPropsToState, { showModal, hideModal, clearModal })(
  Modal
);
