import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class RewardsPromo extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Reward Stickers Promo</h2>
            <b>Promo Period: </b> October 19, 2020 - February 11, 2021
          </PageHeader>
        </div>
        <Container>
          <ul class="list-unstyled">
            <li>
              <h2>Promo Mechanics</h2>
              <ol>
                <li>
                  All BukidFresh Customers are entitled to our Loyalty Program.
                </li>
                <li>
                  Minimum Order to avail of 1 banana leaf:
                  <ul>
                    <li>
                      PHP 1000 worth of produce per single transaction
                      (SUB-TOTAL AMOUNT){" "}
                    </li>
                    <li>PHP 1,000 - 1,999.99 = 1 banana leaf</li>
                    <li>PHP 2,000 - 2,999.99 = 2 banana leaves</li>
                    <li>So on and so forth following the same logic.</li>
                  </ul>
                </li>
                <li>
                  Generated digital sticker will be reflected automatically on
                  the account once the customer has paid.
                  <ul>
                    <li>
                      BukidFresh admin to update customer records accordingly.{" "}
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <br />
            <br />
            <li>
              <h2>How to Redeem</h2>
              <ol>
                <li>
                  Upon completing the 12 banana leaves in the customer’s digital
                  rewards card, the FREE 500 grams YAGAM Coffee will be provided
                  on the customer’s NEXT order.
                </li>
                <li>
                  Customer to receive the gift on his/her NEXT order after we
                  validated his/her 12th banana leaf.
                </li>
                <li>
                  After completing the 12 banana leaves, the digital rewards
                  card will refresh back to zero once the customer has claimed
                  the free item.
                </li>
                <li>
                  If you have exceeded the 12 required stickers within the promo
                  period, excess stickers will be automatically added on the
                  next promo period.
                </li>
                <li>Giveaway is subject to change on the next promo period.</li>
              </ol>
            </li>
          </ul>
          <br />
          <br />
          <br />
        </Container>
      </MainLayout>
    );
  }
}

export default RewardsPromo;
