import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getOrderPeriod } from "../../actions/CoreActions";

export class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.props.history.listen((location, action) => {
      this.props.getOrderPeriod();
    });
  }
  componentDidMount() {
    this.props.getOrderPeriod();
  }
  render() {
    return <div>{this.props.children}</div>;
  }
}

const mapPropsToState = state => ({
  token: state.auth.token
});
export default withRouter(
  connect(
    mapPropsToState,
    { getOrderPeriod }
  )(AppContainer)
);
