import React, { Component } from 'react'

export class Li extends Component {
  render() {
    return (
      <li style={style}>
        { this.props.children }
      </li>
    )
  }
}

const style = {
    listStyleType: "none",
    color: "black"
};

export default Li
