import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";

import { connect } from "react-redux";

import MainLayout from "../components/layout/MainLayout";
import AddToCart from "../components/shop/AddToCart";

import "./styles/Product.css";

import { getProduct } from "../actions/ProductsActions";
import { removeBaseUrl, isEmpty } from "../actions/utils";

export class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
    };
  }
  componentWillMount() {
    if (this.props.location.state) {
      this.setState({
        product: this.props.location.state.product,
      });
    } else {
      const productId = window.location.pathname.replace("/shop/", "");
      getProduct(productId, (cb) => {
        if (cb !== 500 && !isEmpty(cb)) {
          this.setState({
            product: cb,
          });
        }
      });
    }
  }
  render() {
    const { product } = this.state;
    return (
      <MainLayout history={this.props.history}>
        <Container style={style.productContainer}>
          <Row>
            <Col xs={12} md={4}>
              <div
                style={style.productImageContainer}
                className="productImageContainer"
              >
                <Image
                  src={removeBaseUrl(product.image)}
                  style={style.productImage}
                  className="productImage"
                />
              </div>
            </Col>
            <Col xs={12} md={8}>
              <Row>
                <Col>
                  <h4
                    className={
                      product.sources && product.sources.length === 0
                        ? ""
                        : "mb-0"
                    }
                  >
                    {product.name}
                  </h4>
                  {product.sources &&
                    product.sources.map((source) => (
                      <Badge variant="primary" style={style.badgeSource}>
                        {source.description}
                      </Badge>
                    ))}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="text-primary-dark">
                    ₱{product.price} / {product.base_weight_text}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={10}>
                  <Table responsive className="mt-3">
                    <tr>
                      <td>
                        <h6 className="m-0">Packaged:</h6>
                      </td>
                      <td>
                        {product.qty_type === "individual"
                          ? "Per Piece"
                          : "Per Pack"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 className="m-0">
                          Per{" "}
                          {product.qty_type === "individual" ? "piece" : "pack"}{" "}
                          is approximately:
                        </h6>
                      </td>
                      <td>{product.base_weight_text}</td>
                    </tr>
                    {product.product_categories &&
                      product.product_categories.length > 0 && (
                        <tr>
                          <td>
                            <h6 className="m-0">Category:</h6>
                          </td>
                          <td>
                            {product.product_categories.map((category) => (
                              <Badge
                                variant="primary"
                                style={style.badgeSourceCat}
                              >
                                {category.name}
                              </Badge>
                            ))}
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td colSpan={2}>
                        <h6 className="m-0">Description:</h6>
                        <p>
                          {product.description &&
                            product.description
                              .split("/n")
                              .map((item, index) => {
                                return index === 0 ? item : [<br />, item];
                              })}
                        </p>
                      </td>
                    </tr>
                    {product.recipe && (
                      <tr>
                        <td colSpan={2}>
                          <h6 className="m-0">Recipe:</h6>
                          <p>
                            {product.recipe &&
                              product.recipe.split("/n").map((item, index) => {
                                return index === 0 ? item : [<br />, item];
                              })}
                          </p>
                          {product.recipe_link && (
                            <p>
                              For more details on this recipe,{" "}
                              <a
                                target="_blank"
                                href={product.recipe_link}
                                className="text-primary-dark"
                              >
                                Click Here.
                              </a>
                            </p>
                          )}
                        </td>
                      </tr>
                    )}
                  </Table>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={6} lg={4}>
                  <AddToCart product={product} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    );
  }
}

const style = {
  productContainer: {
    marginTop: 40,
  },
  productImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    marginBottom: 20,
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
  },
  productImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  badgeSource: {
    fontWeight: 100,
    fontSize: "12px",
    marginRight: "5px",
    marginBottom: "10px",
  },
  badgeSourceCat: {
    fontWeight: 100,
    fontSize: "12px",
    marginRight: "5px",
  },
};

const mapPropsToState = (state) => ({
  cart: state.shopReducer.cart,
  orderPeriod: state.coreReducer.orderPeriod,
});

export default connect(mapPropsToState, {})(Product);
