import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class TermsConditions extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Terms and Conditions</h2>
            Last modified: October 1, 2019
          </PageHeader>
        </div>
        <Container>
          <ul class="list-unstyled">
            <li>
              <h2>1. General</h2>
              <p>
                These Terms and Conditions ("Agreement") governs the use of the
                services ("Service") that are made available by Bukidfresh.ph.
                ("Bukidfresh.ph", "we" or "us"). These Terms and Conditions
                represent the whole agreement and understanding between
                Bukidfresh.ph and the individual or entity who subscribes to our
                service ("Subscriber" or "you").
              </p>
              <p>
                PLEASE READ THIS AGREEMENT CAREFULLY. By submitting your
                application and by your use of the Service, you agree to comply
                with all of the terms and conditions set out in this Agreement.
                Bukidfresh.ph may terminate your account at any time, with or
                without notice, for conduct that is in breach of this Agreement,
                for conduct that Bukidfresh.ph believes is harmful to its
                business, or for conduct where the use of the Service is harmful
                to any other party.
              </p>
              <p>
                Bukidfresh.ph may, in its sole discretion, change or modify this
                Agreement at any time, with or without notice. Such changes or
                modifications shall be made effective for all Subscribers upon
                posting of the modified Agreement to this web address (URL):{" "}
                <a
                  href="https://bukidfresh.com/terms-conditions"
                  className="text-primary-dark"
                  target="_blank"
                >
                  https://bukidfresh.com/terms-conditions
                </a>
                . You are responsible to read this document from time to time to
                ensure that your use of the Service remains in compliance with
                this Agreement.
              </p>
            </li>
            <br />
            <li>
              <h2>2. Services</h2>
              <p>
                All features, content, specifications, products and prices of
                products and services described or depicted on this web site are
                subject to change at any time without notice. Certain weights,
                measures and similar descriptions are approximate and are
                provided for convenience purposes only. We make all reasonable
                efforts to accurately display the attributes of our products,
                including the applicable colors; however, the actual color you
                see will depend on your computer system, and we cannot guarantee
                that your computer will accurately display such colors. The
                inclusion of any products or services on this web site at a
                particular time does not imply or warrant that these products or
                services will be available at any time. It is your
                responsibility to ascertain and obey all applicable local,
                state, federal and international laws (including minimum age
                requirements) in regard to the possession, use and sale of any
                item purchased from this web site. By placing an order, you
                represent that the products ordered will be used only in a
                lawful manner. Any video or audio recordings and similar
                products sold are for private, home use (where no admission fee
                is charged), non-public performance and may not be duplicated.
              </p>
              <p>
                Services are provided on the basis of facility and equipment
                availability. Bukidfresh.ph reserves the right to modify,
                change, or discontinue any aspect of the Services at any time.
              </p>
              <p>
                Access to the web and email servers is terminated upon expiry of
                the Service.
              </p>
              <p>
                Details regarding your account can be found in your account
                control panel (Click login button in Navbar)
              </p>
            </li>
            <br />
            <li>
              <h2>3. Email</h2>
              <p>
                Bukidfresh.ph's email service provides customers with the
                capability to send and receive email via the Internet.
                Bukidfresh.ph retains the right, at our sole discretion, to
                restrict the volume of messages transmitted or received by you
                in order to maintain the quality of our email services to other
                Subscribers and to protect our computer systems.
              </p>
              <p>
                As an owner and operator of the equipment and other resources
                utilized to provide services, Bukidfresh.ph has the legal right
                to block electronic communications from other entities on the
                Internet.
              </p>
              <p>
                Bukidfresh.ph relies on the support of its sponsors and
                advertisers to help offset the cost of the Services offered to
                its Subscribers. You acknowledge that as part of your
                Bukidfresh.ph Service, advertisement sponsored email newsletters
                may be sent to Subscribers and Bukidfresh.ph email service email
                accounts.
              </p>
            </li>
            <br />
            <li id="fees">
              <h2>4. Service Fees / Payments / Invoices</h2>
              <p>
                All Bukidfresh.ph fees and charges are quoted and billed in
                Philippine Peso unless otherwise noted.
              </p>
              <p>
                Bukidfresh.ph may take any reasonable action to validate your
                payment and registration information, and collect all payments
                due. You agree to pay all attorney and collection fees arising
                from any efforts to collect any past due amounts from you, to
                the extent allowed by law.
              </p>
              <p>
                Invoices for all Bukidfresh.ph services can be found by logging
                into your account (My Orders).
              </p>
              <p>
                Billing inquiries and disputes should be brought to
                Bukidfresh.ph's attention within 30 days of the invoice date.
                Failure to do so will be deemed to be an admission that the
                invoice and charges are accurate.
              </p>
              <p>
                If any chargeback or charge dispute notices are received for
                your account, services provided to you may be immediately
                suspended pending investigation, and you will be subject to
                chargeback service charges. Where applicable, Bukidfresh.ph
                reserves the right to put your domain name into "Registrar Hold"
                status and deny any transfer requests for that domain name until
                the chargeback or dispute issue has been resolved.
              </p>
              <p>
                Accounts will be suspended if its service renewal fees are not
                paid within 72 hours of the due date. Accounts suspended in this
                manner can be re-activated upon payment of the Account
                Reactivation Fee (see below).
              </p>
              <p>
                Any applications or requests for services will be declined if
                payment cannot be successfully processed within 72 hours of the
                order.
              </p>
              <p>
                Bukidfresh.ph reserves the right to modify service fees and
                rates, effective upon posting on the Bukidfresh.ph website or on
                this Agreement.
              </p>
            </li>
            <br />
            <li>
              <h2>4. Order Policies</h2>
              <p>
                The Bukidfresh.ph Order Policy ensures convenience for
                transactions and orders. The Bukidfresh.ph Order Policy is
                subject to modification from time to time, and such changes are
                effective upon posting of the modified policy to this URL:{" "}
                <a
                  href="https://bukidfresh.com/policies"
                  className="text-primary-dark"
                  target="_blank"
                >
                  https://bukidfresh.com/policies
                </a>
              </p>
            </li>
            <br />
            <li>
              <h2>6. Termination / Plan Change</h2>
              <p>
                Bukidfresh.ph may terminate your Service under the following
                circumstances (non-exclusive list):
              </p>
              <ol>
                <li>Non-payment of fees</li>
                <li>
                  You are in breach of any term or condition of this Agreement
                </li>
                <li>
                  Your use of the Service disrupts Bukidfresh.ph business
                  operations or affects any other party
                </li>
              </ol>
              <p></p>
              <p>
                All Subscriber data is removed from Bukidfresh.ph servers for
                such terminations.
              </p>
              <p>
                You may request account termination or hosting plan changes at
                any time by contacting our Customer Service team either through
                phone or case ticket. You can send us an email in this email
                address:{" "}
                <a
                  href="mailto:hello.bukidfresh@gmail.com"
                  className="text-primary-dark"
                >
                  hello.bukidfresh@gmail.com
                </a>
              </p>
              <p>
                When submitting your cancellation request, you must provide the
                correct username and password for your account for verification.
                Incomplete cancellation requests will be deemed invalid and will
                not be processed. You will be responsible for any service fees
                that arise from your failure to cancel your account.
              </p>
            </li>
            <br />
            <li>
              <h2>7. Subscriber Responsibility</h2>
              <p>
                When you apply to use Bukidfresh.ph services, you will be asked
                to select a Subscriber ID and Password. The Subscriber ID and
                Password are the means through which you access certain
                services. You acknowledge and agree that it is your
                responsibility to safeguard the Subscriber ID and Password you
                select from any unauthorized use. IN NO EVENT WILL Bukidfresh.ph
                BE LIABLE FOR THE UNAUTHORIZED USE OR MISUSE OF YOUR SUBSCRIBER
                ID OR PASSWORD.
              </p>
              <p>
                Subscribers are responsible for maintaining accurate account
                information at all times, including credit card and contact
                information. This information can be updated in your account
                control panel.
              </p>
            </li>
            <br />
            <li>
              <h2>8. Prohibited Conduct</h2>
              <p>
                Bukidfresh.ph does not allow the use of unsolicited commercial
                email ("Spam") to promote products or services. Any Subscriber
                engaging in the sending of Spam through the Bukidfresh.ph
                network or promoting information on websites hosted by
                Bukidfresh.ph will be considered in breach of this Agreement and
                will be suspended from the Service immediately.
              </p>
              <p>
                Your use of the Service must be in compliance with Philippine
                laws at all times.
              </p>
              <p>
                You are responsible for ensuring that your use of the Service
                does not consume excessive system or network resources that
                disrupts the normal use of the Service through, but not limited
                to, spawning multiple processes, or consuming excessive amounts
                of memory, CPU or bandwidth capacity.
              </p>
            </li>
            <br />
            <li>
              <h2>9. Notices</h2>
              <p>
                You agree that, unless other instructions are posted on
                Bukidfresh.ph's website, any notices required to be given under
                this Agreement will be deemed to have been given if delivered by
                email or fax, or sent by registered mail or by courier to each
                of the parties in accordance with the most current contact
                information you have provided to us, and the contact information
                for Bukidfresh.ph posted on the Bukidfresh.ph website. All
                notices shall be effective upon receipt, except that email and
                fax notices shall be effective upon transmission.
              </p>
            </li>
            <br />
            <li>
              <h2>10. Privacy</h2>
              <p>
                The Bukidfresh.ph Privacy Policy sets out our obligations with
                respect to the safeguarding, collection and use of Subscribers'
                personal information. The Bukidfresh.ph Privacy Policy is
                subject to modification from time to time, and such changes are
                effective upon posting of the modified policy to this URL:{" "}
                <a
                  href="https://bukidfresh.com/privacy"
                  className="text-primary-dark"
                  target="_blank"
                >
                  https://bukidfresh.com/privacy
                </a>
              </p>
              <p>
                Email newsletters will only be sent directly by Bukidfresh.ph.
                Subscriber information will not be disclosed or sold to any
                third parties. You may also be contacted by Bukidfresh.ph's
                designated customer review software provider for customer
                experience and service feedback.
              </p>
            </li>
            <br />
            <li>
              <h2>11. Reservation of Rights</h2>
              <p>Bukidfresh.ph reserves the right and sole discretion to:</p>
              <ol>
                <li>
                  Censor any website hosted on its servers that is deemed
                  inappropriate.
                </li>
                <li>
                  Review any account for excessive space or bandwidth
                  utilization and to suspend service to those accounts that have
                  exceeded allowed levels.
                </li>
                <li>
                  Terminate any account for non-payment of fees, for providing
                  fraudulent account information or fraudulent payment
                  information.
                </li>
                <li>
                  Terminate any account if the contents of its website results
                  in, or are the subject of, legal action or threatened legal
                  action against Bukidfresh.ph or any of its affiliates or
                  partners, without consideration for whether such legal action
                  or threatened legal action is eventually determined to be with
                  or without merit.
                </li>
                <li>
                  Terminate any account for unsolicited, commercial e-mailing
                  (i.e., Spam), illegal access to other computers or networks
                  (i.e., hacking), distribution of Internet viruses or similar
                  distructive activities, activities whether lawful or unlawful
                  that Bukidfresh.ph determines to be harmful to its other
                  customers, operations or reputation, or for any breach of this
                  agreement.
                </li>
                <li>
                  Suspend the Service at any time for any duration of time when
                  necessary, without penalty or liability to ourselves.
                </li>
              </ol>
              <p></p>
              <p>
                You agree that it may be necessary for us to temporarily suspend
                the Service for technical reasons or to maintain network
                equipment or facilities.
              </p>
            </li>
            <br />
            <li>
              <h2>12. Limitation of Liability</h2>
              <p>
                The Service is provided on an "as is" and "as available" basis
                and the use of the Service is at your own risk. Bukidfresh.ph
                makes no representations or warranties, either expressed or
                implied, with respect to the Service, or any service or
                information provided through the Service. Bukidfresh.ph is not
                responsible for any damages, injury or economic loss arising
                from the use of the content or Service provided by
                Bukidfresh.ph.
              </p>
              <p>
                In no event will Bukidfresh.ph be liable to you for any direct,
                indirect, incidental or consequential damages or economic loss
                arising out of the Service or in connection with your website or
                any other services or products provided to you.
              </p>
              <p>
                Bukidfresh.ph, its officers, directors, owners, agents and
                employees shall in no way be liable to you or anyone else for
                any loss or injury resulting from the use of the Service or of
                your website.
              </p>
              <p>
                In no event shall Bukidfresh.ph be held liable for any damages
                or economic loss, whatsoever, as a result of notifying any
                official of potentially illegal content on your website, or for
                providing copies of your data files to the appropriate
                authorities or cooperating with law enforcement efforts to
                locate persons who have posted content that is illegal or
                promotes illegal conduct.
              </p>
            </li>
            <br />
            <li>
              <h2>13. Indemnification</h2>
              <p>
                You agree to indemnify and hold Bukidfresh.ph, its affiliates,
                sponsors, partners, directors, officers and employees harmless
                from and against, and to reimburse Bukidfresh.ph with respect
                to, any and all losses, damages, liabilities, claims, judgments,
                settlements, fines, costs and expenses (including reasonable
                related expenses, legal fees, costs of investigation) arising
                out of or relating to your breach of this Agreement or use by
                you or any third party of the Services.
              </p>
            </li>
            <br />
            <li>
              <h2>14. Force Majeure</h2>
              <p>
                Bukidfresh.ph will not be liable for any delay, interruption or
                failure in the provisioning of services if caused by acts of
                God, declared or undeclared war, fire, flood, storm, slide,
                earthquake, power failure, the inability to obtain equipment,
                supplies or other facilities that are not caused by a failure to
                pay, labor disputes, or other similar events beyond our control
                that may prevent or delay service provisioning.
              </p>
            </li>
            <br />
            <li>
              <h2>15. Unenforceable Provisions</h2>
              <p>
                If any part of this Agreement is found to be invalid or
                unenforceable under applicable law, such part will be
                ineffective to the extent of such invalid or unenforceable part
                only, without affecting the remaining parts of this Agreement in
                any way.
              </p>
            </li>
            <br />
            <li>
              <h2>16. Governing Law</h2>
              <p>
                The rights and obligations of the parties pursuant to this
                Agreement are governed by, and shall be construed in accordance
                with the laws of Philippines.
              </p>
              <p>
                You may be subject to other local, provincial or state and
                national laws. We shall be entitled to institute legal
                proceedings in connection with any matter arising under this
                Agreement in any jurisdiction where you reside, do business or
                have assets.
              </p>
            </li>
            <br />
            <li>
              <h2>17. Waiver</h2>
              <p>
                No waiver of any of the provisions of this Agreement will be
                deemed to constitute a waiver of any other provision nor shall
                such a waiver constitute a continuing waiver unless otherwise
                expressly provided in writing duly executed by the party to be
                bound thereby.
              </p>
            </li>
          </ul>
        </Container>
      </MainLayout>
    );
  }
}

export default TermsConditions;
