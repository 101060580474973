import axios from "axios";
import { API_URL } from "../constants/ApiConfig";
import {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_TYPE,
  SET_MODAL_TITLE,
  SET_MODAL_BODY,
  SET_MODAL_CONFIRM_BUTTON,
  CLEAR_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  SET_DELIVERIES,
  SET_PICKUPPOINTS,
  SHOW_ADDRESS_MODAL,
  HIDE_ADDRESS_MODAL,
  SET_ORDER_PERIOD,
  SET_UNDER_CONSTRUCTION_BYPASS,
  SET_ACTIVE_MENU,
  SET_CONFIRM_ACTION,
  SET_PIXEL_SHOPBACK
} from "../constants/Types";
import { store } from "../store";
import { getToken } from "./TokenActions";

export function showModal() {
  return {
    type: SHOW_MODAL
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL
  };
}

export function setModalType(type) {
  return {
    type: SET_MODAL_TYPE,
    payload: type
  };
}

export function setModalTitle(title) {
  return {
    type: SET_MODAL_TITLE,
    payload: title
  };
}

export function setModalBody(body) {
  return {
    type: SET_MODAL_BODY,
    payload: body
  };
}

export function setModalConfirmButton(confirmButton) {
  return {
    type: SET_MODAL_CONFIRM_BUTTON,
    payload: confirmButton
  };
}

export function clearModal() {
  return {
    type: CLEAR_MODAL
  };
}

export function showLoginModal() {
  return {
    type: SHOW_LOGIN_MODAL
  };
}

export function hideLoginModal() {
  return {
    type: HIDE_LOGIN_MODAL
  };
}

export function showAddressModal() {
  return {
    type: SHOW_ADDRESS_MODAL
  };
}

export function hideAddressModal() {
  return {
    type: HIDE_ADDRESS_MODAL
  };
}

export function setDeliveries(deliveries) {
  return {
    type: SET_DELIVERIES,
    payload: deliveries
  };
}

export function setOrderPeriod(orderperiod) {
  return {
    type: SET_ORDER_PERIOD,
    payload: orderperiod
  };
}

export function setUnderConstructionBypass(bypass) {
  return {
    type: SET_UNDER_CONSTRUCTION_BYPASS,
    payload: bypass
  };
}

export function setActiveMenu(activeMenu) {
  return {
    type: SET_ACTIVE_MENU,
    payload: activeMenu
  };
}

export function setConfirmAction(action) {
  return {
    type: SET_CONFIRM_ACTION,
    payload: action
  };
}

export function setPixelShopback(action) {
  return {
    type: SET_PIXEL_SHOPBACK,
    payload: action
  };
}

export const checkBypassPassword = (password, cb) => {
  axios
    .post(`${API_URL}/underconstruction/password/`, {
      password: password
    })
    .then(function(response) {
      cb(response.data.access);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      cb(false);
    });
};
export const getAndSetDeliveries = () => dispatch => {
  axios
    .get(`${API_URL}/deliveries/`, {})
    .then(function(response) {
      dispatch(setDeliveries(response.data));
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export function setPickupPoints(pickuppoints) {
  return {
    type: SET_PICKUPPOINTS,
    payload: pickuppoints
  };
}

export const getAndSetPickupPoints = () => dispatch => {
  axios
    .get(`${API_URL}/pickuppoints/`, {})
    .then(function(response) {
      dispatch(setPickupPoints(response.data));
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export const addContactUs = (contactUs, cb) => {
  axios
    .post(`${API_URL}/contactus/`, contactUs, {})
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const getOrderPeriod = () => dispatch => {
  axios
    .get(`${API_URL}/orderperiod/`)
    .then(function(response) {
      dispatch(setOrderPeriod(response.data));
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export const getConfig = cb => {
  axios
    .get(`${API_URL}/config/`, {})
    .then(function(response) {
      cb(response.data);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      cb(0);
    });
};

export const createLog = async (logDetails) => {
  const userToken = getToken();
  const userId = store.getState().auth.user.id;
  const logBody = {
    buyer: userId,
    ...logDetails
  };
  const headers = {
    headers: {
      ...userToken
    }
  };
  await axios
    .post(`${API_URL}/logs/`, logBody, headers)
    .then(() => {})
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
};
