import React, { Component } from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./store";

import Root from "./components/core/Root";

import "./App.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faShoppingCart,
  faEnvelope,
  faPhone,
  faMobileAlt,
  faStickyNote,
  faShoppingBasket,
  faSeedling,
  faBoxOpen,
  faCarrot,
  faPiggyBank,
  faGlobeAmericas,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons/";

library.add(faSearch);
library.add(faShoppingCart);
library.add(faEnvelope);
library.add(faPhone);
library.add(faMobileAlt);
library.add(faStickyNote);
library.add(faFacebookF);
library.add(faInstagram);
library.add(faShoppingBasket);
library.add(faSeedling);
library.add(faBoxOpen);
library.add(faCarrot);
library.add(faPiggyBank);
library.add(faGlobeAmericas);
library.add(faTrash);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Root />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
