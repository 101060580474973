import React, { Component } from "react";

import Container from "react-bootstrap/Container";

export class PageHeader extends Component {
  render() {
    return (
      <div className="page-header-container" style={style.pageHeader}>
        <Container>{this.props.children}</Container>
      </div>
    );
  }
}

const style = {
  pageHeader: {
    backgroundColor: "#f9f9f9",
    padding: 25,
    marginBottom: 40
  }
};

export default PageHeader;
