import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import SelectButton from "../generic/SelectButton";

import AddressModal from "../modals/AddressModal";

import { showAddressModal } from "../../actions/CoreActions";
import { getAddressText } from "../../actions/utils";

export class UpdateAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAddress: props.selectAddress,
      selectedAddress: "",
      addresses: props.user.addresses,
      address: {},
      error: props.error
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.addresses !== this.props.user.addresses) {
      this.setState({
        addresses: this.props.user.addresses
      });
    }
    if (prevProps.selectedAddress !== this.props.selectedAddress) {
      this.setState({
        selectedAddress: "",
        selectedAddress: this.props.selectedAddress
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  };
  selectAddress = selectedAddress => {
    this.state.selectAddress(selectedAddress);
    this.setState({
      selectedAddress,
      address: this.state.addresses.filter(
        address => address.id === selectedAddress
      )[0],
      error: ""
    });
    this.props.showAddressModal();
  };

  addNewAddress = () => {
    this.setState({
      address: {}
    });
    this.props.showAddressModal();
  };
  render() {
    return (
      <React.Fragment>
        <AddressModal address={this.state.address} />
        <Row className="mb-3">
          <Col xs={12}>
            <h5 className="mb-3">Update Address:</h5>
            {this.state.error && (
              <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
                {this.state.error}
              </medium>
            )}
            <Row>
              <Col xs={12} className="mb-3">
                <ListGroup defaultActiveKey="#link1">
                  {this.state.addresses.map(address => (
                    <ListGroup.Item
                      action
                      key={address.id}
                      onClick={() => this.selectAddress(address.id)}
                    >
                      {getAddressText(address)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col xs={12} key="newaddress">
                <SelectButton
                  label="+ ADD NEW ADDRESS"
                  id="newaddress"
                  click={this.addNewAddress}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapPropsToState = state => ({
  user: state.auth.user
});

export default connect(
  mapPropsToState,
  { showAddressModal }
)(UpdateAddress);
