import React, { Component, Fragment } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ReactModal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";

import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import commonStyles from "../constants/styles/CommonStyles";
import "./styles/Cart.css";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";
import SelectDelivery from "../components/shop/SelectDelivery";
import SelectAddress from "../components/shop/SelectAddress";
import SelectPickupPoint from "../components/shop/SelectPickupPoint";
import SelectPaymentOption from "../components/shop/SelectPaymentOption";
import QuantitySelector from "../components/shop/QuantitySelector";
import BayongQuantitySelector from "../components/shop/BayongQuantitySelector";

import { orderProducts, checkVoucher } from "../actions/ShopActions";
import {
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  setConfirmAction,
  setPixelShopback,
  showModal,
  getAndSetDeliveries,
  getAndSetPickupPoints,
  getConfig,
} from "../actions/CoreActions";
import {
  removeBaseUrl,
  getDateText,
  getSimplifiedWeight,
} from "../actions/utils";

import {
  updateCart,
  updateProductToCart,
  removeProductToCart,
  refreshCartFromToken,
} from "../actions/ShopActions";
import { getProduct } from "../actions/ProductsActions";
import { getUserProfile } from "../actions/AuthActions";
import { getOrdersCount } from "../actions/AccountActions";

export class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveries: props.deliveries,
      selectedDelivery: "",
      selectedPayment: "",
      selectedAddress: "",
      selectedPickupPoint: "",
      isLoading: false,
      delivery_error: "",
      payment_option_error: "",
      address_error: "",
      pickuppoint_error: "",
      remarks_error: "",
      non_field_errors: "",
      cart: [],
      shippingCost: 0,
      config: {},
      windowWidth: 0,
      navbarOffset: 0,
      bayongCost: 0,
      returningBayongCount: 0,
      bayongCredits: 0,
      showReturnBayongs: false,
      isPendingToPayOrders: false,
      isThereOpenOrders: false,
      remarks: "",
      showModalBayong: false,
      firstOpenModalBayong: false,
      voucher: "",
      appliedVoucher: "",
      showVoucherMessage: false,
      voucherMessageError: false,
      voucherMessage: "",
      discount: 0,
      isPercentage: false,
      isLoading: true,
      isCheckingIfSoldout: false,
      stickerCount: 0,
      stickerLimit: 12,
    };
    this.selectPickupPointRef = React.createRef();
    this.selectAddressRef = React.createRef();
    this.selectPaymentOptionRef = React.createRef();
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.getAndSetDeliveries();
    this.props.getAndSetPickupPoints();
    this.props.getUserProfile();
    this.setState({
      isLoading: true,
      stickerCount: user.stickers,
    });

    this.props.refreshCartFromToken((cart) => {
      getConfig((config) => {
        this.setState({
          config: config,
          bayongCost: config.bayong_cost,
          stickerLimit: config.max_sticker_count,
          cart,
          isLoading: false,
        });
        cart.map((product) => {
          getProduct(product.id, (cb) => {
            if (cb !== 500 && product.price !== cb.price) {
              let productToUpdate = cart.find((prop) => prop.id === product.id);
              productToUpdate.price = cb.price;
              this.props.updateProductToCart(cart, productToUpdate);
            }
          });
        });
      });
    });

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      bayongCredits: this.props.user.bayong_credit,
    });
    getOrdersCount((cb) => {
      if (cb && cb !== 500) {
        if (cb.delivered_orders_count > 0) {
          this.setState({
            isPendingToPayOrders: true,
          });
        }
        if (cb.open_orders_count > 0) {
          this.setState({
            isThereOpenOrders: true,
          });
        }
      }
      // window.scrollTo(0, 0);
    });
    window.scrollTo(0, 0);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.deliveries !== this.props.deliveries) {
      this.setState({
        deliveries: this.props.deliveries,
      });
    }
    if (prevProps.cart !== this.props.cart) {
      this.setState({
        cart: this.props.cart,
      });
      this.applyVoucher();
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        bayongCredits: this.props.user.bayong_credit,
      });
      getOrdersCount((cb) => {
        if (cb && cb !== 500) {
          if (cb.delivered_orders_count > 0) {
            this.setState({
              isPendingToPayOrders: true,
            });
          }
          if (cb.open_orders_count > 0) {
            this.setState({
              isThereOpenOrders: true,
            });
          }
        }
      });
    }
    if (prevProps.user !== this.props.user) {
      this.setState({
        stickerCount: this.props.user.stickers,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  goToOrders = () => {
    this.props.history.push(`/account?tab=orders`);
  };

  showErrorModal = () => {
    this.props.setModalType("basic");
    this.props.setModalTitle("Error");
    this.props.setModalBody(
      "We are having difficulties with the current number of users. Please try again later."
    );
    this.props.setModalConfirmButton("OK");
    this.props.setConfirmAction(() => window.location.reload());
    this.props.showModal();
  };

  placeOrder = () => {
    if (
      this.props.user.bayong_onhand > 0 &&
      !this.state.isThereOpenOrders &&
      !this.state.showReturnBayongs &&
      !this.state.firstOpenModalBayong
    ) {
      this.setState({ showModalBayong: true, firstOpenModalBayong: true });
    } else if (this.state.isLoading === false) {
      let hasOtherError = false;
      if (
        this.state.selectedAddress === "" &&
        this.state.selectedPickupPoint === ""
      ) {
        if (
          this.state.deliveries.length > 0 &&
          this.state.selectedDelivery ===
            this.state.deliveries.filter(
              (delivery) => delivery.name === "Delivery"
            )[0].id &&
          this.state.selectedAddress === ""
        ) {
          this.setState({
            address_error: "Please select an Address.",
          });
          hasOtherError = true;
          window.scrollTo(
            0,
            this.selectAddressRef.current.offsetTop - this.state.navbarOffset
          );
        } else if (
          this.state.deliveries.length > 0 &&
          this.state.selectedDelivery ===
            this.state.deliveries.filter(
              (delivery) => delivery.name === "Pickup"
            )[0].id &&
          this.state.selectedPickupPoint === ""
        ) {
          this.setState({
            pickuppoint_error: "Please select a Pickup Point.",
          });
          hasOtherError = true;
          window.scrollTo(
            0,
            this.selectPickupPointRef.current.offsetTop -
              this.state.navbarOffset
          );
        }
      } else if (this.state.selectedPayment === "") {
        this.setState({
          payment_option_error: "Please select a Payment Option.",
        });
        if (
          this.state.selectedAddress !== "" ||
          this.state.selectedPickupPoint !== ""
        ) {
          window.scrollTo(
            0,
            this.selectPaymentOptionRef.current.offsetTop -
              this.state.navbarOffset
          );
        }
        hasOtherError = true;
      } else if (this.state.remarks.length > 350) {
        hasOtherError = true;
        this.setState({
          remarks_error: "Remarks should not exceed 350 characters.",
        });
      }
      if (!hasOtherError) {
        this.setState({
          isCheckingIfSoldout: true,
        });
        this.props.refreshCartFromToken((cart) => {
          if (cart === "ERROR") {
            this.showErrorModal();
          } else {
            const soldOutItems = cart
              .filter((cartItem) => cartItem.is_soldout === "true")
              .map((cartItem) => cartItem.name);
            const notEnoughItems = cart
              .filter(
                (cartItem) =>
                  cartItem.current_stock > -1 &&
                  cartItem.current_stock < cartItem.quantity
              )
              .map((cartItem) => cartItem.name);
            if (soldOutItems.length > 0) {
              this.props.setModalType("basic");
              this.props.setModalTitle("Sold Out!");
              this.props.setModalBody(
                `Unfortunately, some items in your cart are soldout already. Kindly REMOVE them and review again your order. The following items are soldout already: [${soldOutItems.join(
                  ", "
                )}]`
              );
              this.props.setModalConfirmButton("OK");
              this.props.showModal();
              this.setState({
                isCheckingIfSoldout: false,
              });
            } else if (notEnoughItems.length > 0) {
              this.props.setModalType("basic");
              this.props.setModalTitle("No Enough Stock!");
              this.props.setModalBody(
                `Unfortunately, some items in your cart does not have enough stock. Kindly REMOVE some and review again your order. The said items are: [${notEnoughItems.join(
                  ", "
                )}]`
              );
              this.props.setModalConfirmButton("OK");
              this.props.showModal();
              this.setState({
                isCheckingIfSoldout: false,
              });
            } else {
              this.setState({
                isLoading: true,
              });
              if (!this.props.orderPeriod[0]) {
                this.props.setModalType("basic");
                this.props.setModalTitle("Notice");
                this.props.setModalBody(
                  "The shop is currently closed. You can't proceed to order."
                );
                this.props.setModalConfirmButton("OK");
                this.props.showModal();
                this.setState({
                  isLoading: false,
                });
              } else {
                let totalBayongs = this.props.cart.reduce(function (
                  prev,
                  product
                ) {
                  if (
                    isNaN(product.bayong_allocation) ||
                    product.is_available !== "true"
                  ) {
                    return prev;
                  }
                  return prev + +(product.bayong_allocation * product.quantity);
                },
                0);
                totalBayongs = Math.ceil(totalBayongs);
                const orderBody = {
                  order_period: this.props.orderPeriod[0].id,
                  delivery: this.state.selectedDelivery,
                  payment_option: this.state.selectedPayment,
                  address: this.state.selectedAddress,
                  pickuppoint: this.state.selectedPickupPoint,
                  bayong_to_return: this.state.returningBayongCount,
                  total_bayongs: totalBayongs,
                  remarks: this.state.remarks,
                  voucher: this.state.appliedVoucher,
                };
                if (
                  this.state.selectedDelivery !== "" &&
                  this.state.selectedPayment !== "" &&
                  (this.state.selectedAddress !== "" ||
                    this.state.selectedPickupPoint !== "")
                ) {
                  this.props.orderProducts(orderBody, (cb) => {
                    if (cb === "ERROR") {
                      this.showErrorModal();
                    } else {
                      if (cb.status === 400) {
                        Object.keys(cb.data).forEach((key, index) => {
                          const errors = cb.data[key].join(" ");
                          if (key === "non_field_errors") {
                            this.setState({
                              [key]: errors,
                            });
                          } else {
                            this.setState({
                              [key + "_error"]: errors,
                            });
                          }
                        });
                      } else if (!hasOtherError && cb.status === 201) {
                        this.props.setModalType("basic");
                        this.props.setModalTitle("Checkout");
                        this.props.setModalBody("Order successfully placed!");
                        this.props.setModalConfirmButton("OK");
                        this.props.setPixelShopback(
                          `https://shopback.go2cloud.org/aff_l?offer_id=3022&adv_sub=${cb.data.id}&amount=${cb.data.initial_order_price}&conversion_unique_id=3022PH${cb.data.id}`
                        );
                        this.props.showModal();
                        this.props.getUserProfile();
                        this.props.history.push(`/shop`);
                        this.props.setConfirmAction(this.goToOrders);
                      }
                      this.setState({
                        isLoading: false,
                        isCheckingIfSoldout: false,
                      });
                    }
                  });
                } else {
                  this.setState({
                    isLoading: false,
                    isCheckingIfSoldout: false,
                  });
                }
              }
            }
          }
        });
      }
    }
  };

  selectDelivery = (selectedDelivery) => {
    this.setState({
      selectedDelivery,
      selectedPayment: "",
      selectedAddress: "",
      selectedPickupPoint: "",
      delivery_error: "",
      payment_option_error: "",
      address_error: "",
      pickuppoint_error: "",
    });
    if (
      selectedDelivery ===
        this.state.deliveries.filter(
          (delivery) => delivery.name === "Delivery"
        )[0].id &&
      this.state.config.delivery_cost
    ) {
      this.setState({
        shippingCost: this.state.config.delivery_cost,
      });
    } else if (
      selectedDelivery ===
        this.state.deliveries.filter(
          (delivery) => delivery.name === "Pickup"
        )[0].id &&
      this.state.config.pickup_cost
    ) {
      this.setState({
        shippingCost: this.state.config.pickup_cost,
      });
    }
    this.applyVoucher();
  };
  selectPayment = (selectedPayment) => {
    this.setState({
      selectedPayment,
      payment_option_error: "",
    });
    this.applyVoucher();
  };

  selectAddress = (selectedAddress) => {
    this.setState({
      selectedAddress,
      address_error: "",
    });
    this.applyVoucher();
  };

  selectRemarks = (remarks) => {
    this.setState({
      remarks,
      error: "",
      remarks_error: "",
    });
  };

  selectPickupPoint = (selectedPickupPoint) => {
    this.setState({
      selectedPickupPoint,
      pickuppoint_error: "",
    });
    this.applyVoucher();
  };

  subtractQty = (productId) => {
    const { cart } = this.props;
    let currentQty = cart.find((prop) => prop.id === productId).quantity;
    if (currentQty > 1) {
      let productToUpdate = cart.find((prop) => prop.id === productId);
      productToUpdate.quantity = currentQty - 1;
      this.props.updateProductToCart(cart, productToUpdate);
    }
  };

  addQty = (productId) => {
    const { cart } = this.props;
    let currentQty = cart.find((prop) => prop.id === productId).quantity;
    let productToUpdate = cart.find((prop) => prop.id === productId);
    productToUpdate.quantity = currentQty + 1;
    this.props.updateProductToCart(cart, productToUpdate);
  };

  removeCart = (product) => {
    const { cart } = this.props;
    let productToDelete = cart.find((prop) => prop.id === product.id);
    removeProductToCart(productToDelete);
    const updatedCart = cart.filter((prop) => prop.id !== product.id);
    this.props.updateCart(updatedCart);
  };

  goToProduct = (product) => {
    this.props.history.push(`/shop/${product.id}`, { product });
  };

  goToShop = () => {
    this.props.history.push(`/shop`);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
    if (window.innerWidth > 991) {
      this.setState({ navbarOffset: 180 });
    } else if (window.innerWidth > 767) {
      this.setState({ navbarOffset: 320 });
    } else {
      this.setState({ navbarOffset: 234 });
    }
  };

  subtractReturnBayongQty = () => {
    if (0 < this.state.returningBayongCount) {
      this.setState({
        returningBayongCount: this.state.returningBayongCount - 1,
        bayongCredits: this.state.bayongCredits - 1,
      });
    }
  };

  addReturnBayongQty = () => {
    if (this.props.user.bayong_onhand > this.state.returningBayongCount) {
      this.setState({
        returningBayongCount: this.state.returningBayongCount + 1,
        bayongCredits: this.state.bayongCredits + 1,
      });
    }
  };

  renderPageHeader = () => (
    <div>
      <PageHeader>
        <h2>Checkout</h2>
        <h5 className="p">
          Your order will be delivered on:{" "}
          <span className="heading text-primary-dark">
            {this.props.orderPeriod[0] &&
              getDateText(this.props.orderPeriod[0].delivery_date)}
          </span>
        </h5>
      </PageHeader>
    </div>
  );

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  hideBayongModalClose = () => {
    this.setState({
      showModalBayong: false,
    });
  };

  cancelBayongModalClose = () => {
    if (!this.state.showReturnBayongs) {
      this.setState({
        returningBayongCount: 0,
        bayongCredits: 0,
      });
    }
    this.hideBayongModalClose();
  };

  returnBayong = () => {
    this.setState({
      showReturnBayongs: true,
    });
    this.hideBayongModalClose();
  };

  applyVoucher = () => {
    if (this.state.voucher !== "") {
      checkVoucher(this.state.voucher, (cb) => {
        if (cb.valid) {
          if (
            (cb.payment_option !== "None" &&
              cb.payment_option !== this.state.selectedPayment) ||
            (cb.pickuppoint !== "None" &&
              cb.pickuppoint !== this.state.selectedPickupPoint)
          ) {
            this.setState({
              voucherMessage: "Voucher conditions are not met.",
              showVoucherMessage: true,
              voucherMessageError: true,
              appliedVoucher: "",
              discount: 0,
              isPercentage: false,
            });
            return false;
          }
          const totalPrice = this.props.cart.reduce(function (prev, product) {
            if (isNaN(product.price) || product.is_available !== "true") {
              return prev;
            }
            return prev + +(product.price * product.quantity);
          }, 0);
          if (cb.min_amount > totalPrice) {
            this.setState({
              voucherMessage:
                "Voucher conditions are not met. Minimum order value for voucher did not reach.",
              showVoucherMessage: true,
              voucherMessageError: true,
              appliedVoucher: "",
              discount: 0,
              isPercentage: false,
            });
            return false;
          }
          this.setState({
            voucherMessage: "Voucher successfully applied.",
            showVoucherMessage: true,
            voucherMessageError: false,
            appliedVoucher: cb.voucher,
            discount: cb.discount_amount,
            isPercentage: cb.isPercentage,
          });
        } else {
          this.setState({
            voucherMessage: cb.error,
            showVoucherMessage: true,
            voucherMessageError: true,
            appliedVoucher: "",
            discount: 0,
            isPercentage: false,
          });
        }
      });
    } else {
      this.setState({
        showVoucherMessage: false,
        voucherMessageError: false,
        voucherMessage: "",
        voucher: "",
        appliedVoucher: "",
        discount: 0,
        isPercentage: false,
      });
    }
  };

  render() {
    const totalPrice = this.props.cart.reduce(function (prev, product) {
      if (isNaN(product.price) || product.is_available !== "true") {
        return prev;
      }
      return prev + +(product.price * product.quantity);
    }, 0);
    const totalPacks = this.props.cart.reduce(function (prev, product) {
      if (isNaN(product.price)) {
        return prev;
      }
      return prev + +product.quantity;
    }, 0);
    let totalBayongs = this.props.cart.reduce(function (prev, product) {
      if (isNaN(product.bayong_allocation) || product.is_available !== "true") {
        return prev;
      }
      return prev + +(product.bayong_allocation * product.quantity);
    }, 0);
    totalBayongs = Math.ceil(totalBayongs);
    if (!this.props.orderPeriod[0]) {
      return (
        <MainLayout history={this.props.history}>
          {this.renderPageHeader()}
          <Container>The shop is now closed.</Container>
        </MainLayout>
      );
    }
    let computedDiscount = this.state.discount;
    if (this.state.isPercentage) {
      computedDiscount = this.state.discount * 0.01 * totalPrice;
    }

    const getEstimatedStickerCount = (amount) => {
      return Math.floor(amount / 1000);
    };
    const totalEstimatedCount = getEstimatedStickerCount(totalPrice);

    return (
      <MainLayout history={this.props.history}>
        {this.renderPageHeader()}
        {this.state.isLoading ? (
          <div>
            <div style={style.pleaseWaitContainer}>Please wait...</div>
            <div style={style.spinnerContainer}>
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="secondary" />
              <Spinner animation="grow" variant="secondary" />
            </div>
          </div>
        ) : this.state.cart.length == 0 ? (
          <Container>
            You cannot checkout. Cart Empty.
            <Button
              variant="primary"
              onClick={this.goToShop}
              className="d-block mt-4"
            >
              Go to Shop
            </Button>
          </Container>
        ) : (
          <Container>
            <Container className="mb-3">
              <SelectDelivery
                selectDelivery={this.selectDelivery}
                selectPayment={this.selectPayment}
                error={this.state.delivery_error}
                config={this.state.config}
              />
              {this.state.deliveries.length > 0 &&
                this.state.selectedDelivery ===
                  this.state.deliveries.filter(
                    (delivery) => delivery.name === "Delivery"
                  )[0].id && (
                  <SelectAddress
                    selectAddress={this.selectAddress}
                    error={this.state.address_error}
                    refProp={this.selectAddressRef}
                  />
                )}
              {this.state.deliveries.length > 0 &&
                this.state.selectedDelivery ===
                  this.state.deliveries.filter(
                    (delivery) => delivery.name === "Pickup"
                  )[0].id && (
                  <SelectPickupPoint
                    selectPickupPoint={this.selectPickupPoint}
                    error={this.state.pickuppoint_error}
                    refProp={this.selectPickupPointRef}
                  />
                )}
            </Container>
            <Card body className="mb-3" style={style.cartMainContainer}>
              <Row className="mb-3">
                <Col
                  xs={12}
                  className="d-flex d-flex"
                  style={commonStyles.flexDirectionCol}
                >
                  <h5 className="mb-3">2. Review your Order:</h5>
                  <Row className="mb-3 d-none d-md-block">
                    <Col xs={12}>
                      <Row>
                        <Col
                          xs={{ span: 2, offset: 8 }}
                          style={{
                            ...style.productDetailLabel,
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                          className="pr-0 mb-0"
                        >
                          Approx. Weight
                        </Col>
                        <Col
                          xs={{ span: 2 }}
                          style={{
                            ...style.productDetailLabel,
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                          className="mb-0"
                        >
                          Estimated Price
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {this.state.cart.map((product) => {
                    return (
                      <Row className="mb-3">
                        <Col xs={12}>
                          <Card className="p-2">
                            <Row>
                              <Col xs={12} style={commonStyles.flexAlignCenter}>
                                <Card.Body className="p-1 py-3">
                                  <Row>
                                    <Col xs={4} md={2}>
                                      <div
                                        style={{
                                          ...style.cartImageContainer,
                                          ...commonStyles.flex,
                                          ...commonStyles.flexAlignCenter,
                                        }}
                                        className="cartImageContainer"
                                        onClick={() =>
                                          this.goToProduct(product)
                                        }
                                      >
                                        <Image
                                          src={removeBaseUrl(product.image)}
                                          style={style.cartImage}
                                          className="cartImage"
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xs={8}
                                      md={3}
                                      style={{
                                        ...commonStyles.flex,
                                        ...commonStyles.flexAlignCenter,
                                      }}
                                    >
                                      <div
                                        className="mb-3"
                                        style={style.hoverCursor}
                                        onClick={() =>
                                          this.goToProduct(product)
                                        }
                                      >
                                        <Card.Title className="mb-0">
                                          {product.name}
                                        </Card.Title>
                                        <Card.Text>
                                          ₱{product.price} /{" "}
                                          {product.base_weight_text}
                                        </Card.Text>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={{ span: 10, order: 6 }}
                                      md={{ span: 3, order: 1 }}
                                      style={{
                                        ...commonStyles.flex,
                                        ...commonStyles.flexAlignCenter,
                                      }}
                                    >
                                      {!isNaN(product.price) && (
                                        <QuantitySelector
                                          className="mt-0 mb-0"
                                          subtractQty={() =>
                                            this.subtractQty(product.id)
                                          }
                                          addQty={() => this.addQty(product.id)}
                                          quantity={product.quantity}
                                          quantityType={product.qty_type}
                                          disabled={
                                            product.is_available !== "true"
                                          }
                                          limit={product.current_stock}
                                        />
                                      )}
                                    </Col>
                                    <Col
                                      xs={{ span: 6, order: 2 }}
                                      md={{ span: 2, order: 12 }}
                                      style={{
                                        ...commonStyles.flex,
                                        ...commonStyles.flexAlignCenter,
                                        ...commonStyles.flexJustifyConentCenter,
                                      }}
                                    >
                                      <h5 className="ml-md-4 mb-0">
                                        {!isNaN(product.price) &&
                                        product.is_available === "true"
                                          ? `₱${
                                              product.price * product.quantity
                                            }`
                                          : product.is_soldout === "true"
                                          ? "Sold Out"
                                          : "Not Available"}
                                      </h5>
                                    </Col>
                                    <Col
                                      xs={{ span: 6, order: 1 }}
                                      md={{ span: 1, order: 9 }}
                                      style={{
                                        ...commonStyles.flex,
                                        ...commonStyles.flexAlignCenter,
                                        ...commonStyles.flexJustifyConentCenter,
                                      }}
                                    >
                                      <h5 className="ml-md-4 mb-0">
                                        {getSimplifiedWeight(
                                          product.base_weight * product.quantity
                                        )}
                                      </h5>
                                    </Col>
                                    <Col
                                      xs={{ span: 6, order: 3 }}
                                      className="d-block d-md-none"
                                    >
                                      <span
                                        style={style.mobileProductDetailLabel}
                                      >
                                        Approx. Weight
                                      </span>
                                    </Col>
                                    <Col
                                      xs={{ span: 6, order: 4 }}
                                      className="d-block d-md-none"
                                    >
                                      <span
                                        style={style.mobileProductDetailLabel}
                                      >
                                        Subtotal Price
                                      </span>
                                    </Col>
                                    <Col
                                      xs={{ span: 2, order: 12 }}
                                      md={{ span: 1, order: 6 }}
                                      style={{
                                        ...commonStyles.flex,
                                      }}
                                      className="removeButtonContainer"
                                    >
                                      {!isNaN(product.price) && (
                                        <Button
                                          className="mt-0 mb-0"
                                          variant="outline-danger"
                                          onClick={() =>
                                            this.removeCart(product)
                                          }
                                          style={
                                            commonStyles.defaultMarginBotttom
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="trash"
                                            size="sm"
                                            className="text-danger"
                                          />
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="mb-3">
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          md={{ span: 5, offset: 5 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <h5 className="ml-md-4">
                            Order Subtotal
                            {` (${totalPacks} ${
                              totalPacks > 1 ? "items" : "item"
                            })`}
                            :
                          </h5>
                        </Col>
                        <Col
                          xs={{ span: 2 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          <h5 className="ml-5 ml-md-0">₱{totalPrice}</h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          md={{ span: 5, offset: 5 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <h6 className="ml-md-4">Number of Bayongs:</h6>
                        </Col>
                        <Col
                          xs={{ span: 2 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          <h5 className="ml-5 ml-md-0">{totalBayongs}</h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-end">
                      <small class="form-text">
                        The weights above are only estimates. On the day of
                        delivery, we'll weigh each of your items to accurately
                        determine the final price.
                      </small>
                    </Col>
                    {totalEstimatedCount > 0 && (
                      <Col xs={12} className="d-flex justify-content-end mt-4">
                        <small class="form-text">
                          You are entitled to have{" "}
                          <strong className="text-primary-text">
                            {totalEstimatedCount} promo sticker/s
                          </strong>{" "}
                          once the ordered is paid and delivered. Click{" "}
                          <a
                            className="text-dark-green"
                            href="/rewards"
                            target="_blank"
                          >
                            here
                          </a>{" "}
                          to view more details about this promo.
                        </small>
                      </Col>
                    )}
                    {this.state.stickerCount >= this.state.stickerLimit && (
                      <Col xs={12} className="d-flex justify-content-end mt-4">
                        <strong className="text-primary-text">
                          Ordering will accomplish your rewards card with a
                          total of {this.state.stickerLimit} stickers! Please
                          expect a free gift from us. :)
                        </strong>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
            <Card
              body
              className={
                this.state.selectedDelivery !== ""
                  ? "d-block mb-3"
                  : "d-none mb-3"
              }
              ref={this.selectPaymentOptionRef}
            >
              <SelectPaymentOption
                selectedDelivery={this.state.selectedDelivery}
                selectPayment={this.selectPayment}
                error={this.state.payment_option_error}
              />
              <Row>
                <Col
                  xs={{ span: 12, offset: 12 }}
                  lg={{ span: 6 }}
                  className="mb-5"
                >
                  <h6 className="mb-3">Additional Notes:</h6>
                  <textarea
                    name="remarks"
                    onChange={this.handleChange}
                    onInput={this.selectRemarks}
                    className={`pr-0 mb-0 w-100${
                      this.state.remarks_error && " text-danger"
                    }`}
                    rows="5"
                  ></textarea>
                  {this.state.remarks_error && (
                    <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
                      {this.state.remarks_error}
                    </medium>
                  )}
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <p className="ml-md-4">
                            Subtotal
                            {` (${totalPacks} ${
                              totalPacks > 1 ? "items" : "item"
                            })`}
                            :
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 4 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          {this.state.discount > 0 ? (
                            <s>₱{totalPrice}</s>
                          ) : (
                            <p>₱{totalPrice}</p>
                          )}
                        </Col>
                      </Row>
                      {this.state.discount > 0 && (
                        <Fragment>
                          <Row>
                            <Col
                              xs={{ span: 8 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyContentFlexEnd,
                              }}
                            >
                              <p className="ml-md-4 mb-0">Voucher Discount:</p>
                            </Col>
                            <Col
                              xs={{ span: 4 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyConentCenter,
                              }}
                            >
                              <p className="mb-0">₱{computedDiscount}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={{ span: 8 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyContentFlexEnd,
                              }}
                            >
                              <p className="ml-md-4 mb-2">
                                Subtotal after discount:
                              </p>
                            </Col>
                            <Col
                              xs={{ span: 4 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyConentCenter,
                              }}
                            >
                              <p className="mb-2">
                                ₱{totalPrice - computedDiscount}
                              </p>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row
                        style={{
                          ...commonStyles.flex,
                          ...commonStyles.flexAlignCenter,
                          ...commonStyles.flexJustifyContentFlexEnd,
                        }}
                      >
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 9 }}
                          md={{ span: 6 }}
                          lg={{ span: 9 }}
                          xl={{ span: 8 }}
                          className="mb-3 mr-sm-3 mr-md-5 mr-lg-3 mr-xl-5"
                        >
                          <InputGroup>
                            <FormControl
                              placeholder="Input Voucher"
                              name="voucher"
                              onChange={this.handleChange}
                            />
                            <InputGroup.Append>
                              <Button
                                variant="outline-primary"
                                onClick={this.applyVoucher}
                              >
                                Apply
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                          {this.state.showVoucherMessage && (
                            <small
                              className={
                                this.state.voucherMessageError
                                  ? "text-danger"
                                  : "text-primary-dark"
                              }
                            >
                              {this.state.voucherMessage}
                            </small>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <p className="ml-md-4 mb-0">
                            Bayong Cost
                            {` (${totalBayongs} ${
                              totalBayongs > 1 ? "bayongs" : "bayong"
                            })`}
                            :
                          </p>
                        </Col>
                        <Col
                          xs={{ span: 4 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          {this.state.bayongCredits > 0 ? (
                            this.state.bayongCredits >= totalBayongs ? (
                              <s>₱{this.state.bayongCost * totalBayongs}</s>
                            ) : (
                              <Fragment>
                                <s>₱{this.state.bayongCost * totalBayongs}</s>
                                <p className="mb-0 ml-1">
                                  ₱
                                  {this.state.bayongCost * totalBayongs -
                                    this.state.bayongCost *
                                      this.state.bayongCredits}
                                </p>
                              </Fragment>
                            )
                          ) : (
                            <p className="mb-0">
                              ₱{this.state.bayongCost * totalBayongs}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {this.state.bayongCredits > 0 && (
                    <Row>
                      <Col xs={12} style={commonStyles.flexAlignCenter}>
                        <Row>
                          <Col
                            xs={{ span: 8 }}
                            style={{
                              ...commonStyles.flex,
                              ...commonStyles.flexAlignCenter,
                              ...commonStyles.flexJustifyContentFlexEnd,
                            }}
                          >
                            <small class="form-text text-primary-dark">
                              Bayong Credits: {this.state.bayongCredits}
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {this.props.user.bayong_onhand > 0 &&
                    !this.state.isThereOpenOrders && (
                      <Row className="mb-3">
                        <Col xs={12} style={commonStyles.flexAlignCenter}>
                          <Row>
                            <Col
                              xs={{ span: 8 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyContentFlexEnd,
                              }}
                            >
                              <small className="ml-md-4 mb-0 text-primary-dark">
                                You have {this.props.user.bayong_onhand} bayongs
                                on hand.
                              </small>
                            </Col>
                            <Col
                              xs={{ span: 4 }}
                              style={{
                                ...commonStyles.flex,
                                ...commonStyles.flexAlignCenter,
                                ...commonStyles.flexJustifyConentCenter,
                              }}
                            >
                              {this.state.showReturnBayongs ? (
                                <BayongQuantitySelector
                                  quantity={this.state.returningBayongCount}
                                  subtractQty={() =>
                                    this.subtractReturnBayongQty()
                                  }
                                  addQty={() => this.addReturnBayongQty()}
                                />
                              ) : (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      showModalBayong: true,
                                      firstOpenModalBayong: true,
                                    });
                                  }}
                                >
                                  <small className="ml-md-4 mb-0 text-primary-dark">
                                    Return a Bayong?
                                  </small>
                                </a>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  <Row className="mb-3">
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <p className="ml-md-4">Shipping Total:</p>
                        </Col>
                        <Col
                          xs={{ span: 4 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          <p>₱{this.state.shippingCost}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} style={commonStyles.flexAlignCenter}>
                      <Row>
                        <Col
                          xs={{ span: 8 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyContentFlexEnd,
                          }}
                        >
                          <h5 className="ml-md-4">Total Estimated Payment:</h5>
                        </Col>
                        <Col
                          xs={{ span: 4 }}
                          style={{
                            ...commonStyles.flex,
                            ...commonStyles.flexAlignCenter,
                            ...commonStyles.flexJustifyConentCenter,
                          }}
                        >
                          <h5>
                            ₱
                            {totalPrice -
                              computedDiscount +
                              this.state.bayongCost * totalBayongs -
                              (this.state.bayongCredits > 0
                                ? this.state.bayongCredits >= totalBayongs
                                  ? this.state.bayongCost * totalBayongs
                                  : this.state.bayongCost *
                                    this.state.bayongCredits
                                : 0) +
                              this.state.shippingCost}
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-end mb-3">
                      <small class="form-text">
                        By confirming your order, you agree to our terms and
                        conditions.
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="d-flex justify-content-end">
                      {totalPrice < this.state.config.min_price ||
                      this.state.isPendingToPayOrders ||
                      this.state.isCheckingIfSoldout ? (
                        <Button variant="disabled">
                          <span>
                            {this.state.isCheckingIfSoldout
                              ? "Loading..."
                              : "Place Order"}
                          </span>
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={this.placeOrder}>
                          {this.state.isLoading ? (
                            <div>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </div>
                          ) : (
                            <span>Place Order</span>
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {totalPrice < this.state.config.min_price && (
                    <Row>
                      <Col xs={12} className="d-flex justify-content-end">
                        <small class="form-text text-danger">
                          Order Subtotal should be at least PHP
                          {this.state.config.min_price} to proceed.
                        </small>
                      </Col>
                    </Row>
                  )}
                  {this.state.isPendingToPayOrders && (
                    <Row>
                      <Col xs={12} className="d-flex justify-content-end">
                        <small class="form-text text-danger">
                          You have still have an order you haven't paid yet.
                          Please settle it first.
                        </small>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        )}
        <ReactModal
          show={this.state.showModalBayong}
          onHide={this.hideBayongModalClose}
        >
          <ReactModal.Header closeButton>
            <ReactModal.Title>Return a Bayong</ReactModal.Title>
          </ReactModal.Header>

          <ReactModal.Body>
            <small>
              You have {this.props.user.bayong_onhand} bayongs on hand.
            </small>
            <BayongQuantitySelector
              quantity={this.state.returningBayongCount}
              subtractQty={() => this.subtractReturnBayongQty()}
              addQty={() => this.addReturnBayongQty()}
            />
          </ReactModal.Body>

          <ReactModal.Footer>
            <Button variant="secondary" onClick={this.cancelBayongModalClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.returnBayong}
              disabled={this.state.returningBayongCount === 0}
            >
              Return
            </Button>
          </ReactModal.Footer>
        </ReactModal>
      </MainLayout>
    );
  }
}

const style = {
  hoverCursor: {
    cursor: "pointer",
  },
  cartMainContainer: {
    backgroundColor: "#f9f9f9",
  },
  cartImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
    cursor: "pointer",
  },
  cartImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  productDetailLabel: {
    fontSize: "10px",
    display: "block",
    textAlign: "center",
    marginBottom: "10px",
  },
  mobileProductDetailLabel: {
    fontSize: "10px",
    display: "block",
    textAlign: "center",
    marginBottom: "10px",
  },
  pleaseWaitContainer: {
    textAlign: "center",
    marginBottom: "20px",
  },
  spinnerContainer: {
    textAlign: "center",
    marginBottom: "120px",
  },
};

const mapPropsToState = (state) => ({
  deliveries: state.coreReducer.deliveries,
  cart: state.shopReducer.cart,
  orderPeriod: state.coreReducer.orderPeriod,
  user: state.auth.user,
});

export default connect(mapPropsToState, {
  orderProducts,
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  setConfirmAction,
  setPixelShopback,
  showModal,
  getAndSetDeliveries,
  getAndSetPickupPoints,
  updateCart,
  updateProductToCart,
  getUserProfile,
  refreshCartFromToken,
})(Checkout);
