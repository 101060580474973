import React, { Component } from "react";

import Image from "react-bootstrap/Image";

import { connect } from "react-redux";

import "./styles/FeaturedProducts.css";

import { setFeaturedProducts } from "../../actions/ProductsActions";

export class FeaturedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredProducts: []
    };
    this.props.setFeaturedProducts();
  }

  componentDidMount() {
    const { featuredProducts } = this.props;
    this.setState({
      featuredProducts
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      featuredProducts: props.featuredProducts
    });
  }

  goToProduct = product => {
    this.props.history.push(`/shop/${product.id}`, { product });
  };

  render() {
    const { featuredProducts } = this.state;
    return (
      <div>
        <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
          {featuredProducts[0] && (
            <div
              className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden"
              style={style.featuredProductContainer}
              onClick={() => this.goToProduct(featuredProducts[0].product)}
            >
              <div className="my-3 py-3">
                <div
                  style={style.featuredProductImageContainer}
                  className="featuredProductImageContainer"
                >
                  <Image
                    src={featuredProducts[0].product.image}
                    style={style.featuredProductImage}
                    className="featuredProductImage"
                  />
                </div>
                <h2 className="display-5">
                  {featuredProducts[0].product.name}
                </h2>
                <p className="lead">
                  {featuredProducts[0].product.description}
                </p>
              </div>
              <div className="bg-light shadow-sm mx-auto" />
            </div>
          )}
          {featuredProducts[1] && (
            <div
              className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden"
              style={style.featuredProductContainer}
              onClick={() => this.goToProduct(featuredProducts[1].product)}
            >
              <div className="my-3 py-3">
                <div
                  style={style.featuredProductImageContainer}
                  className="featuredProductImageContainer"
                >
                  <Image
                    src={featuredProducts[1].product.image}
                    style={style.featuredProductImage}
                    className="featuredProductImage"
                  />
                </div>
                <h2 className="display-5">
                  {featuredProducts[1].product.name}
                </h2>
                <p className="lead">
                  {featuredProducts[1].product.description}
                </p>
              </div>
              <div className="bg-dark shadow-sm mx-auto" />
            </div>
          )}
        </div>

        <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
          {featuredProducts[2] && (
            <div
              className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden"
              style={style.featuredProductContainer}
              onClick={() => this.goToProduct(featuredProducts[2].product)}
            >
              <div className="my-3 py-3">
                <div
                  style={style.featuredProductImageContainer}
                  className="featuredProductImageContainer"
                >
                  <Image
                    src={featuredProducts[2].product.image}
                    style={style.featuredProductImage}
                    className="featuredProductImage"
                  />
                </div>
                <h2 className="display-5">
                  {featuredProducts[2].product.name}
                </h2>
                <p className="lead">
                  {featuredProducts[2].product.description}
                </p>
              </div>
              <div className="bg-dark shadow-sm mx-auto" />
            </div>
          )}
          {featuredProducts[3] && (
            <div
              className="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden"
              style={style.featuredProductContainer}
              onClick={() => this.goToProduct(featuredProducts[3].product)}
            >
              <div className="my-3 py-3">
                <div
                  style={style.featuredProductImageContainer}
                  className="featuredProductImageContainer"
                >
                  <Image
                    src={featuredProducts[3].product.image}
                    style={style.featuredProductImage}
                    className="featuredProductImage"
                  />
                </div>
                <h2 className="display-5">
                  {featuredProducts[3].product.name}
                </h2>
                <p className="lead">
                  {featuredProducts[3].product.description}
                </p>
              </div>
              <div className="bg-light shadow-sm mx-auto" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const style = {
  featuredProductContainer: {
    cursor: "pointer"
  },
  featuredProductImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    marginBottom: 20,
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
    maxWidth: 250,
    maxHeight: 250
  },
  featuredProductImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

const mapPropsToState = state => ({
  featuredProducts: state.productReducer.featuredProducts
});

export default connect(
  mapPropsToState,
  { setFeaturedProducts }
)(FeaturedProducts);
