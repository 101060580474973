import React, { Component } from "react";

export class SelectButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      active: false,
      hover: false,
      id: props.id,
      click: props.click,
      disabled: props.disabled
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.selected !== this.props.selected) {
      if (this.props.selected === this.state.id) {
        this.setState({
          active: true
        });
      } else {
        this.setState({
          active: false
        });
      }
    }
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  selectButton = () => {
    this.setState({ active: true });
  };

  unSelectButton = () => {
    this.setState({ active: false });
  };

  render() {
    return (
      <div
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={() => {
          this.state.click(this.state.id);
        }}
        style={{
          ...(this.state.active
            ? style.selectButtonContainerActive
            : style.selectButtonContainer),
          ...(this.state.hover && !this.state.active
            ? style.selectButtonContainerHoverColor
            : {}),
          ...(this.state.disabled && style.selectButtonContainerDisabled)
        }}
      >
        <b
          style={{
            ...(this.state.active
              ? style.selectButtonTextActive
              : style.selectButtonTextInactive),
            ...(this.state.disabled && style.selectButtonTextDisabled)
          }}
        >
          {this.state.label}
        </b>
      </div>
    );
  }
}

const style = {
  selectButtonContainer: {
    cursor: "pointer",
    padding: 20,
    borderRadius: "0.25rem",
    border: "1px solid #e6e6e6",
    textAlign: "center",
    backgroundColor: "whitesmoke",
    color: "black",
    marginBottom: 10
  },
  selectButtonContainerActive: {
    cursor: "pointer",
    padding: 20,
    borderRadius: "0.25rem",
    border: "1px solid #CAF17C",
    textAlign: "center",
    backgroundColor: "#CAF17C",
    marginBottom: 10
  },
  selectButtonContainerHoverColor: {
    backgroundColor: "#ececec"
  },
  selectButtonTextInactive: {
    color: "#b1b1b1"
  },
  selectButtonTextActive: {
    color: "#7fad23"
  },
  selectButtonContainerDisabled: {
    border: "1px solid #eaeaea",
    backgroundColor: "white"
  },
  selectButtonTextDisabled: {
    color: "#eaeaea"
  }
};

export default SelectButton;
