import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class Policies extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Order Policies</h2>
          </PageHeader>
        </div>
        <Container>
          <ul class="list-unstyled">
            <li>
              <h2>1. Order Cancellation Policy</h2>
              <p>
                Your order is considered final after our designated cutoff time.
                Should you have amendments to your order, please contact us by
                e-mail or SMS before the order cut-off.
              </p>
              <p>
                Due to the nature of our business, we are no longer able to
                cancel or adjust your order after cutoff, as we have already
                coordinated it with our farmers for their harvest.
              </p>
            </li>
            <br />
            <li>
              <h2>2. Wait Time Policy</h2>
              <p>
                In order to deliver your produce promptly and within its
                freshest state, our team will implement a wait time policy of a
                maximum of 30 minutes only per drop-off, from time of arrival.
                During this period, we request that you would have checked
                already the quantity and quality of the delivered produce and
                have signed off on the invoice as provided by our partner
                courier.
              </p>
              <p>
                We request that in case of you not being in the delivery address
                during our specified delivery time, kindly have a person to
                receive the goods as we’re not able to process returns due to
                the sensitivity of our fresh produce.
              </p>
            </li>
            <br />
            <li>
              <h2>3. Refund Policy</h2>
              <p>
                We at BukidFresh, strive to provide our customers with the freshest 
                harvest and superior quality products. There will be occasions 
                however when the quality of an item may not be to your liking. 
                Should an occasion such as this arise, please call the attention 
                of our staff on delivery so that we can immediately attend to the 
                matter and resolve the issue.
              </p>
              <p>
                For any item found to be questionable in quality, please fill up
                our Delivery Feedback Form which will be sent via e-mail
                together with our invoice. We request for you to fill up the
                said form with clear photos of the product and upon
                investigation, we will be willing to provide a refund.
              </p>
              <span>
                <strong>Delivery Feedback Form:</strong>{" "}
                <a
                  href="https://forms.gle/35k7me3kvum6BYt7A"
                  target="_blank"
                  className="text-primary-dark"
                >
                  https://forms.gle/35k7me3kvum6BYt7A
                </a>
              </span>
            </li>
          </ul>
        </Container>
      </MainLayout>
    );
  }
}

export default Policies;
