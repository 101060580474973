import axios from "axios";
import { API_URL } from "../constants/ApiConfig";

import { SET_PRODUCTS, SET_FEATURED_PRODUCTS } from "../constants/Types";

export const setProducts = () => dispatch => {
  axios
    .get(`${API_URL}/products/`, {})
    .then(function(response) {
      dispatch({
        type: SET_PRODUCTS,
        payload: response.data.results
      });
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export const getProducts = async (reqParams) => {
  let params = "";
  if (reqParams.page) {
    params = `${params}page=${reqParams.page}&`
  }
  if (reqParams.category) {
    params = `${params}category=${reqParams.category}&`
  }
  if (reqParams.q) {
    params = `${params}q=${reqParams.q}&`
  }
  if (params !== "") {
    params = `?${params}`
  }
  let products = [];
  await axios
    .get(`${API_URL}/products/${params}`, {})
    .then(function(response) {
      products = response.data;
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
  return products;
};

export const getProduct = (id, cb) => {
  axios
    .get(`${API_URL}/products/${id}/`, {})
    .then(function(response) {
      cb(response.data);
    })
    .catch(function(error) {
      cb(500)
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export const setFeaturedProducts = () => dispatch => {
  axios
    .get(`${API_URL}/featured-products/`, {})
    .then(function(response) {
      dispatch({
        type: SET_FEATURED_PRODUCTS,
        payload: response.data
      });
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};
