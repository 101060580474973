import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const AnimatedSlideUp = ({ delay, children }) => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
    triggerOnce: true
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const props = useSpring({
    opacity: inView && isLoaded ? 1 : 0,
    marginTop: inView && isLoaded ? 0 : 400,
    delay
  });
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 50);
  });
  return (
    <animated.div ref={ref} style={props}>
      {children}
    </animated.div>
  );
};

export default AnimatedSlideUp;
