// PRODUCTION
export const API_URL = "/b/api/v1";
export const API_BASE_URL = "http://bukidfresh.com";

// PRODUCTION - LOCAL
// export const API_URL = "http://localhost/b/api/v1";
// export const API_BASE_URL = "http://localhost";

// // DEV NON DOCKER
// export const API_URL = "http://localhost:8000/b/api/v1";
// export const API_BASE_URL = "";
