import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import OrderList from "../components/account/OrderList";

import { getOrders } from "../actions/AccountActions";
export class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOrders: [],
      shippedOrders: [],
      deliveredOrders: [],
      paidOrders: [],
      cancelledOrders: []
    };
  }
  refreshOrders = () => {
    getOrders(cb => {
      if (cb && cb !== 500) {
        const openOrders = cb.filter(order => order.status === 10);
        const shippedOrders = cb.filter(order => order.status === 20);
        const deliveredOrders = cb.filter(order => order.status === 30);
        const paidOrders = cb.filter(order => order.status === 40);
        const cancelledOrders = cb.filter(order => order.status === 50);
        this.setState({
          openOrders,
          shippedOrders,
          deliveredOrders,
          paidOrders,
          cancelledOrders
        });
      }
    });
  };
  componentDidMount() {
    this.refreshOrders();
  }
  render() {
    return (
      <div className="mx-3 my-3 my-sm-0">
        <Tabs defaultActiveKey="open" variant="pills" className="order-tabs">
          <Tab eventKey="open" title="TO SHIP">
            <OrderList orders={this.state.openOrders} />
          </Tab>
          <Tab eventKey="shipped" title="TO RECEIVE">
            <OrderList
              orders={this.state.shippedOrders}
              toReceive={true}
              refresh={this.refreshOrders}
            />
          </Tab>
          <Tab eventKey="delivered" title="TO PAY">
            <OrderList orders={this.state.deliveredOrders} />
          </Tab>
          <Tab eventKey="paid" title="COMPLETED">
            <OrderList orders={this.state.paidOrders} />
          </Tab>
          <Tab eventKey="cancelled" title="CANCELLED">
            <OrderList orders={this.state.cancelledOrders} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default Orders;
