import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import queryString from "query-string";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";
import UpdateAddress from "../components/shop/UpdateAddress";
import Orders from "../pages/Orders";
import RewardCard from "../components/rewards/RewardCard";

import { updateAccount, updatePassword } from "../actions/AccountActions";
import { getUserProfile } from "../actions/AuthActions";
import { getConfig } from "../actions/CoreActions";

export class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      selectedAddress: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      stickerCount: "",
      stickerLimit: 12,
      disabled: true,
      success: false,
      first_name_error: "",
      last_name_error: "",
      mobile_error: "",
      non_field_errors: "",

      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      old_password_error: "",
      new_password_error: "",
      confirm_password_error: "",
      successPassword: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({
      user,
      firstName: user.first_name,
      lastName: user.last_name,
      mobile: user.mobile,
      email: user.email,
      stickerCount: user.stickers,
    });
    getConfig((config) => {
      this.setState({
        stickerLimit: config.max_sticker_count,
      });
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user !== this.props.user) {
      this.setState({
        user: this.props.user,
        firstName: this.props.user.first_name,
        lastName: this.props.user.last_name,
        mobile: this.props.user.mobile,
        email: this.props.user.email,
        stickerCount: this.props.user.stickers,
      });
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  selectAddress = (selectedAddress) => {
    this.setState({
      selectedAddress,
      address_error: "",
    });
  };

  updateAccount = () => {
    this.setState({
      disabled: false,
      success: false,
    });
  };

  saveAccount = () => {
    const user = {
      id: this.state.user.user,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      mobile: this.state.mobile,
    };
    updateAccount(user, (cb) => {
      if (cb.status === 400) {
        Object.keys(cb.data).forEach((key, index) => {
          const errors = cb.data[key].join(" ");
          if (key === "non_field_errors") {
            this.setState({
              [key]: errors,
            });
          } else {
            this.setState({
              [key + "_error"]: errors,
            });
          }
        });
      } else {
        this.setState({
          disabled: true,
          success: true,
        });
        this.props.getUserProfile();
      }
    });
  };

  cancelSave = () => {
    this.setState({
      firstName: this.state.user.first_name,
      lastName: this.state.user.last_name,
      mobile: this.state.user.mobile,
      disabled: true,
    });
  };

  updatePassword = () => {
    this.setState({
      old_password_error: "",
      new_password_error: "",
      confirm_password_error: "",
    });
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        confirm_password_error: "New Password does not match!",
        newPassword: "",
        confirmPassword: "",
      });
    } else {
      const passwords = {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      };
      updatePassword(passwords, (cb) => {
        if (cb.status === 400) {
          Object.keys(cb.data).forEach((key, index) => {
            const errors = cb.data[key].join(" ");
            if (key === "non_field_errors") {
              this.setState({
                [key]: errors,
              });
            } else {
              this.setState({
                [key + "_error"]: errors,
              });
            }
          });
          this.resetPasswordForm();
        } else {
          this.setState({
            successPassword: true,
          });
          this.resetPasswordForm();
        }
      });
    }
  };

  resetPasswordForm = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  render() {
    const url_param = queryString.parse(this.props.location.search);
    const currentCardStickerCount =
      this.state.stickerCount >= this.state.stickerLimit
        ? this.state.stickerLimit
        : this.state.stickerCount;
    const nextCardStickerCount =
      this.state.stickerCount >= this.state.stickerLimit
        ? this.state.stickerCount - this.state.stickerLimit
        : 0;
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>My Account</h2>
          </PageHeader>
        </div>
        <Container>
          <Tab.Container
            defaultActiveKey={
              url_param && url_param.tab ? url_param.tab : "profile"
            }
          >
            <Row className="mb-5">
              <Col sm={3} style={styles.leftPanel}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="profile" style={styles.sideBarLink}>
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="updatePassword"
                      style={styles.sideBarLink}
                    >
                      Update Password
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="addresses" style={styles.sideBarLink}>
                      Addresses
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="orders" style={styles.sideBarLink}>
                      My Orders
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bayong" style={styles.sideBarLink}>
                      Bayong Credits
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rewards" style={styles.sideBarLink}>
                      Rewards Card
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Email
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="mobile"
                              disabled={true}
                              type="text"
                              value={this.state.email}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            First Name
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="firstName"
                              disabled={this.state.disabled}
                              type="text"
                              value={this.state.firstName}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.first_name_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.first_name_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Last Name
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="lastName"
                              disabled={this.state.disabled}
                              type="text"
                              value={this.state.lastName}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.last_name_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.last_name_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Mobile Number
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="mobile"
                              disabled={this.state.disabled}
                              type="text"
                              value={this.state.mobile}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.mobile_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.mobile_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={8}
                        style={styles.updateAccountButtonContainer}
                      >
                        {this.state.success && (
                          <small className="form-text text-success mr-3">
                            Successfully Updated Account!
                          </small>
                        )}
                        {this.state.disabled && (
                          <Button
                            variant="primary"
                            onClick={this.updateAccount}
                          >
                            Update
                          </Button>
                        )}
                        {!this.state.disabled && (
                          <React.Fragment>
                            <Button
                              className="mr-2"
                              variant="secondary"
                              onClick={this.cancelSave}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.saveAccount}
                            >
                              Save
                            </Button>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="bayong">
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Bayong Credits
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={true}
                              type="text"
                              value={this.props.user.bayong_credit}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Bayong/s on Hand
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              disabled={true}
                              type="text"
                              value={this.props.user.bayong_onhand}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="updatePassword">
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Old Password
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="oldPassword"
                              type="password"
                              value={this.state.oldPassword}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.old_password_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.old_password_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            New Password
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="newPassword"
                              type="password"
                              value={this.state.newPassword}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.new_password_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.new_password_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8}>
                        <Form.Group as={Row}>
                          <Form.Label column sm="4">
                            Confirm Password
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="confirmPassword"
                              type="password"
                              value={this.state.confirmPassword}
                              onChange={this.onChange}
                            />
                          </Col>
                          {this.state.confirm_password_error && (
                            <small className="form-text text-danger ml-3">
                              {this.state.confirm_password_error}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        md={8}
                        style={styles.updateAccountButtonContainer}
                      >
                        {this.state.successPassword && (
                          <small className="form-text text-success mr-3">
                            Successfully Updated Password!
                          </small>
                        )}
                        <Button variant="primary" onClick={this.updatePassword}>
                          Update
                        </Button>
                        {!this.state.disabled && (
                          <React.Fragment>
                            <Button
                              className="mr-2"
                              variant="secondary"
                              onClick={this.cancelSave}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.saveAccount}
                            >
                              Save
                            </Button>
                          </React.Fragment>
                        )}
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="addresses">
                    <UpdateAddress selectAddress={this.selectAddress} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="orders">
                    <Orders />
                  </Tab.Pane>
                  <Tab.Pane eventKey="rewards">
                    <Row>
                      <Col xs={12} md={5}>
                        <RewardCard stickerCount={this.state.stickerCount} />
                      </Col>
                      <Col xs={12} md={7}>
                        <b>
                          There is always a reason to celebrate holidays.
                          Celebrate with BukidFresh. Make an order with at least
                          PHP12,000.00 worth of produce throughout the duration
                          of the promo, and get a free gift from us.
                        </b>
                        <br />
                        <br />
                        <b>Sticker Count: {currentCardStickerCount}</b>
                        <br />
                        <b>
                          Remaining:{" "}
                          {this.state.stickerLimit - currentCardStickerCount}
                        </b>
                        {nextCardStickerCount > 0 && (
                          <Fragment>
                            <br />
                            <b>
                              You have {nextCardStickerCount} extra sticker/s
                              for your next card.
                            </b>
                          </Fragment>
                        )}
                        <br />
                        <br />
                        <b>Promo is valid until February 11, 2021</b>
                        <small class="form-text">
                          Click{" "}
                          <a
                            className="text-dark-green"
                            href="/rewards"
                            target="_blank"
                          >
                            here
                          </a>{" "}
                          to view more details about this promo.
                        </small>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </MainLayout>
    );
  }
}

const styles = {
  updateAccountButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  sideBarLink: {
    color: "#3B2626",
  },
  leftPanel: {
    borderRight: "1px solid rgba(0, 0, 0, 0.125)",
  },
};

const mapPropsToState = (state) => ({
  user: state.auth.user,
});

export default connect(mapPropsToState, { getUserProfile })(Account);
