import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import Image from "react-bootstrap/Image";

import {
  setUnderConstructionBypass,
  checkBypassPassword
} from "../../actions/CoreActions";

const UnderConstruction = props => {
  const styles = {
    mainContainer: {
      width: "100vw",
      height: "100vh",
      position: "relative"
    },
    contentContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center"
    },
    logoImage: {
      maxWidth: 400,
      maxHeight: "300px",
      margin: "20px"
    },
    comingSoonLabel: {
      color: "#806f6e"
    },
    passwordContainer: {
      position: "absolute",
      bottom: 0,
      left: 0,
      margin: "10px"
    },
    passwordInput: {
      margin: "0 10px"
    },
    passwordSubmitButton: {
      padding: "2px 10px"
    },
    passwordLink: {
      margin: "5px 0",
      display: "inline-block"
    }
  };

  const [isShowPassword, setIsShowPassord] = useState(false);
  const [password, setPassword] = useState("");

  const submit = () => {
    checkBypassPassword(password, bypass => {
      props.setUnderConstructionBypass(bypass);
      setPassword("");
    });
  };
  const showPassword = () => {
    setIsShowPassord(true);
  };
  const hidePassword = () => {
    setIsShowPassord(false);
  };
  const updatePassword = e => {
    setPassword(e.target.value);
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles.contentContainer}>
        <Image
          src={`${window.location.origin}/images/logo.png`}
          style={styles.logoImage}
        />
        <h1 style={styles.comingSoonLabel}>COMING SOON</h1>
      </div>
      <div style={styles.passwordContainer}>
        {isShowPassword ? (
          <Fragment>
            <a href="#" onClick={hidePassword} style={styles.passwordLink}>
              close
            </a>
            <input
              type="password"
              style={styles.passwordInput}
              value={password}
              onChange={updatePassword}
            ></input>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submit}
              style={styles.passwordSubmitButton}
            >
              Submit
            </button>
          </Fragment>
        ) : (
          <a href="#" onClick={showPassword} style={styles.passwordLink}>
            admin {props.modalType}
          </a>
        )}
      </div>
    </div>
  );
};

export default connect(
  null,
  { setUnderConstructionBypass }
)(UnderConstruction);
