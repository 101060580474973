import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import {
  getAddressText,
  getOrderStatus,
  getDateTimeText
} from "../../actions/utils";
import { orderReceieved } from "../../actions/ShopActions";

export class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: props.orders
    };
  }
  componentDidUpdate = prevProps => {
    if (prevProps.orders !== this.props.orders) {
      this.setState({
        orders: this.props.orders
      });
    }
  };
  orderReceived = id => {
    orderReceieved(id, cb => {
      if (cb === 200) {
        this.props.refresh();
      }
    });
  };
  render() {
    return (
      <div>
        {this.state.orders.length == 0 && <h5 className="m-5">No orders.</h5>}
        {this.state.orders.map(order => {
          const totalPrice = order.orderlines.reduce(function(prev, orderline) {
            if (isNaN(orderline.fin_total_price)) {
              return prev;
            }
            return prev + +orderline.fin_total_price;
          }, 0);
          return (
            <Card className="my-3 p-2">
              <Card.Body>
                <div>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <span style={styles.label}>DELIVERY OPTION:</span>
                    </Col>
                    <Col xs={8}>
                      <span style={styles.value}>{order.delivery.name}</span>
                    </Col>
                  </Row>
                  {order.delivery.name === "Delivery" && (
                    <Row className="mb-3">
                      <Col xs={4}>
                        <span style={styles.label}>ADDRESS:</span>
                      </Col>
                      <Col xs={8}>
                        <span style={styles.value}>
                          {getAddressText(order.address)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order.delivery.name === "Pickup" && (
                    <Row className="mb-3">
                      <Col xs={4}>
                        <span style={styles.label}>PICKUP POINT:</span>
                      </Col>
                      <Col xs={8}>
                        <span style={styles.value}>
                          {order.pickuppoint.name}
                        </span>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <Col xs={4}>
                      <span style={styles.label}>PAYMENT OPTION:</span>
                    </Col>
                    <Col xs={8}>
                      <span style={styles.value}>
                        {order.payment_option.name}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <span style={styles.label}>STATUS:</span>
                    </Col>
                    <Col xs={8}>
                      <span style={styles.value}>
                        {getOrderStatus(order.status)}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <span style={styles.label}>DATE ORDERED:</span>
                    </Col>
                    <Col xs={8}>
                      <span style={styles.value}>
                        {getDateTimeText(order.created)}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row className="mb-3">
                    <Col xs={4}>
                      <span style={styles.label}>ORDERED PRODUCTS:</span>
                    </Col>
                  </Row>
                  <Table responsive>
                    <thead>
                      <tr style={styles.row}>
                        <th>Name</th>
                        <th>Product Price</th>
                        <th>Number of Items</th>
                        <th>Subtotal Weight</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderlines
                        .filter(e => e.in_set === "")
                        .map(orderline => {
                          return (
                            <tr style={styles.row}>
                              <td>{orderline.product.name}</td>
                              <td>
                                ₱{orderline.product_price}/
                                {orderline.product.base_weight_text}
                              </td>
                              <td>
                                {orderline.quantity ===
                                orderline.adj_quantity ? (
                                  <span>{orderline.quantity}</span>
                                ) : (
                                  <span>
                                    <s>{orderline.quantity}</s>
                                    {"  "}
                                    {orderline.adj_quantity}
                                  </span>
                                )}
                              </td>
                              <td>
                                {orderline.line_weight_text ===
                                orderline.adj_line_weight_text ? (
                                  <span>{orderline.line_weight_text}</span>
                                ) : (
                                  <span>
                                    <s>{orderline.line_weight_text}</s>{" "}
                                    {orderline.adj_line_weight_text}
                                  </span>
                                )}
                              </td>
                              <td>
                                {orderline.line_total_price ===
                                orderline.fin_total_price ? (
                                  <span>₱{orderline.line_total_price}</span>
                                ) : (
                                  <span>
                                    <s>₱{orderline.line_total_price}</s> ₱
                                    {orderline.fin_total_price}
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      <tr style={styles.row}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <h5>Subtotal</h5>
                        </td>
                        <td>
                          <h5>₱{parseFloat(totalPrice)}</h5>
                        </td>
                      </tr>
                      <tr style={styles.row}>
                        <td className="pb-4" />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                      {order.discount_price > 0 && (
                        <tr style={styles.row}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Discount</td>
                          <td>(₱{order.discount_price})</td>
                        </tr>
                      )}
                      <tr style={styles.row}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Bayong Cost</td>
                        <td>₱{order.bayong_fee}</td>
                      </tr>
                      <tr style={styles.row}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Shipping Total</td>
                        <td>₱{order.delivery_fee}</td>
                      </tr>
                      <tr style={styles.row}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <h5>Order Total</h5>
                        </td>
                        <td>
                          <h5>
                            ₱
                            {parseFloat(totalPrice) -
                              parseFloat(order.discount_price) +
                              parseFloat(order.bayong_fee) +
                              parseFloat(order.delivery_fee)}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {this.props.toReceive && (
                  <Button
                    variant="primary"
                    onClick={() => this.orderReceived(order.id)}
                  >
                    Order Received
                  </Button>
                )}
              </Card.Body>
            </Card>
          );
        })}
      </div>
    );
  }
}

const styles = {
  label: {
    color: "#b1b1b1"
  },
  value: {
    color: "#3B2626"
  },
  row: {
    fontSize: "14px"
  }
};

export default OrderList;
