import React, { Component } from 'react'

import Container from "react-bootstrap/Container";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class PageError extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>Access Invalid</h2>
          </PageHeader>
        </div>
        <Container>
        </Container>
      </MainLayout>
    )
  }
}

export default PageError
