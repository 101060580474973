import { ADD_PRODUCT_TO_CART, UPDATE_CART, EMPTY_CART } from "../constants/Types";

const initialState = {
  cart: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };

    case UPDATE_CART:
      return {
        ...state,
        cart: [...action.payload],
      };

    case EMPTY_CART:
      return {
        ...state,
        cart: [],
      };

    default:
      return state;
  }
}
