import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { connect } from "react-redux";

import AppContainer from "./AppContainer";
import PrivateRoute from "./PrivateRoute";
import Landing from "../../pages/Landing";
import About from "../../pages/About";
import HowToOrder from "../../pages/HowToOrder";
import Shop from "../../pages/Shop";
import Contact from "../../pages/Contact";
import Product from "../../pages/Product";
import Cart from "../../pages/Cart";
import Checkout from "../../pages/Checkout";
import Account from "../../pages/Account";
import FAQ from "../../pages/FAQ";
import TermsConditions from "../../pages/TermsConditions";
import Privacy from "../../pages/Privacy";
import Policies from "../../pages/Policies";
import RewardsPromo from "../../pages/RewardsPromo";

export class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    };
  }
  componentDidMount() {
    const { token } = this.props;
    const loggedIn = token !== "";
    this.setState({
      isLoggedIn: loggedIn
    });
  }

  componentWillReceiveProps(props) {
    const { token } = props;
    const loggedIn = token !== "";
    this.setState({
      isLoggedIn: loggedIn
    });
  }

  render() {
    return (
      <Router>
        <AppContainer>
          <Route exact path="/" component={Landing} />
          <Route path="/about" component={About} />
          <Route path="/how-to-order" component={HowToOrder} />
          <Route exact path="/shop" component={Shop} />
          <Route path="/shop/:id" component={Product} />
          <Route path="/contact" component={Contact} />
          <Route path="/cart" component={Cart} />
          <Route path="/faq" component={FAQ} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/policies" component={Policies} />
          <Route path="/rewards" component={RewardsPromo} />
          <PrivateRoute
            path="/checkout"
            component={Checkout}
            isLoggedIn={this.state.isLoggedIn}
          />
          <PrivateRoute
            path="/account"
            component={Account}
            isLoggedIn={this.state.isLoggedIn}
          />
        </AppContainer>
      </Router>
    );
  }
}

const mapPropsToState = state => ({
  token: state.auth.token
});

export default connect(
  mapPropsToState,
  {}
)(Root);
