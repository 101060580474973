import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";

import { setActiveMenu } from "../../actions/CoreActions";

import Image from "react-bootstrap/Image";

export class Footer extends Component {
  goToContact = e => {
    e.preventDefault();
    this.props.setActiveMenu("contact");
    this.props.history.push(`/contact`);
    window.scrollTo(0, 0);
  };
  goToFAQs = e => {
    e.preventDefault();
    this.props.history.push(`/faq`);
    window.scrollTo(0, 0);
  };
  goToTermsConditions = e => {
    e.preventDefault();
    this.props.history.push(`/terms-conditions`);
    window.scrollTo(0, 0);
  };
  goToPrivacy = e => {
    e.preventDefault();
    this.props.history.push(`/privacy`);
    window.scrollTo(0, 0);
  };
  goToPolicies = e => {
    e.preventDefault();
    this.props.history.push(`/policies`);
    window.scrollTo(0, 0);
  };
  goToRewards = e => {
    e.preventDefault();
    this.props.history.push(`/rewards`);
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <div style={styles.footerContainer}>
        <footer className="container pb-2 pt-3 py-md-5">
          <div className="row">
            <div className="col-12 col-md-5">
              <Image
                src={`${window.location.origin}/images/icon.png`}
                style={styles.iconNavBarImage}
              />
              <div className="my-3 mb-5">
                <a
                  href="https://www.facebook.com/bukidfresh"
                  className="text-primary-dark mr-2"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} size="lg" />
                </a>
                <a
                  href="https://www.instagram.com/bukidfresh/"
                  className="text-primary-dark mr-2"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
                </a>
              </div>
              <small className="my-3 text-dark-green d-none d-md-block heading">
                &copy; Bukid Fresh 2019. All rights reserved.
              </small>
            </div>
            <div className="col-7 col-md-4">
              <h5 className="text-primary-dark">Contact</h5>
              <ul className="list-unstyled text-small">
                <li className="text-dark-green">
                  <FontAwesomeIcon icon="mobile-alt" size="sm" />
                  {" : 09278185794"}
                </li>
                <li className="text-dark-green">
                  <FontAwesomeIcon icon="phone" size="xs" />
                  {" : 87764838"}
                </li>
                <li className="text-dark-green">
                  <FontAwesomeIcon icon="envelope" size="xs" />
                  {" : "}
                  <a
                    className="text-dark-green"
                    href="mailto:hello.bukidfresh@gmail.com"
                  >
                    hello.bukidfresh@gmail.com
                  </a>
                </li>
                <li className="text-dark-green">
                  <FontAwesomeIcon icon="sticky-note" size="xs" />
                  {" : "}
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToContact(e)}
                  >
                    Leave us a message!
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-5 col-md-3">
              <h5 className="text-primary-dark">Our Policies</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToFAQs(e)}
                  >
                    FAQ
                  </a>
                </li>
                <li>
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToPolicies(e)}
                  >
                    Order Policies
                  </a>
                </li>
                <li>
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToTermsConditions(e)}
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToPrivacy(e)}
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    className="text-dark-green"
                    href="#"
                    onClick={e => this.goToRewards(e)}
                  >
                    Rewards Card Promo
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <small className="my-3 text-dark-green d-md-none heading">
                &copy; Bukid Fresh 2019. All rights reserved.
              </small>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const styles = {
  footerContainer: {
    backgroundColor: "#edffca",
  },
  iconNavBarImage: {
    maxWidth: 30,
    width: "100%"
  }
};

export default connect(
  null,
  { setActiveMenu }
)(Footer);
