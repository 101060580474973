import React, { Component } from "react";

import Li from "../html/Li";
import A from "../html/A";

export class CategoryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productCategories: [],
      filterShop: props.filterShop,
      category: props.category,
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.productCategories !== this.props.productCategories) {
      this.setState({
        productCategories: this.props.productCategories,
      });
    }
    if (prevProps.category !== this.props.category) {
      this.setState({
        category: this.props.category,
      });
    }
  };
  click = (code) => {
    this.state.filterShop(code);
  };
  render() {
    console.log(this.state.category);
    console.log(this.state.productCategories);
    return (
      <div
        className="shop-category-filter-container"
        className="d-none d-md-block"
      >
        <h5>Categories</h5>
        <ul style={style.categoryList}>
          <Li key="all">
            <A active={!this.state.category} onClick={() => this.click("all")}>All</A>
          </Li>
          {this.state.productCategories.map((category) => (
            <Li key={category.code}>
              <A
                active={this.state.category === category.code}
                onClick={() => this.click(category.code)}
              >
                {category.name}
              </A>
            </Li>
          ))}
        </ul>
      </div>
    );
  }
}

const style = {
  categoryList: {
    padding: 0,
  },
};

export default CategoryFilter;
