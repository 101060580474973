import React, { Component } from "react";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import commonStyles from "../../constants/styles/CommonStyles";

export default function BayongQuantitySelector({
  subtractQty,
  addQty,
  quantity,
  className,
  disabled
}) {
  let singleText = "pc";
  let pluralText = "pcs";
  return (
    <InputGroup
      className={className}
      style={{
        ...commonStyles.defaultMarginBotttom,
        ...commonStyles.defaultMarginTop
      }}
    >
      <InputGroup.Prepend>
        <Button
          onClick={disabled ? null : subtractQty}
          variant={disabled ? "disabled" : "outline-primary"}
        >
          -
        </Button>
      </InputGroup.Prepend>
      <FormControl
        value={`${quantity} ${quantity > 1 ? pluralText : singleText}`}
        aria-describedby="basic-addon2"
        onChange={() => {}}
        style={{textAlign: "center"}}
      />
      <InputGroup.Append>
        <Button
          onClick={disabled ? null : addQty}
          variant={disabled ? "disabled" : "outline-primary"}
        >
          +
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
