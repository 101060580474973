import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

export class About extends Component {
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>About Us</h2>
          </PageHeader>
        </div>
        <Container className="mb-5">
          <Row className="mt-3">
            <Col xs={12}>
              <h2 className="text-center wide-text">Our Story</h2>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col xs={12} md={{ span: 10, offset: 1 }}>
              <p className="text-center">
                BukidFresh was born out of its founders’ vision of an improved 
                agriculture sector that’s inclusive for the majority of smallholder 
                farmers in our country. What started out as a school requirement 
                for the team has now become a full-fledged business, creating value 
                not just for the farmers but for urban dwellers as well who want 
                access to fresh, quality, and nutritious produce. Through BukidFresh, 
                we’re giving respect and dignity back to the farmers as they now set 
                the price for their produce and at the same time, allowing them to 
                market their produce directly to the consumers.
              </p>
            </Col>
          </Row>
          {/* <Row className="mt-5 mb-5">
            <Col xs={12}>
              <h2 className="text-center wide-text">Meet the Farmers</h2>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">
                Palangue Agrarian Reform Cooperative
              </h6>
              <p className="text-center text-muted">Naic, Cavite</p>
            </Col>
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">SAMAKAMI</h6>
              <p className="text-center text-muted">Magallanes, Cavite</p>
            </Col>
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">
                Tanim Agriculture Cooperative
              </h6>
              <p className="text-center text-muted">San Pablo, Laguna</p>
            </Col>
          </Row>
          <Row className="mt-md-3">
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">SIPAG-LAGUNA</h6>
              <p className="text-center text-muted">Majayjay, Laguna</p>
            </Col>
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">SIPAG-CAVITE</h6>
              <p className="text-center text-muted">Indang, Cavite</p>
            </Col>
            <Col xs={12} md={4}>
              <h6 className="text-center text-primary-dark">Palaya Farm</h6>
              <p className="text-center text-muted">Tanay, Rizal</p>
            </Col>
          </Row> */}
        </Container>
      </MainLayout>
    );
  }
}

export default About;
