import axios from "axios";
import { API_URL } from "../constants/ApiConfig";
import { getToken } from "./TokenActions";

export const createUpdateAddress = (type, address, cb) => {
  if (type === "create") {
    addNewAddress(address, cb);
  } else if (type === "update") {
    updateAddress(address, cb);
  }
};

export const addNewAddress = (address, cb) => {
  const userToken = getToken();
  axios
    .post(`${API_URL}/address/`, address, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const updateAddress = (address, cb) => {
  const userToken = getToken();
  axios
    .put(`${API_URL}/address/${address.id}/`, address, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const deleteNewAddress = (address, cb) => {
  const updatedAddress = { ...address, status: 20 };
  const userToken = getToken();
  axios
    .put(`${API_URL}/address/${address.id}/`, updatedAddress, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const updateAccount = (user, cb) => {
  const userToken = getToken();
  axios
    .put(`${API_URL}/users/${user.id}/`, user, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export async function getOrders(cb) {
  const userToken = getToken();
  const headers = {
    headers: {
      ...userToken
    }
  };
  await axios
    .get(`${API_URL}/orders/`, headers)
    .then(function(response) {
      return cb(response.data);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return cb(500);
    });
}

export async function getOrdersCount(cb) {
  const userToken = getToken();
  const headers = {
    headers: {
      ...userToken
    }
  };
  await axios
    .get(`${API_URL}/orders-count/`, headers)
    .then(function(response) {
      return cb(response.data);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return cb(500);
    });
}

export const updatePassword = (passwords, cb) => {
  const userToken = getToken();
  axios
    .put(`${API_URL}/update-password/`, passwords, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};
