import axios from "axios";
import { API_URL } from "../constants/ApiConfig";
import {
  ADD_PRODUCT_TO_CART,
  UPDATE_CART,
  EMPTY_CART
} from "../constants/Types";
import { store } from "../store";
import { getToken } from "./TokenActions";
import { createLog } from "./CoreActions";

export const addProductToCart = product => dispatch => {
  const cartId = store.getState().auth.user.cart_id;
  const userToken = getToken();
  const addToCartBody = {
    cart: cartId,
    product: product.id,
    quantity: product.quantity
  };
  const headers = {
    headers: {
      ...userToken
    }
  };
  axios
    .post(`${API_URL}/cart/item/`, addToCartBody, headers)
    .then(function(response) {
      dispatch(addCart(product));
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export function addCart(product) {
  return {
    type: ADD_PRODUCT_TO_CART,
    payload: product
  };
}

export const updateProductToCart = (udpatedCart, product) => dispatch => {
  const cartId = store.getState().auth.user.cart_id;
  const userToken = getToken();
  const addToCartBody = {
    cart: cartId,
    product: product.id,
    quantity: product.quantity
  };
  const headers = {
    headers: {
      ...userToken
    }
  };
  axios
    .post(`${API_URL}/cart/item/`, addToCartBody, headers)
    .then(function(response) {
      dispatch(updateCart(udpatedCart));
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
};

export function updateCart(udpatedCart) {
  return {
    type: UPDATE_CART,
    payload: udpatedCart
  };
}

export function removeProductToCart(product) {
  const cartId = store.getState().auth.user.cart_id;
  const userToken = getToken();
  const addToCartBody = {
    cart: cartId,
    product: product.id,
    quantity: 0
  };
  const headers = {
    headers: {
      ...userToken
    }
  };
  axios
    .post(`${API_URL}/cart/item/`, addToCartBody, headers)
    .then(function(response) {})
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    });
}

export function emptyCart() {
  return {
    type: EMPTY_CART
  };
}

export const refreshCartFromToken = cb => dispatch => {
  const userToken = getToken();
  axios
    .get(`${API_URL}/cart/`, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      dispatch(updateCart(response.data[0].cartitems));
      return cb(response.data[0].cartitems);
    })
    .catch(function(error) {
      if (error && error.response) {
        console.log(error, "ERROR HERE");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      createLog({
        trigger: "Refresh Cart Token",
        status: error.response.status || "",
        data: JSON.stringify(error.response.data),
        headers: JSON.stringify(error.response.headers),
        log_type: 30
      });
      return cb("ERROR");
    });
};

export const removeAllCartItems = cb => {
  const userToken = getToken();
  const cartId = store.getState().auth.user.cart_id;
  axios
    .put(`${API_URL}/cart/${cartId}/deleteall/`, null, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const orderProducts = (otherOrderBody, cb) => dispatch => {
  const cart = store.getState().shopReducer.cart;
  const userId = store.getState().auth.user.id;
  const userToken = getToken();
  let orderlines = [];
  cart.map(product => {
    if (product.is_available === "true") {
      orderlines.push({
        product: product.id,
        quantity: product.quantity,
        adj_quantity: product.quantity,
        is_set: false,
        in_set: ""
      });
    }
  });
  const orderBody = {
    buyer: userId,
    orderlines,
    ...otherOrderBody
  };
  const headers = {
    headers: {
      ...userToken
    }
  };
  axios
    .post(`${API_URL}/orders/`, orderBody, headers)
    .then(function(response) {
      dispatch(emptyCart());
      removeAllCartItems(cb => null);
      return cb(response);
    })
    .catch(function(error) {
      if (error && error.response) {
        console.log(error, "ERROR HERE");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      createLog({
        trigger: "Order Products",
        status: error.response.status || "",
        data: JSON.stringify(error.response.data),
        headers: JSON.stringify(error.response.headers),
        log_type: 30
      });
      return cb("ERROR");
    });
};

export async function getProductCategories(cb) {
  await axios
    .get(`${API_URL}/productcategories/`)
    .then(function(response) {
      return cb(response.data);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return cb(500);
    });
}

export const orderReceieved = (id, cb) => {
  const userToken = getToken();
  axios
    .put(`${API_URL}/orders/${id}/received/`, null, {
      headers: {
        ...userToken
      }
    })
    .then(function(response) {
      return cb(200);
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      return cb(error.response || 500);
    });
};

export const checkVoucher = (code, cb) => {
  const userToken = getToken();
  const headers = {
    headers: {
      ...userToken
    }
  };
  axios
    .post(
      `${API_URL}/voucher/`,
      {
        code: code
      },
      headers
    )
    .then(function(response) {
      cb(response.data);
    })
    .catch(function(error) {
      console.log(error, "ERROR HERE");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      cb(false);
    });
};
