import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

import MainLayout from "../components/layout/MainLayout";
import PageHeader from "../components/core/PageHeader";

import { getConfig } from "../actions/CoreActions";

export class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minPrice: "",
    };
  }
  componentDidMount() {
    getConfig(cb => {
      if (cb.min_price !== "") {
        this.setState({
          minPrice: cb.min_price
        });
      }
    });
  }
  render() {
    return (
      <MainLayout history={this.props.history}>
        <div>
          <PageHeader>
            <h2>FAQs</h2>
          </PageHeader>
        </div>
        <Container>
          <Accordion defaultActiveKey="0" className="mb-5">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                style={styles.cardHeader}
              >
                Do you have a physical store?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    No, we don’t have a physical store yet but please
                    stay tuned to our social media page as we look to be
                    present in some pop-up events and weekend markets in
                    Metro Manila. 
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                style={styles.cardHeader}
              >
                Do you have a minimum order to deliver?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                  To properly compensate for our partner farmers’ hard work in
                  providing fresh produce to our growing community, we will be
                  implementing a minimum order of PHP {this.state.minPrice} per
                  customer delivery (estimated value), exclusive of the bayong
                  and shipping fees.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                style={styles.cardHeader}
              >
                What areas do you deliver?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>We currently deliver to the areas mentioned below.</p>
                  <p>
                    Standard Service Area: Muntinlupa, Paranaque, Las Pinas,
                    Pasay, Manila, Marikina, Taguig, Makati, Pateros, San Juan, Pasig,
                    Mandaluyong, Southern Part of Quezon City (District 1,4 & 3)
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                style={styles.cardHeader}
              >
                Can I pick-up my order instead to save on shipping fee?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Yes, we do accommodate pick-up via our partner hubs. Exact
                    locations are provided below.
                  </p>
                  <strong>Locations:</strong>
                  <br />
                  <br />
                  <p>
                    <span>
                      <strong>Derive Cafe</strong>
                      {" - "}
                      <a
                        href="https://g.page/derivecafeph?share"
                        className="text-primary-dark"
                      >
                        https://g.page/derivecafeph?share
                      </a>
                    </span>
                    <br />
                    <span>Pick-up time: 10AM - 2PM</span>
                  </p>
                  <p>
                    <span>
                      <strong>The Common Space</strong>
                      {" - "}
                      <a
                        href="https://goo.gl/maps/w25GtpW8S55B3PYh7"
                        className="text-primary-dark"
                      >
                        https://goo.gl/maps/w25GtpW8S55B3PYh7
                      </a>
                    </span>
                    <br />
                    <span>Pick-up time: 10AM - 2PM</span>
                  </p>
                  <p>
                    <span>
                      <strong>SkyLabs</strong>
                      {" - "}
                      <a
                        href="https://goo.gl/maps/NHXV316NcDq3tPDL9"
                        className="text-primary-dark"
                      >
                        https://goo.gl/maps/NHXV316NcDq3tPDL9
                      </a>
                    </span>
                    <br />
                    <span>Pick-up time: 11AM - 3PM</span>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                style={styles.cardHeader}
              >
                Do you supply to business or wholesale customers?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>
                    Yes, we are open to serving business or wholesale customers
                    especially those who have the same vision as ours in
                    uplifting the lives our fellow Filipino farmers. Kindly
                    reach out to us at{" "}
                    <a
                      href="mailto:gorby.emagsasaka@gmail.com"
                      className="text-primary-dark"
                    >
                      gorby.emagsasaka@gmail.com
                    </a>{" "}
                    for your fresh produce requirements and we’ll be more than
                    willing to discuss with you for your business needs.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="5"
                style={styles.cardHeader}
              >
                Do I receive an invoice for my order? When do I pay?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>
                    Yes, we will be sending you an invoice via e-mail once your
                    orders have been weighed and packed by our team. Kindly
                    expect it to be sent Saturday morning prior to dispatch from
                    our hub. We will also be sending you a physical copy upon
                    delivery. Upon checking of your orders, our courier will
                    request for your sign-off in the physical invoice and after
                    which, you may proceed to pay via your preferred payment
                    method.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="6"
                style={styles.cardHeader}
              >
                Why is there a discrepancy in the amount stated in my order
                confirmation and in the final sales invoice?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>
                    The amount stated in the order confirmation is just an
                    approximation given the average weight of certain items.
                    However, the actual weight of some items could only be
                    determined on the morning of the delivery, once we start
                    packing your orders. Hence, the total amount payable will be
                    based on the actual weight of packed orders which will be
                    reflected in the final sales invoice we'll be providing you
                    with.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="7"
                style={styles.cardHeader}
              >
                I’d like to purchase and give it to someone as a gift. How will
                I do this?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p>
                    You may just add a new delivery address in your BukidFresh
                    account and choose it after you are done picking your
                    orders. Please make sure that there will be someone to
                    receive the order on the day of delivery.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </MainLayout>
    );
  }
}
const styles = {
  cardHeader: {
    cursor: "pointer"
  }
};

export default FAQ;
