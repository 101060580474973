import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer.js";
import ProductReducer from "./ProductReducer.js";
import ShopReducer from "./ShopReducer.js";
import CoreReducer from "./CoreReducer";

export default combineReducers({
  auth: AuthReducer,
  productReducer: ProductReducer,
  shopReducer: ShopReducer,
  coreReducer: CoreReducer
});
