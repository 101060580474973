import {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_TYPE,
  SET_MODAL_TITLE,
  SET_MODAL_BODY,
  SET_MODAL_CONFIRM_BUTTON,
  CLEAR_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  SET_DELIVERIES,
  SET_PICKUPPOINTS,
  SHOW_ADDRESS_MODAL,
  HIDE_ADDRESS_MODAL,
  SET_ORDER_PERIOD,
  SET_UNDER_CONSTRUCTION_BYPASS,
  SET_ACTIVE_MENU,
  SET_CONFIRM_ACTION,
  SET_PIXEL_SHOPBACK
} from "../constants/Types";

const initialState = {
  showModal: false,
  modalType: "basic",
  modalTitle: "",
  modalBody: "",
  modalConfirmButton: "",
  showLoginModal: false,
  showAddressModal: false,
  deliveries: [],
  pickuppoints: [],
  orderPeriod: {},
  underConstructionBypass: false,
  activeMenu: "",
  confirmAction: () => null,
  pixelShopBack: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true
      };

    case HIDE_MODAL:
      return {
        ...state,
        showModal: false
      };

    case SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      };

    case SET_MODAL_TITLE:
      return {
        ...state,
        modalTitle: action.payload
      };

    case SET_MODAL_BODY:
      return {
        ...state,
        modalBody: action.payload
      };

    case SET_MODAL_CONFIRM_BUTTON:
      return {
        ...state,
        modalConfirmButton: action.payload
      };

    case SET_CONFIRM_ACTION:
      return {
        ...state,
        confirmAction: action.payload
      };

    case SET_PIXEL_SHOPBACK:
      return {
        ...state,
        pixelShopBack: action.payload
      };

    case CLEAR_MODAL:
      return {
        ...state,
        modalType: "basic",
        modalTitle: "",
        modalBody: "",
        modalConfirmButton: "",
        confirmAction: () => null,
        pixelShopBack: "",
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: true
      };

    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: false
      };

    case SHOW_ADDRESS_MODAL:
      return {
        ...state,
        showAddressModal: true
      };

    case HIDE_ADDRESS_MODAL:
      return {
        ...state,
        showAddressModal: false
      };

    case SET_DELIVERIES:
      return {
        ...state,
        deliveries: [...action.payload]
      };

    case SET_PICKUPPOINTS:
      return {
        ...state,
        pickuppoints: [...action.payload]
      };

    case SET_ORDER_PERIOD:
      return {
        ...state,
        orderPeriod: action.payload
      };

    case SET_UNDER_CONSTRUCTION_BYPASS:
      return {
        ...state,
        underConstructionBypass: action.payload
      };

    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload
      };

    default:
      return state;
  }
}
