import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SelectButton from "../generic/SelectButton";

export class SelectPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPayment: props.selectPayment,
      selectedPayment: "",
      selectedDelivery: props.selectedDelivery,
      paymentOptions: [],
      error: props.error
    };
  }
  componentDidUpdate = prevProps => {
    if (prevProps.selectedDelivery !== this.props.selectedDelivery) {
      if (this.props.selectedDelivery === "") {
        this.setState({
          selectedPayment: "",
          selectedDelivery: "",
          paymentOptions: []
        });
      } else {
        let paymentOptions = this.props.deliveries.filter(
          delivery => delivery.id === this.props.selectedDelivery
        )[0].payment_options;
        this.setState({
          selectedPayment: "",
          selectedDelivery: this.props.selectedDelivery,
          paymentOptions
        });
      }
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  };
  selectPayment = selectedPayment => {
    this.state.selectPayment(selectedPayment);
    this.setState({
      selectedPayment,
      error: ""
    });
  };
  render() {
    return (
      <Fragment>
        <Row className="mb-3">
          {this.state.paymentOptions.length > 0 && (
            <Col xs={12}>
              <h5 className="mb-3">3. Select your Payment Option:</h5>
              {this.state.error && (
                <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
                  {this.state.error}
                </medium>
              )}
              <Row>
                {this.state.paymentOptions.map(paymentOption => (
                  <Col xs={12} lg={4} key={paymentOption.id}>
                    <SelectButton
                      label={paymentOption.name}
                      id={paymentOption.id}
                      selected={this.state.selectedPayment}
                      click={this.selectPayment}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
        {this.state.selectedPayment !== "" && (
          <Row className="mb-5">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <h6>Instructions:</h6>
                  {
                    this.state.paymentOptions.filter(
                      paymentOption =>
                        paymentOption.id === this.state.selectedPayment
                    )[0].instruction
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapPropsToState = state => ({
  deliveries: state.coreReducer.deliveries
});

export default connect(mapPropsToState, {})(SelectPaymentOption);
