import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";

import { connect } from "react-redux";

import "./styles/ProductCard.css";
import AddToCart from "./AddToCart";

import { removeBaseUrl } from "../../actions/utils";

export class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      hover: false,
      qty: 1
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      product: props.product
    });
  }

  goToProduct = product => {
    this.props.history.push(`/shop/${product.id}`, { product });
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { product } = this.state;
    return (
      <Col
        key={product.id}
        xs={12}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        style={{
          ...style.productCardContainer,
          backgroundColor: this.state.hover
            ? style.productCardContainerHoverColor
            : ""
        }}
      >
        <div>
          <Container className="p-0 m-0">
            <Row>
              <Col xs={12} onClick={() => this.goToProduct(product)}>
                <div
                  className="productCardImageContainer m-0 mb-md-2"
                  style={style.productCardImageContainer}
                >
                  <Image
                    className="productCardImage"
                    src={removeBaseUrl(product.image)}
                    style={style.productCardImage}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div onClick={() => this.goToProduct(product)}>
                  <h6 className="m-0" style={style.productName}>
                    {product.name}
                  </h6>
                  {product.sources.map(source => (
                    <Badge variant="primary" style={style.badgeSource}>
                      {source.name}
                    </Badge>
                  ))}
                  {product.sources.length == 0 && <span>&nbsp;</span>}

                  <h6 className="mt-md-3 mb-md-3">
                    ₱{product.price} / {product.base_weight_text}
                  </h6>
                </div>
                {this.props.orderPeriod[0] && <AddToCart product={product} />}
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    );
  }
}

const style = {
  productCardContainer: {
    cursor: "pointer",
    paddingTop: 20,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "0.25rem"
  },
  productCardContainerHoverColor: "#f7f7f7",
  productCardImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden"
  },
  productCardImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  badgeSource: {
    fontWeight: 100,
    fontSize: "12px",
    marginRight: "5px"
  },
  productName: {
    whiteSpace: "nowrap",
    maxWidth: "45vw",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};

const mapPropsToState = state => ({
  cart: state.shopReducer.cart,
  orderPeriod: state.coreReducer.orderPeriod
});

export default connect(mapPropsToState, {})(ProductCard);
