import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectButton from "../generic/SelectButton";
import SelectRadio from "../generic/SelectRadio";

import AddressModal from "../../components/modals/AddressModal";

import { showAddressModal } from "../../actions/CoreActions";
import { getAddressText } from "../../actions/utils";

export class SelectAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAddress: props.selectAddress,
      selectedAddress: "",
      addresses: props.user.addresses,
      error: props.error,
      restrictedCities: ["Bacoor West District 1"]
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.addresses !== this.props.user.addresses) {
      this.setState({
        addresses: this.props.user.addresses
      });
    }
    if (prevProps.selectedAddress !== this.props.selectedAddress) {
      this.setState({
        selectedAddress: "",
        selectedAddress: this.props.selectedAddress
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  };

  getAddressNameById = id => {
    const addresses = this.state.addresses.filter(address => address.id === id);
    if (addresses && addresses.length > 0) {
      return addresses[0].citymun;
    }
    return "";
  };

  selectAddress = selectedAddress => {
    const addressName = this.getAddressNameById(selectedAddress);
    if (this.state.restrictedCities.includes(addressName)) {
      this.state.selectAddress("");
      this.setState({
        error: "We are temporarily not delivering on the selected address.",
        selectedAddress: ""
      });
    } else {
      this.state.selectAddress(selectedAddress);
      this.setState({
        selectedAddress,
        error: ""
      });
    }
  };

  addNewAddress = () => {
    this.props.showAddressModal();
  };
  render() {
    return (
      <React.Fragment>
        <AddressModal address={{}} />
        <Row className="mb-3 ml-2" ref={this.props.refProp}>
          <Col xs={12} lg={6}>
            <h6 className="mb-3">Select Address:</h6>
            {this.state.error && (
              <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
                {this.state.error}
              </medium>
            )}
            <Row>
              {this.state.addresses.map(address => (
                <Col
                  xs={12}
                  key={`${address.id}${address.modified}`}
                  className="ml-3"
                >
                  <SelectRadio
                    label={getAddressText(address)}
                    id={address.id}
                    selected={this.state.selectedAddress}
                    click={this.selectAddress}
                    disabled={this.state.restrictedCities.includes(
                      this.getAddressNameById(address.id)
                    )}
                  />
                </Col>
              ))}
              <Col className="mt-3" xs={12} key="newaddress">
                <SelectButton
                  label="+ ADD NEW ADDRESS"
                  id="newaddress"
                  click={this.addNewAddress}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapPropsToState = state => ({
  user: state.auth.user
});

export default connect(mapPropsToState, { showAddressModal })(SelectAddress);
