import React, { Component } from "react";
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";

import { setActiveMenu } from "../../actions/CoreActions";

export class Hero extends Component {
  goToShop = (e) => {
    e.preventDefault();
    this.props.setActiveMenu("shop");
    this.props.history.push(`/shop`);
  };
  render() {
    return (
      <div className="position-relative overflow-hidden text-center bg-light">
        <Image
          src={`${window.location.origin}/images/rewards-landing-page.png`}
          className="w-100"
          fluid
        />
        <div style={styles.heroContentContainer}>
          <div className="d-md-none">
            <a
              className="btn btn-sm btn-primary mt-1"
              href="#"
              onClick={(e) => this.goToShop(e)}
            >
              Shop Now!
            </a>
            <a
              className="btn btn-sm btn-primary mt-1 ml-2"
              href="/rewards"
              target="_blank"
            >
              Promo Details
            </a>
          </div>
          <div className="d-none d-md-block d-lg-none">
            <a
              className="btn btn-lg btn-primary mt-3"
              href="#"
              onClick={(e) => this.goToShop(e)}
            >
              Shop Now!
            </a>
            <a
              className="btn btn-lg btn-primary mt-3 ml-3"
              href="/rewards"
              target="_blank"
            >
              Promo Details
            </a>
          </div>
          <div className="d-none d-lg-block">
            <a
              className="btn btn-lg btn-primary mt-3"
              href="#"
              onClick={(e) => this.goToShop(e)}
            >
              Shop Now!
            </a>
            <a
              className="btn btn-lg btn-primary mt-3 ml-3"
              href="/rewards"
              target="_blank"
            >
              Promo Details
            </a>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  heroContentContainer: {
    position: "absolute",
    top: "86%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  heroLabelMobile: {
    color: "white",
    fontWeight: 100,
    fontSize: 18,
  },
  heroLabelTablet: {
    color: "white",
    fontWeight: 100,
    fontSize: 35,
  },
  heroLabelDesktop: {
    color: "white",
    fontWeight: 100,
    fontSize: 70,
  },
};

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState, {
  setActiveMenu,
})(Hero);
