import React, { useState, useEffect } from "react";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import commonStyles from "../../constants/styles/CommonStyles";

export default function QuantitySelector({
  subtractQty,
  addQty,
  updateQty,
  quantity,
  className,
  quantityType,
  disabled,
  limit,
}) {
  let singleText = "pc";
  let pluralText = "pcs";
  if (quantityType === "pack") {
    singleText = "pack";
    pluralText = "packs";
  }
  const [updateQ, setUpdateQ] = useState(quantity);

  useEffect(() => {
    setUpdateQ(quantity);
  }, [quantity]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Set Quantity</Popover.Title>
      <Popover.Content>
        <InputGroup>
          <FormControl
            value={updateQ}
            onChange={(event) => {
              setUpdateQ(event.target.value);
            }}
            type="number"
            maxLength="3"
          />
          <InputGroup.Append>
            <Button
              onClick={
                limit
                  ? limit == -1
                    ? () => updateQty(updateQ)
                    : limit <= updateQ
                    ? null
                    : disabled
                    ? null
                    : () => updateQty(updateQ)
                  : () => updateQty(updateQ)
              }
              variant={
                limit
                  ? limit == -1
                    ? "primary"
                    : limit <= updateQ
                    ? "disabled"
                    : disabled
                    ? "disabled"
                    : "primary"
                  : "primary"
              }
            >
              Update
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Popover.Content>
    </Popover>
  );

  return (
    <InputGroup className={className} style={commonStyles.defaultMarginBotttom}>
      {limit > -1 && limit - 1 == quantity && (
        <span class="text-danger" style={style.oneMoreItemLeftText}>
          {`1 ${singleText} left`}
        </span>
      )}
      <InputGroup.Prepend>
        <Button
          onClick={disabled ? null : subtractQty}
          variant={disabled ? "disabled" : "outline-primary"}
        >
          -
        </Button>
      </InputGroup.Prepend>
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom"
        overlay={popover}
      >
        <FormControl
          value={`${quantity} ${quantity > 1 ? pluralText : singleText}`}
          aria-describedby="basic-addon2"
          onChange={() => {}}
        />
      </OverlayTrigger>
      <InputGroup.Append>
        <Button
          onClick={
            limit
              ? limit == -1
                ? addQty
                : limit <= quantity
                ? null
                : disabled
                ? null
                : addQty
              : addQty
          }
          variant={
            limit
              ? limit == -1
                ? "outline-primary"
                : limit <= quantity
                ? "disabled"
                : disabled
                ? "disabled"
                : "outline-primary"
              : "outline-primary"
          }
        >
          +
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

const style = {
  oneMoreItemLeftText: {
    display: "block",
    fontSize: "10px",
    marginTop: "5px",
    textAlign: "center",
    position: "absolute",
    top: -20,
  },
};
