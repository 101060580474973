import React, { Component } from "react";

import { connect } from "react-redux";

import Button from "react-bootstrap/Button";

import QuantitySelector from "./QuantitySelector";

import {
  addProductToCart,
  updateProductToCart,
} from "../../actions/ShopActions";
import {
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal,
  showLoginModal,
} from "../../actions/CoreActions";
import { isEmpty, getSimplifiedWeight } from "../../actions/utils";

import commonStyles from "../../constants/styles/CommonStyles";

export class AddToCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
    };
  }

  subtractQty = (q = 0) => {
    if (q > 0) {
      if (this.state.quantity > q) {
        this.setState({ quantity: this.state.quantity - q });
      } else {
        this.setState({ quantity: 1 });
      }
    } else {
      if (this.state.quantity > 1) {
        this.setState({ quantity: this.state.quantity - 1 });
      }
    }
  };

  addQty = (q = 0) => {
    if (q > 0) {
      this.setState({ quantity: this.state.quantity + q });
    } else {
      this.setState({ quantity: this.state.quantity + 1 });
    }
  };

  updateQty = (q = 0) => {
    if (q > 0) {
      this.setState({ quantity: parseInt(q) });
    }
  };

  addToCart = () => {
    const { cart, product } = this.props;
    if (isEmpty(this.props.user)) {
      this.props.showLoginModal();
    } else if (product.price === "---" || isNaN(product.price)) {
      this.props.setModalType("basic");
      this.props.setModalTitle("Error");
      this.props.setModalBody("Price for this product has not been set yet.");
      this.props.setModalConfirmButton("OK");
      this.props.showModal();
    } else {
      const { quantity } = this.state;
      const isInCart = cart.filter((prop) => prop.id === product.id);
      if (isInCart && isInCart.length) {
        let currentQty = cart.find((prop) => prop.id === product.id).quantity;
        let productToUpdate = cart.find((prop) => prop.id === product.id);
        productToUpdate.quantity = currentQty + quantity;
        this.props.updateProductToCart(cart, productToUpdate);
      } else {
        product.quantity = quantity;
        this.props.addProductToCart(product);
      }
      this.setState({ quantity: 1 });
      if (!this.props.orderPeriod[0]) {
        this.props.setModalType("basic");
        this.props.setModalTitle("Notice");
        this.props.setModalBody(
          "The shop is currently not accepting orders. You can add to cart but won't let you checkout your items."
        );
        this.props.setModalConfirmButton("OK");
        this.props.showModal();
      }
    }
  };

  render() {
    const { product, cart } = this.props;
    let currentCartItem = cart.find((prop) => prop.id === product.id);
    let currentCartQty = currentCartItem ? currentCartItem.quantity : 0;
    return (
      <React.Fragment>
        {this.props.orderPeriod[0] && (
          <QuantitySelector
            subtractQty={this.subtractQty}
            addQty={this.addQty}
            updateQty={this.updateQty}
            quantity={this.state.quantity}
            quantityType={product.qty_type}
            disabled={product.is_available !== "true"}
            limit={product.current_stock}
          />
        )}
        {this.state.quantity > 1 && (
          <span style={styles.priceSubtotal}>
            Price Subtotal: ₱{product.price * this.state.quantity}
          </span>
        )}
        {this.state.quantity > 1 && (
          <span style={styles.weightSubtotal}>
            Approximately{" "}
            {getSimplifiedWeight(product.base_weight * this.state.quantity)}
          </span>
        )}
        {product.is_available === "true" &&
        (product.current_stock == -1
          ? true
          : currentCartQty + this.state.quantity <= product.current_stock) ? (
          <Button
            variant="gray"
            className="w-100"
            style={commonStyles.defaultMarginBotttom}
            onClick={this.addToCart}
          >
            Add to Cart
          </Button>
        ) : (
          <Button
            variant="disabled"
            className="w-100"
            style={commonStyles.defaultMarginBotttom}
          >
            {this.props.orderPeriod[0]
              ? product.is_soldout === "true"
                ? "Sold Out"
                : currentCartQty + this.state.quantity > product.current_stock
                ? "No enough stock"
                : "Not Available"
              : "Shop is Closed"}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

const styles = {
  priceSubtotal: {
    display: "block",
    fontSize: "10px",
    marginBottom: "5px",
  },
  weightSubtotal: {
    display: "block",
    fontSize: "10px",
    marginBottom: "5px",
  },
};

const mapPropsToState = (state) => ({
  cart: state.shopReducer.cart,
  user: state.auth.user,
  orderPeriod: state.coreReducer.orderPeriod,
});

export default connect(mapPropsToState, {
  addProductToCart,
  updateProductToCart,
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal,
  showLoginModal,
})(AddToCart);
