import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spring } from "react-spring/renderprops";

import { connect } from "react-redux";

import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";

import LoginModal from "../../components/modals/LoginModal";
import RegisterModal from "../../components/modals/RegisterModal";
import CartModal from "../../components/modals/CartModal";

import { logout, getUserProfile } from "../../actions/AuthActions";
import {
  emptyCart,
  updateProductToCart,
  refreshCartFromToken
} from "../../actions/ShopActions";
import {
  showLoginModal,
  setActiveMenu,
  getConfig
} from "../../actions/CoreActions";
import { getProduct } from "../../actions/ProductsActions";
import { isEmpty, getDateText, getFirstLetter } from "../../actions/utils";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartQty: 0,
      token: "",
      cartAnimation: "",
      isSmallLogo: false,
      orderPeriod: [],
      openNext: ""
    };
  }
  componentDidMount() {
    const { cart, token, orderPeriod } = this.props;
    let cartQty = 0;
    cart.map(product => {
      cartQty = cartQty + product.quantity;
    });
    this.setState({
      cartQty,
      token,
      orderPeriod
    });
    window.addEventListener("scroll", this.handleScroll);
    getConfig(cb => {
      if (cb.open_next !== "") {
        this.setState({
          openNext: cb.open_next
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(props) {
    const { cart, token, orderPeriod } = props;
    let newCartQty = 0;
    cart.map(product => {
      newCartQty = newCartQty + product.quantity;
    });
    this.setState({
      token,
      orderPeriod
    });
    if (this.state.cartQty !== newCartQty) {
      this.setState({
        cartQty: newCartQty,
        cartAnimation: "bounce animated"
      });
    }
    setTimeout(() => {
      this.setState({
        cartAnimation: ""
      });
    }, 1000);
  }

  handleScroll = event => {
    if (window.pageYOffset > 200) {
      this.setState({ isSmallLogo: true });
    } else if (window.pageYOffset < 130) {
      this.setState({ isSmallLogo: false });
    }
  };

  logout = e => {
    e.preventDefault();
    this.props.emptyCart();
    this.props.logout();
    this.props.history.push(`/`);
  };

  goToCart = e => {
    e.preventDefault();
    if (isEmpty(this.props.user)) {
      this.props.showLoginModal();
    } else {
      this.props.refreshCartFromToken(cart => {
        cart.map(product => {
          getProduct(product.id, cb => {
            if (cb !== 500 && product.price !== cb.price) {
              let productToUpdate = cart.find(prop => prop.id === product.id);
              productToUpdate.price = cb.price;
              this.props.updateProductToCart(cart, productToUpdate);
            }
          });
        });
        this.refs.openCartModalLink.click();
      });
    }
  };

  goToLanding = e => {
    e.preventDefault();
    this.props.setActiveMenu("home");
    this.props.history.push(`/`);
  };

  goToAbout = e => {
    e.preventDefault();
    this.props.setActiveMenu("about");
    this.props.history.push(`/about`);
  };

  goToHowToOrder = e => {
    e.preventDefault();
    this.props.setActiveMenu("how-to-order");
    this.props.history.push(`/how-to-order`);
  };

  goToShop = e => {
    e.preventDefault();
    this.props.setActiveMenu("shop");
    this.props.history.push(`/shop`);
  };

  goToContact = e => {
    e.preventDefault();
    this.props.setActiveMenu("contact");
    this.props.history.push(`/contact`);
  };

  goToAccount = e => {
    e.preventDefault();
    this.props.getUserProfile();
    this.props.history.push(`/account`);
  };

  render() {
    return (
      <React.Fragment>
        <CartModal history={this.props.history} />
        <LoginModal />
        <RegisterModal />
        <nav className="sticky-top">
          <div style={styles.topNavDiv}>
            <div className="container p-0">
              <div className="row flex-nowrap justify-content-between align-items-center flex-column flex-lg-row m-0">
                <div className="col-12 col-lg-4 d-flex p-3 pr-0">
                  {this.state.orderPeriod[0] ? (
                    <div style={styles.deliveryDateLabelContainer}>
                      <p style={styles.shoppingForLabel} className="heading">
                        SHOPPING FOR DELIVERY DATE:
                      </p>
                      <p style={styles.shoppingForDate} className="heading">
                        {getDateText(this.state.orderPeriod[0].delivery_date)}
                      </p>
                    </div>
                  ) : (
                    <div style={styles.deliveryDateLabelContainer}>
                      {this.state.openNext !== "" ? (
                        <Fragment>
                          <p
                            style={styles.shoppingForLabel}
                            className="heading"
                          >
                            SHOP IS CURRENTLY CLOSED
                          </p>
                          <p
                            style={styles.shoppingForLabel}
                            className="heading"
                          >
                            WILL RESUME ON:
                          </p>
                          <b style={styles.shoppingForDate} className="heading">
                            {this.state.openNext}
                          </b>
                        </Fragment>
                      ) : (
                        <p style={styles.shoppingForLabel} className="heading">
                          SHOP IS TEMPORARILY CLOSED
                        </p>
                      )}
                    </div>
                  )}
                  <div style={styles.mobileCartContainer} className="d-md-none">
                    <a
                      className="text-brown ml-2"
                      href="#"
                      onClick={e => this.goToCart(e)}
                    >
                      <FontAwesomeIcon icon="shopping-cart" />
                      <Badge
                        pill
                        variant="danger text-white"
                        className={this.state.cartAnimation}
                      >
                        {this.state.cartQty > 0 ? this.state.cartQty : ""}
                      </Badge>
                    </a>
                  </div>
                </div>
                <div className="col-12 col-lg-4 text-center">
                  <a
                    className="blog-header-logo text-dark d-none d-md-block"
                    href="/"
                  >
                    <Spring
                      from={{ height: "100px" }}
                      to={{ height: this.state.isSmallLogo ? "60px" : "100px" }}
                    >
                      {props => (
                        <Image
                          src={`${window.location.origin}/images/logo.png`}
                          style={{ ...styles.logoNavBarImage, ...props }}
                        />
                      )}
                    </Spring>
                  </a>
                </div>
                <div className="col-12 col-lg-4 justify-content-end align-items-center p-3 pr-0 d-none d-md-flex">
                  <div className="row w-100 m-0">
                    <div className="p-0 col-6 col-lg-8 d-flex order-lg-12 justify-content-lg-end align-items-center pr-lg-0">
                      {this.state.token === "" && (
                        <div>
                          <a
                            className="btn btn-sm btn-primary"
                            href="#"
                            onClick={() => this.props.showLoginModal()}
                          >
                            Login
                          </a>
                          <a
                            className="btn btn-sm btn-outline-primary ml-2"
                            href="#"
                            data-toggle="modal"
                            data-target="#registerModal"
                          >
                            Register
                          </a>
                        </div>
                      )}
                      {this.state.token !== "" && (
                        <div>
                          <a
                            className="btn btn-sm btn-primary-link"
                            href="#"
                            onClick={e => this.goToAccount(e)}
                          >
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                              className="heading"
                            >
                              <div style={styles.nameInitialsContainer}>
                                <span className="heading">
                                  {getFirstLetter(this.props.user.first_name)}
                                  {getFirstLetter(this.props.user.last_name)}
                                </span>
                              </div>
                              My Account
                            </span>
                          </a>
                          <a
                            className="btn btn-sm btn-primary ml-2"
                            href="#"
                            onClick={this.logout}
                          >
                            Logout
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="p-0 col-6 col-lg-4 d-flex order-lg-1 justify-content-end align-items-center">
                      {/* <a className="text-brown" href="#">
                        <FontAwesomeIcon icon="search" />
                      </a> */}
                      <a
                        className="text-brown ml-2"
                        href="#"
                        onClick={e => this.goToCart(e)}
                      >
                        <FontAwesomeIcon icon="shopping-cart" />
                        <Badge
                          pill
                          variant="danger text-white"
                          className={this.state.cartAnimation}
                        >
                          {this.state.cartQty > 0 ? this.state.cartQty : ""}
                        </Badge>
                      </a>
                      <a
                        style={{ display: "none" }}
                        href="#"
                        data-toggle="modal"
                        data-target="#cartModal"
                        ref="openCartModalLink"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar navbar-expand-md navbar-dark">
            <a className="navbar-brand d-block d-md-none" href="#">
              <Image
                src={`${window.location.origin}/images/icon-white.png`}
                style={styles.logoMobileNavBarImage}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExampleDefault"
              aria-controls="navbarsExampleDefault"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarsExampleDefault"
            >
              <ul className="navbar-nav mr-auto container d-flex flex-column flex-md-row justify-content-between p-0">
                <li className="nav-item d-none d-md-block">
                  <a
                    className={
                      this.props.activeMenu === "home"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="#"
                    onClick={e => this.goToLanding(e)}
                  >
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      this.props.activeMenu === "about"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="#"
                    onClick={e => this.goToAbout(e)}
                  >
                    ABOUT
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      this.props.activeMenu === "how-to-order"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="#"
                    onClick={e => this.goToHowToOrder(e)}
                  >
                    HOW TO ORDER
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      this.props.activeMenu === "shop"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="#"
                    onClick={e => this.goToShop(e)}
                  >
                    SHOP
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      this.props.activeMenu === "contact"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href="#"
                    onClick={e => this.goToContact(e)}
                  >
                    CONTACT
                  </a>
                </li>
              </ul>
              <div className="row w-100 m-0 d-md-none pt-4">
                <div className="p-0 col-6 col-lg-8 d-flex order-lg-12 justify-content-lg-end align-items-center pr-lg-0">
                  {this.state.token !== "" && (
                    <div>
                      <a
                        className="p-0 btn btn-sm btn-primary-link"
                        href="#"
                        onClick={e => this.goToAccount(e)}
                      >
                        <span
                          style={{ display: "flex", alignItems: "center" }}
                          className="heading"
                        >
                          <div style={styles.nameInitialsContainer}>
                            <span className="heading">
                              {getFirstLetter(this.props.user.first_name)}
                              {getFirstLetter(this.props.user.last_name)}
                            </span>
                          </div>
                          My Account
                        </span>
                      </a>
                    </div>
                  )}
                </div>
                <div className="p-0 col-6 col-lg-4 d-flex order-lg-1 justify-content-end align-items-center">
                  {this.state.token === "" && (
                    <div>
                      <a
                        className="btn btn-sm btn-primary"
                        href="#"
                        onClick={() => this.props.showLoginModal()}
                      >
                        Login
                      </a>
                      <a
                        className="btn btn-sm btn-outline-primary ml-2"
                        href="#"
                        data-toggle="modal"
                        data-target="#registerModal"
                      >
                        Register
                      </a>
                    </div>
                  )}
                  {this.state.token !== "" && (
                    <div>
                      <a
                        className="btn btn-sm btn-primary ml-2"
                        href="#"
                        onClick={this.logout}
                      >
                        Logout
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const styles = {
  iconNavBarImage: {
    maxWidth: 30,
    width: "100%"
  },
  logoNavBarImage: {
    maxWidth: 400,
    maxHeight: "100px",
    margin: "10px"
  },
  logoMobileNavBarImage: {
    maxHeight: 50
  },
  topNavDiv: {
    backgroundColor: "white"
  },
  shoppingForLabel: {
    color: "#c1c1c1",
    margin: 0,
    fontSize: "13px"
  },
  shoppingForDate: {
    margin: 0,
    color: "#a5d24b",
    fontWeight: "bold"
  },
  nameInitialsContainer: {
    backgroundColor: "#3b2726",
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    color: "#caf17c"
  },
  deliveryDateLabelContainer: {
    flexGrow: 1
  },
  mobileCartContainer: {
    alignSelf: "center"
  }
};

const mapPropsToState = state => ({
  token: state.auth.token,
  user: state.auth.user,
  cart: state.shopReducer.cart,
  orderPeriod: state.coreReducer.orderPeriod,
  activeMenu: state.coreReducer.activeMenu
});

export default connect(mapPropsToState, {
  logout,
  emptyCart,
  showLoginModal,
  updateProductToCart,
  setActiveMenu,
  getUserProfile,
  refreshCartFromToken
})(Navbar);
