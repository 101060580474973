import React, { Fragment } from "react";
import { connect } from "react-redux";

import Modal from "../core/Modal";
import Navbar from "../core/Navbar";
import Footer from "../core/Footer";
import UnderConstruction from "../core/UnderConstruction";
import StickerModal from "../modals/StickerModal";

const MainLayout = props => {
  return (
    <div className="App">
      {process.env.REACT_APP_UNDER_CONSTRUCTION == "True" && !props.underConstructionBypass  ? (
        <UnderConstruction />
      ) : (
        <Fragment>
          <Modal />
          <Navbar history={props.history} />
          {props.children}
          <Footer history={props.history} />
          <StickerModal />
        </Fragment>
      )}
    </div>
  );
};

const mapPropsToState = state => ({
  underConstructionBypass: state.coreReducer.underConstructionBypass
});

export default connect(
  mapPropsToState,
  {}
)(MainLayout);
