import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

import AnimatedSlideUp from "../animation/AnimatedSlideUp";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const LandingSecondRow = () => {
  const [isInitialVisible, setIsInitialVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsInitialVisible(true);
    }, 500);
  });
  return (
    <Container className="my-5">
      {isInitialVisible && (
        <Row className="text-center">
          <Col xs={12} md={4} className="my-3 my-md-5">
            <AnimatedSlideUp delay={0}>
              <div style={styles.iconBg} className="bg-brown mb-4">
                <FontAwesomeIcon
                  icon="seedling"
                  size="2x"
                  className="text-brown-text"
                />
              </div>
              <h5 className="text-brown mt-4">FARMER</h5>
              <p>
                BukidFresh sources directly from smallholder local farmers
                at a price which they themselves set. Through this approach,
                dignity is given back to their profession.
              </p>
            </AnimatedSlideUp>
          </Col>
          <Col xs={12} md={4} className="my-3 my-5">
            <AnimatedSlideUp delay={100}>
              <div style={styles.iconBg} className="bg-brown mb-4">
                <FontAwesomeIcon
                  icon="piggy-bank"
                  size="2x"
                  className="text-brown-text"
                />
              </div>
              <h5 className="text-brown mt-4">CONSUMER</h5>
              <p>
                Enjoy fresh and safe produce at a better cost and with more
                convenience on your end. Get your kitchen up and running with
                our best plant-based recipes!
              </p>
            </AnimatedSlideUp>
          </Col>
          <Col xs={12} md={4} className="my-3 my-5">
            <AnimatedSlideUp delay={200}>
              <div style={styles.iconBg} className="bg-brown mb-4">
                <FontAwesomeIcon
                  icon="globe-americas"
                  size="2x"
                  className="text-brown-text"
                />
              </div>
              <h5 className="text-brown mt-4">EARTH</h5>
              <p>
                BukidFresh is a supporter of Mother Earth and that's why our
                operations are conducted in a way which is considerate of our 
                environment.
              </p>
            </AnimatedSlideUp>
          </Col>
        </Row>
      )}
    </Container>
  );
};

const styles = {
  iconBg: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
    margin: "auto",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default LandingSecondRow;
