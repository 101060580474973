import React, { Component } from "react";

export class A extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      active: props.active ? props.active : false,
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.active !== this.props.active) {
      this.setState({
        active: this.props.active,
      });
    }
  };
  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };
  render() {
    if (this.props.onClick) {
      return (
        <a
          onClick={this.props.onClick}
          style={{
            ...style.linkButton,
            ...(this.state.hover ? style.linkButtonHover : {}),
            ...(this.state.active ? style.activeLink : {}),
          }}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <a href={this.props.href} style={style.linkHref}>
        {this.props.children}
      </a>
    );
  }
}

const style = {
  linkHref: {
    color: "#9e9e9e",
  },
  linkButton: {
    color: "#9e9e9e",
    cursor: "pointer",
  },
  linkButtonHover: {
    textDecoration: "underline",
  },
  activeLink: {
    color: "#7eb415",
    textDecoration: "underline",
  },
};

export default A;
