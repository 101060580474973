import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import "./styles/Landing.css";

import MainLayout from "../components/layout/MainLayout";
import Hero from "../components/landing/Hero";
import FeaturedProducts from "../components/landing/FeaturedProducts";
import HowItWorksRow from "../components/landing/HowItWorksRow";
import LandingSecondRow from "../components/landing/LandingSecondRow";

import { setActiveMenu } from "../actions/CoreActions";

const Landing = ({ history, setActiveMenu }) => {
  const goToAbout = e => {
    e.preventDefault();
    setActiveMenu("about");
    history.push(`/about`);
  };
  return (
    <MainLayout history={history}>
      <Hero history={history} />
      <HowItWorksRow />
      {/* <FeaturedProducts history={history} /> */}
      <div className="position-relative overflow-hidden text-center bg-light">
        <Image
          src={`${window.location.origin}/images/palangue-agrarian-farmers.jpg`}
          className="w-100"
          fluid
        />
      </div>
      <LandingSecondRow />
      <Container fluid className="p-0 position-relative">
        <Image
          src={`${window.location.origin}/images/grass.png`}
          className="coffeeBg"
        />
        <div style={styles.aboutUsContainer}>
          <h4 style={styles.aboutUsLink} className="wide-text about-link">
            LEARN ABOUT US!{" "}
            <a href="#" onClick={e => goToAbout(e)}>
              CLICK HERE
            </a>
          </h4>
        </div>
      </Container>
    </MainLayout>
  );
};

const styles = {
  iconBg: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
    margin: "auto",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  aboutUsContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.65)"
  },
  aboutUsLink: {
    color: "white"
  }
};

export default connect(null, { setActiveMenu })(Landing);
