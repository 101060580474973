import React, { Component, Fragment } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import commonStyles from "../../constants/styles/CommonStyles";
import QuantitySelector from "../../components/shop/QuantitySelector";

import {
  updateCart,
  updateProductToCart,
  removeProductToCart
} from "../../actions/ShopActions";
import {
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal
} from "../../actions/CoreActions";
import { getUserProfile } from "../../actions/AuthActions";
import { removeBaseUrl, getSimplifiedWeight } from "../../actions/utils";

import { connect } from "react-redux";

export class CartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: []
    };
  }
  componentDidMount() {
    const { cart } = this.props;
    this.setState({
      cart
    });
  }
  componentDidUpdate = prevProps => {
    if (prevProps.cart !== this.props.cart) {
      this.setState({
        cart: this.props.cart
      });
    }
  };

  subtractQty = productId => {
    const { cart } = this.props;
    let currentQty = cart.find(prop => prop.id === productId).quantity;
    if (currentQty > 1) {
      let productToUpdate = cart.find(prop => prop.id === productId);
      productToUpdate.quantity = currentQty - 1;
      this.props.updateProductToCart(cart, productToUpdate);
    }
  };

  addQty = productId => {
    const { cart } = this.props;
    let currentQty = cart.find(prop => prop.id === productId).quantity;
    let productToUpdate = cart.find(prop => prop.id === productId);
    productToUpdate.quantity = currentQty + 1;
    this.props.updateProductToCart(cart, productToUpdate);
  };

  removeCart = product => {
    const { cart } = this.props;
    let productToDelete = cart.find(prop => prop.id === product.id);
    removeProductToCart(productToDelete);
    const updatedCart = cart.filter(prop => prop.id !== product.id);
    this.props.updateCart(updatedCart);
  };

  goToCheckout = e => {
    e.preventDefault();
    this.refs.cartModalCloseButton.click();
    this.props.getUserProfile();
    if (!this.props.orderPeriod[0]) {
      this.props.setModalType("basic");
      this.props.setModalTitle("Notice");
      this.props.setModalBody(
        "The shop is currently closed. You can't proceed to checkout."
      );
      this.props.setModalConfirmButton("OK");
      this.props.showModal();
    } else {
      this.props.history.push(`/checkout`);
    }
  };

  goToProduct = product => {
    this.refs.cartModalCloseButton.click();
    this.props.history.push(`/shop/${product.id}`, { product });
  };

  goToShop = () => {
    this.refs.cartModalCloseButton.click();
    this.props.history.push(`/shop`);
  };

  closeModal = e => {
    e.preventDefault();
    this.refs.cartModalCloseButton.click();
  };

  render() {
    const totalPrice = this.props.cart.reduce(function(prev, product) {
      if (isNaN(product.price)) {
        return prev;
      }
      return prev + +(product.price * product.quantity);
    }, 0);
    const totalPacks = this.props.cart.reduce(function(prev, product) {
      if (isNaN(product.price)) {
        return prev;
      }
      return prev + +product.quantity;
    }, 0);
    return (
      <div>
        <div
          className="modal right fade"
          id="cartModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cartModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content modal-cart">
              <div className="modal-header">
                <h4 className="modal-title" id="cartModalLabel">
                  Cart
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  ref="cartModalCloseButton"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {this.state.cart.length == 0 && (
                  <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                      <h6 className="text-center">Your Cart is Empty.</h6>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-center">
                      <Button variant="primary" onClick={this.goToShop}>
                        Go to Shop
                      </Button>
                    </Col>
                  </Row>
                )}
                {this.state.cart.map(product => {
                  return (
                    <Row className="mb-3" key={product.id}>
                      <Col xs={12}>
                        <Card className="p-2">
                          <Row>
                            <Col xs={4}>
                              <div
                                style={style.cartModalImageContainer}
                                className="cartModalImageContainer"
                                onClick={() => this.goToProduct(product)}
                              >
                                <Image
                                  src={removeBaseUrl(product.image)}
                                  style={style.cartImage}
                                  className="cartImage"
                                />
                              </div>
                            </Col>
                            <Col
                              xs={8}
                              style={{
                                ...commonStyles.flexAlignCenter,
                                paddingLeft: 0
                              }}
                            >
                              <Card.Body style={style.cartBody}>
                                <Row>
                                  <Col
                                    xs={9}
                                    sm={8}
                                    style={commonStyles.flexAlignCenter}
                                    style={{
                                      ...commonStyles.flex,
                                      ...commonStyles.flexAlignStart,
                                      ...commonStyles.flexDirectionCol,
                                      ...commonStyles.flexJustifyContentFlexStart,
                                      paddingLeft: 0
                                    }}
                                  >
                                    <div style={style.hoverCursor}>
                                      <Card.Title
                                        style={style.productName}
                                        onClick={() =>
                                          this.goToProduct(product)
                                        }
                                      >
                                        {product.name}
                                      </Card.Title>
                                      <Card.Text style={style.productPrice}>
                                        <div
                                          onClick={() =>
                                            this.goToProduct(product)
                                          }
                                        >
                                          ₱{product.price} /{" "}
                                          {product.base_weight_text}
                                        </div>
                                        {!isNaN(product.price) && (
                                          <Fragment>
                                            <QuantitySelector
                                              className="mt-4 mb-0"
                                              subtractQty={() =>
                                                this.subtractQty(product.id)
                                              }
                                              addQty={() =>
                                                this.addQty(product.id)
                                              }
                                              quantity={product.quantity}
                                              quantityType={product.qty_type}
                                              disabled={
                                                product.is_available !== "true"
                                              }
                                              limit={product.current_stock}
                                            />
                                            <span style={style.weightSubtotal}>
                                              Approximately{" "}
                                              {getSimplifiedWeight(
                                                product.base_weight *
                                                  product.quantity
                                              )}
                                            </span>
                                            {product.is_available !==
                                              "true" && (
                                              <span
                                                style={style.weightSubtotal}
                                              >
                                                {product.is_soldout === "true" ? "Sold Out" : "Not Available"}
                                              </span>
                                            )}
                                          </Fragment>
                                        )}
                                      </Card.Text>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={3}
                                    sm={4}
                                    style={{
                                      ...commonStyles.flex,
                                      ...commonStyles.flexAlignEnd,
                                      ...commonStyles.flexDirectionCol,
                                      ...commonStyles.flexJustifyContentFlexStart
                                    }}
                                  >
                                    <h5 style={style.totalPrice}>
                                      {!isNaN(product.price)
                                        ? `₱${product.price * product.quantity}`
                                        : `Not available`}
                                    </h5>
                                    {!isNaN(product.price) && (
                                      <Button
                                        className="mt-4 mb-0"
                                        variant="outline-danger"
                                        onClick={() => this.removeCart(product)}
                                        style={
                                          commonStyles.defaultMarginBotttom
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon="trash"
                                          size="sm"
                                          className="text-danger"
                                        />
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <div className="modal-footer">
                {this.state.cart.length > 0 && (
                  <React.Fragment>
                    <h5>
                      Total
                      {` (${totalPacks} ${totalPacks > 1 ? "items" : "item"})`}:
                      ₱{totalPrice}
                    </h5>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={e => this.goToCheckout(e)}
                    >
                      Checkout
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            style={style.imageLogoWhiteContainer}
            onClick={e => this.closeModal(e)}
            className="d-none d-lg-block"
          >
            <Image
              src={`${window.location.origin}/images/icon-white.png`}
              style={style.imageLogoWhite}
            />
          </div>
        </div>
      </div>
    );
  }
}

const style = {
  hoverCursor: {
    cursor: "pointer"
  },
  cartModalImageContainer: {
    border: "1px solid #dee2e6",
    borderRadius: "0.25rem",
    textAlign: "center",
    backgroundColor: "white",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "hidden",
    cursor: "pointer"
  },
  cartImage: {
    position: "absolute",
    margin: "auto",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  productName: {
    fontSize: "15px",
    marginBottom: 0
  },
  productPrice: {
    fontSize: "13px"
  },
  totalPrice: {
    fontSize: "25px"
  },
  weightSubtotal: {
    display: "block",
    fontSize: "10px",
    marginTop: "5px",
    textAlign: "center"
  },
  imageLogoWhiteContainer: {
    position: "absolute",
    width: "calc(100% - 500px)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  imageLogoWhite: {
    maxWidth: "200px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
};

const mapPropsToState = state => ({
  cart: state.shopReducer.cart,
  orderPeriod: state.coreReducer.orderPeriod
});

export default connect(mapPropsToState, {
  updateCart,
  updateProductToCart,
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal,
  getUserProfile
})(CartModal);
