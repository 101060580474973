import { SET_PRODUCTS, SET_FEATURED_PRODUCTS } from "../constants/Types";

const initialState = {
  products: [],
  featuredProducts: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };

    case SET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload
      };

    default:
      return state;
  }
}
