export default {
  defaultMarginBotttom: {
    marginBottom: 10
  },
  defaultMarginTop: {
    marginTop: 10
  },
  flex: {
    display: "flex"
  },
  flexAlignCenter: {
    alignItems: "center"
  },
  flexAlignEnd: {
    alignItems: "flex-end"
  },
  flexAlignStart: {
    alignItems: "flex-start"
  },
  flexDirectionRow: {
    flexDirection: "row"
  },
  flexDirectionCol: {
    flexDirection: "column"
  },
  flexAlignCenter: {
    alignItems: "center"
  },
  flexJustifyContentFlexStart: {
    justifyContent: "flex-start"
  },
  flexJustifyContentFlexEnd: {
    justifyContent: "flex-end"
  },
  flexJustifyConentCenter: {
    justifyContent: "center"
  },
  textAlignRight: {
    textAlign: "right"
  }
};
