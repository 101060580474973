import React, { Component } from "react";

import { connect } from "react-redux";

import Image from "react-bootstrap/Image";

export class RewardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stickerCount: props.stickerCount,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.stickerCount !== this.props.stickerCount) {
      this.setState({
        stickerCount: this.props.stickerCount,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div style={styles.rewardCardDiv}>
          <Image
            src={`${window.location.origin}/images/bf-reward-card.png`}
            style={styles.rewardCardBg}
          />
          {this.state.stickerCount >= 1 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker1,
              }}
            />
          )}
          {this.state.stickerCount >= 2 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker2,
              }}
            />
          )}
          {this.state.stickerCount >= 3 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker3,
              }}
            />
          )}
          {this.state.stickerCount >= 4 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker4,
              }}
            />
          )}
          {this.state.stickerCount >= 5 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker5,
              }}
            />
          )}
          {this.state.stickerCount >= 6 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker6,
              }}
            />
          )}
          {this.state.stickerCount >= 7 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker7,
              }}
            />
          )}
          {this.state.stickerCount >= 7 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker7,
              }}
            />
          )}
          {this.state.stickerCount >= 8 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker8,
              }}
            />
          )}
          {this.state.stickerCount >= 9 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker9,
              }}
            />
          )}
          {this.state.stickerCount >= 10 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker10,
              }}
            />
          )}
          {this.state.stickerCount >= 11 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker11,
              }}
            />
          )}
          {this.state.stickerCount >= 12 && (
            <Image
              src={`${window.location.origin}/images/sticker.png`}
              style={{
                ...styles.rewardCardSticker,
                ...styles.rewardCardSticker12,
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  rewardCardDiv: {
    width: "100%",
    position: "relative",
  },
  rewardCardBg: {
    width: "100%",
  },
  rewardCardSticker: {
    width: "12%",
    position: "absolute",
  },
  rewardCardSticker1: {
    left: "5.8%",
    top: "37%",
  },
  rewardCardSticker2: {
    left: "21%",
    top: "37%",
  },
  rewardCardSticker3: {
    left: "36.3%",
    top: "37%",
  },
  rewardCardSticker4: {
    left: "51.6%",
    top: "37%",
  },
  rewardCardSticker5: {
    left: "66.9%",
    top: "37%",
  },
  rewardCardSticker6: {
    left: "82.3%",
    top: "37%",
  },
  rewardCardSticker7: {
    left: "5.8%",
    top: "58%",
  },
  rewardCardSticker8: {
    left: "21%",
    top: "58%",
  },
  rewardCardSticker9: {
    left: "36.3%",
    top: "58%",
  },
  rewardCardSticker10: {
    left: "51.6%",
    top: "58%",
  },
  rewardCardSticker11: {
    left: "66.9%",
    top: "58%",
  },
  rewardCardSticker12: {
    left: "82.3%",
    top: "58%",
  },
};

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState, {})(RewardCard);
