import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectRadio from "../generic/SelectRadio";

export class SelectPickupPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPickupPoint: props.selectPickupPoint,
      selectedPickupPoint: "",
      pickuppoints: props.pickuppoints,
      error: props.error
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.selectedPickupPoint !== this.props.selectedPickupPoint) {
      this.setState({
        selectedPickupPoint: "",
        selectedPickupPoint: this.props.selectedPickupPoint
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  };
  selectPickupPoint = selectedPickupPoint => {
    this.state.selectPickupPoint(selectedPickupPoint);
    this.setState({
      selectedPickupPoint,
      error: ""
    });
  };
  render() {
    return (
      <Row className="mb-3 ml-2" ref={this.props.refProp}>
        <Col xs={12} lg={6}>
          <h6 className="mb-3">Select Pickup Point:</h6>
          {this.state.error && (
            <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
              {this.state.error}
            </medium>
          )}
          <Row>
            {this.state.pickuppoints.map(pickuppoint => (
              <Col xs={12} key={pickuppoint.id} className="ml-3">
                <SelectRadio
                  label={pickuppoint.name}
                  id={pickuppoint.id}
                  selected={this.state.selectedPickupPoint}
                  click={this.selectPickupPoint}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapPropsToState = state => ({
  pickuppoints: state.coreReducer.pickuppoints
});

export default connect(
  mapPropsToState,
  {}
)(SelectPickupPoint);
