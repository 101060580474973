import { ORDER_STATUS } from "../constants/Order";
import { API_BASE_URL } from "../constants/ApiConfig";

export function isEmpty(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function getAddressText(address) {
  let hasFirst = false;
  let addressText = "";
  const addressProperties = ["hlbnumber", "barangay", "citymun", "zipcode"];
  addressProperties.map(prop => {
    if (address[prop] && !hasFirst) {
      addressText = address[prop];
      hasFirst = true;
    } else if (address[prop]) {
      addressText = `${addressText} ${address[prop]}`;
    }
  });
  return addressText;
}

export function getOrderStatus(statusCode) {
  const status = ORDER_STATUS.filter(status => status.code === statusCode)[0];
  return status.name;
}

export function getDateTimeText(dateTime) {
  const date = new Date(dateTime);
  return date.toLocaleString();
}

export function getDateText(dateTime) {
  const date = new Date(dateTime);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return date.toLocaleDateString("en-US", options);
}

export function removeBaseUrl(url) {
  if (url) return url.replace(API_BASE_URL, "");
  return "";
}

export function getSimplifiedWeight(weight) {
  if (weight > 999) {
    return `${weight / 1000}kg`;
  }
  return `${weight}g`;
}

export function getFirstLetter(string) {
  if (string) {
    return string.toUpperCase().charAt(0);
  }
  return "";
}
