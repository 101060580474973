import React, { Component } from "react";
import { connect } from "react-redux";

import ReactModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { getUserProfile } from "../../actions/AuthActions";
import {
  createUpdateAddress,
  deleteNewAddress
} from "../../actions/AccountActions";
import {
  hideAddressModal,
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal
} from "../../actions/CoreActions";

import { isEmpty } from "../../actions/utils";

export class AddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      customer: props.customer.id,
      id: "",
      name: "",
      mobile: "",
      hlbnumber: "",
      barangay: "",
      citymun: "",
      province: "Metro Manila",
      zipcode: "",
      status: 10,
      isLoading: false,
      isDeleteLoading: false,
      name_error: "",
      mobile_error: "",
      hlbnumber_error: "",
      barangay_error: "",
      citymun_error: "",
      province_error: "",
      zipcode_error: "",
      non_field_errors: "",
      show: false,
      success: false,
      showConfirmDelete: false,
      disabledDefault: true
    };
  }

  componentDidMount() {
    const { show } = this.props;
    if (!isEmpty(this.props.address)) {
      this.setState({
        type: "update",
        id: this.props.address.id,
        name: this.props.address.name,
        mobile: this.props.address.mobile,
        hlbnumber: this.props.address.hlbnumber,
        barangay: this.props.address.barangay,
        citymun: this.props.address.citymun,
        zipcode: this.props.address.zipcode
      });
    } else {
      this.setState({
        type: "create",
        name: `${this.props.customer.first_name} ${this.props.customer.last_name}`,
        mobile: this.props.customer.mobile,
        hlbnumber: "",
        barangay: "",
        citymun: "",
        zipcode: ""
      });
    }
    this.setState({
      show
    });
  }

  componentDidUpdate = prevProps => {
    if (prevProps.show !== this.props.show) {
      this.setState({
        show: this.props.show
      });
    }
    if (prevProps.address !== this.props.address) {
      if (!isEmpty(this.props.address)) {
        this.setState({
          type: "update",
          id: this.props.address.id,
          name: this.props.address.name,
          mobile: this.props.address.mobile,
          hlbnumber: this.props.address.hlbnumber,
          barangay: this.props.address.barangay,
          citymun: this.props.address.citymun,
          zipcode: this.props.address.zipcode
        });
      } else {
        this.setState({
          type: "create",
          name: `${this.props.customer.first_name} ${this.props.customer.last_name}`,
          mobile: this.props.customer.mobile,
          hlbnumber: "",
          barangay: "",
          citymun: "",
          zipcode: ""
        });
      }
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      name_error: "",
      mobile_error: "",
      hlbnumber_error: "",
      barangay_error: "",
      citymun_error: "",
      province_error: "",
      zipcode_error: "",
      non_field_errors: ""
    });
    const address = {
      customer: this.props.customer.id,
      id: this.state.id,
      name: this.state.name,
      mobile: this.state.mobile,
      hlbnumber: this.state.hlbnumber,
      barangay: this.state.barangay,
      citymun: this.state.citymun,
      province: this.state.province,
      zipcode: this.state.zipcode
    };
    createUpdateAddress(this.state.type, address, cb => {
      if (cb.status === 400) {
        Object.keys(cb.data).forEach((key, index) => {
          const errors = cb.data[key].join(" ");
          if (key === "non_field_errors") {
            this.setState({
              [key]: errors
            });
          } else {
            this.setState({
              [key + "_error"]: errors
            });
          }
        });
        this.setState({
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false,
          success: true
        });
        this.props.getUserProfile();
        if (this.state.type === "create") {
          this.setState({
            id: "",
            name: "",
            mobile: "",
            hlbnumber: "",
            barangay: "",
            citymun: "",
            zipcode: ""
          });
          this.refs.newAddressModalDismissBtn.click();
          setTimeout(() => {
            this.props.setModalType("basic");
            this.props.setModalTitle("Success");
            this.props.setModalBody("New Address Successfully Created!");
            this.props.setModalConfirmButton("OK");
            this.props.showModal();
          }, 500);
        }
      }
    });
  };

  handleClose = () => {
    this.setState({
      success: false,
      disabledDefault: true
    });
    this.props.hideAddressModal();
  };

  hideConfirmDelete = () => {
    this.setState({
      showConfirmDelete: false
    });
  };

  showConfirmDelete = () => {
    this.setState({
      showConfirmDelete: true
    });
  };

  deleteAddress = () => {
    this.setState({
      isDeleteLoading: true
    });
    const address = {
      customer: this.props.customer.id,
      id: this.state.id,
      name: this.state.name,
      mobile: this.state.mobile,
      hlbnumber: this.state.hlbnumber,
      barangay: this.state.barangay,
      citymun: this.state.citymun,
      province: this.state.province,
      zipcode: this.state.zipcode
    };
    deleteNewAddress(address, cb => {
      if (cb.status === 400) {
        Object.keys(cb.data).forEach((key, index) => {
          const errors = cb.data[key].join(" ");
          if (key === "non_field_errors") {
            this.setState({
              [key]: errors
            });
          } else {
            this.setState({
              [key + "_error"]: errors
            });
          }
        });
        this.setState({
          isDeleteLoading: false
        });
      } else {
        this.setState({
          id: "",
          name: "",
          mobile: "",
          hlbnumber: "",
          barangay: "",
          citymun: "",
          zipcode: "",
          isDeleteLoading: false,
          showConfirmDelete: false
        });
        this.props.getUserProfile();
        this.refs.newAddressModalDismissBtn.click();
      }
    });
  };

  changeRecipient = e => {
    e.preventDefault();
    this.setState({ disabledDefault: false, name: "", mobile: "" });
  };

  render() {
    return (
      <ReactModal show={this.state.show} onHide={this.handleClose}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>
            {this.state.type === "create" && "Add A New Address"}
            {this.state.type === "update" && "Update Address"}
          </ReactModal.Title>
        </ReactModal.Header>

        <form onSubmit={this.onSubmit}>
          <ReactModal.Body>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Recipient Name"
                onChange={this.onChange}
                value={this.state.name}
                disabled={this.state.disabledDefault}
              />
              {this.state.name_error && (
                <small className="form-text text-danger">
                  {this.state.name_error}
                </small>
              )}
            </div>
            <div
              className={`form-group ${this.state.disabledDefault && "mb-1"}`}
            >
              <input
                type="tel"
                name="mobile"
                className="form-control"
                placeholder="Mobile"
                onChange={this.onChange}
                value={this.state.mobile}
                disabled={this.state.disabledDefault}
              />
              {this.state.mobile_error && (
                <small className="form-text text-danger">
                  {this.state.mobile_error}
                </small>
              )}
            </div>
            {this.state.disabledDefault && (
              <a
                href="#"
                onClick={e => this.changeRecipient(e)}
                className="link-primary-dark mb-3 d-block"
              >
                Change Recipient Details
              </a>
            )}
            <div className="form-group">
              <input
                type="text"
                name="hlbnumber"
                className="form-control"
                placeholder="Unit/House Number, Building, Street, and etc..."
                onChange={this.onChange}
                value={this.state.hlbnumber}
              />
              {this.state.hlbnumber_error && (
                <small className="form-text text-danger">
                  {this.state.hlbnumber_error}
                </small>
              )}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="barangay"
                className="form-control"
                placeholder="Barangay"
                onChange={this.onChange}
                value={this.state.barangay}
              />
              {this.state.barangay_error && (
                <small className="form-text text-danger">
                  {this.state.barangay_error}
                </small>
              )}
            </div>
            <div className="form-group">
              <select
                className="form-control"
                value={this.state.citymun}
                onChange={e => {
                  this.setState({ citymun: e.target.value });
                }}
              >
                <option value="" disabled>
                  City
                </option>
                <option value="Bacoor West District 1">
                  Bacoor West District 1
                </option>
                <option value="Las Piñas">Las Piñas</option>
                <option value="Makati">Makati</option>
                <option value="Manila">Manila</option>
                <option value="Marikina">Marikina</option>
                <option value="Mandaluyong">Mandaluyong</option>
                <option value="Muntinlupa">Muntinlupa</option>
                <option value="Parañaque">Parañaque</option>
                <option value="Pasay">Pasay</option>
                <option value="Pasig">Pasig</option>
                <option value="Pateros">Pateros</option>
                <option value="Quezon City District 1">
                  Quezon City District 1
                </option>
                <option value="Quezon City District 3">
                  Quezon City District 3
                </option>
                <option value="Quezon City District 4">
                  Quezon City District 4
                </option>
                <option value="San Juan">San Juan</option>
                <option value="Taguig">Taguig</option>
              </select>
              {this.state.citymun_error && (
                <small className="form-text text-danger">
                  {this.state.citymun_error}
                </small>
              )}
              <small className="form-text text-primary-dark">
                Please be informed that we only deliver to these cities for now.
              </small>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="zipcode"
                className="form-control"
                placeholder="Zip Code"
                onChange={this.onChange}
                value={this.state.zipcode}
              />
              {this.state.zipcode_error && (
                <small className="form-text text-danger">
                  {this.state.zipcode_error}
                </small>
              )}
            </div>
            {this.state.non_field_errors && (
              <small className="form-text text-danger">
                {this.state.non_field_errors}
              </small>
            )}
            {this.state.success && (
              <small className="form-text text-success">
                {this.state.type === "create" &&
                  "New Address Successfully Created!"}
                {this.state.type === "update" && "Successfully Updated Address"}
              </small>
            )}
          </ReactModal.Body>
          <ReactModal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleClose}
              ref="newAddressModalDismissBtn"
            >
              Cancel
            </Button>
            {this.state.type === "update" && (
              <Button variant="danger" onClick={this.showConfirmDelete}>
                {this.state.isDeleteLoading ? (
                  <div>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <span>Delete</span>
                )}
              </Button>
            )}
            <button type="submit" className="btn btn-primary">
              {this.state.isLoading ? (
                <div>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </div>
              ) : (
                <span>
                  {this.state.type === "create" && "Submit"}
                  {this.state.type === "update" && "Update"}
                </span>
              )}
            </button>
          </ReactModal.Footer>
          {this.state.showConfirmDelete && (
            <ReactModal.Footer>
              <b className="mr-2">Are you sure you want to delete this?</b>
              <Button variant="secondary" onClick={this.hideConfirmDelete}>
                No
              </Button>
              <Button variant="primary" onClick={this.deleteAddress}>
                Confirm
              </Button>
            </ReactModal.Footer>
          )}
        </form>
      </ReactModal>
    );
  }
}

const mapPropsToState = state => ({
  show: state.coreReducer.showAddressModal,
  customer: state.auth.user
});

export default connect(mapPropsToState, {
  getUserProfile,
  hideAddressModal,
  setModalType,
  setModalTitle,
  setModalBody,
  setModalConfirmButton,
  showModal
})(AddressModal);
