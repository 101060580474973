import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectButton from "../generic/SelectButton";
import { isEmpty } from "../../actions/utils";

export class SelectDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectDelivery: props.selectDelivery,
      selectedDelivery: "",
      deliveries: props.deliveries,
      error: props.error,
      disabledDelivery: ["Pickup"],
      config: props.config
    };
  }
  componentDidUpdate = prevProps => {
    if (prevProps.deliveries !== this.props.deliveries) {
      this.setState({
        deliveries: this.props.deliveries
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({
        error: this.props.error
      });
    }
  };

  selectDelivery = selectedDelivery => {
    if (isEmpty(this.state.config)) {
      this.setState({
        error: "Loading Error. Kindly refresh page."
      });
      return false;
    }
    const delivery = this.state.deliveries.filter(
      d => d.id === selectedDelivery
    )[0];
    if (delivery && this.state.disabledDelivery.includes(delivery.name)) {
      this.setState({
        selectedDelivery: "",
        error: "Selected delivery option is temporarily unavailable."
      });
      this.state.selectDelivery("");
    } else {
      this.setState({
        selectedDelivery,
        error: ""
      });
      this.state.selectDelivery(selectedDelivery);
    }
  };

  render() {
    return (
      <Row className="mb-3">
        <Col xs={12} lg={6}>
          <h5 className="mb-3">1. Choose your Delivery Option:</h5>
          {this.state.error && (
            <medium className="form-text text-danger mb-3 heading d-inline-block animated pulse infinite fast">
              {this.state.error}
            </medium>
          )}
          <Row>
            {this.state.deliveries.map(delivery => (
              <Col xs={6} key={delivery.id}>
                <SelectButton
                  label={delivery.name}
                  id={delivery.id}
                  selected={this.state.selectedDelivery}
                  click={this.selectDelivery}
                  disabled={this.state.disabledDelivery.includes(delivery.name)}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapPropsToState = state => ({
  deliveries: state.coreReducer.deliveries
});

export default connect(mapPropsToState, {})(SelectDelivery);
